import axios, { AxiosPromise } from "axios";
import { host_url } from "@purusha/config";

export default class MembersService {
  public static base_url: string = `${host_url}/api/v1`

  public static members(organization_id: string): AxiosPromise<Array<any>> {
    const url: string = `${this.base_url}/organizations/${organization_id}/members`;
    return axios.get(url);
  }

  public static create(organization_id: string, user: any) {
    let url: string = `${this.base_url}/organizations/${organization_id}/users`;
    let data = {
      name: user.name,
      email: user.email,
      role: user.role
    };
    return axios.post(url, { user: data });
  }

  public static update(organization_id: string, member: any): AxiosPromise {
    const url: string = `${this.base_url}/organizations/${organization_id}/users/${member.id}`;
    return axios.put(url, { user: { name: member.name, role: member.role } });
  }

  public static discard(organization_id: string, id: string): AxiosPromise {
    const url: string = `${this.base_url}/organizations/${organization_id}/remove_user/${id}`;
    return axios.delete(url);
  }

  public static create_team(organization_id: string, team: any) {
    let url: string = `${this.base_url}/organizations/${organization_id}/teams`;
    return axios.post(url, { team: team });
  }

  public static update_team(organization_id: string, team: any): AxiosPromise {
    const url: string = `${this.base_url}/organizations/${organization_id}/teams/${team.id}`;
    return axios.put(url, { team: team });
  }

  public static discard_team(
    organization_id: string,
    id: string
  ): AxiosPromise {
    const url: string = `${this.base_url}/organizations/${organization_id}/teams/${id}`;
    return axios.delete(url);
  }
}
