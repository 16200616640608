import * as React from "react";
import "./tabs.template.scss";
import { HashRouter as Router, Switch, NavLink, Route } from "react-router-dom";
import { Translate } from "ns-react-i18next";
import { NamaButton } from "@prakriti/prakriti";

interface NamaTabsTemplateProps {
  ["tabs"]: Array<NamaTabTemplateProps>;
  ["filters"]?: Array<Element | JSX.Element>;
}

interface NamaTabTemplateProps {
  ["component"]: any;
  ["title"]: string;
}

interface NamaTabTemplateState {
  ["selected"]: number;
}

class NamaTabsTemplate extends React.Component<
  NamaTabsTemplateProps,
  NamaTabTemplateState
  > {
  constructor(
    public props: NamaTabsTemplateProps,
    public state: NamaTabTemplateState
  ) {
    super(props);
    this.state = {
      selected: 0
    };
  }

  public select = (e: number) => {
    this.setState({ selected: e });
  };

  public header = () => {
    return (
      <div className="nama-sub-header">
        <div className="links">
          {this.props.tabs.map((tab: NamaTabTemplateProps, index: number) => {
            return (
              <NamaButton
                key={index}
                className={this.state.selected === index ? `active` : ``}
                onClick={() => {
                  this.select(index);
                }}
              >
                <Translate>{tab.title}</Translate>
              </NamaButton>
            );
          })}
        </div>
        <div className="filters">
          {this.props.filters}
        </div>
      </div>
    );
  };

  public tabs = () => {
    return (
      <div id="tabs">
        {this.props.tabs.map((tab: NamaTabTemplateProps, index: number) => {
          return (
            this.state.selected === index && (
              <div className="content" key={index}>
                <tab.component />
              </div>
            )
          );
        })}
      </div>
    );
  };

  public render() {
    return (
      <div className="tabs-template">
        {this.header()}
        {this.tabs()}
      </div>
    );
  }
}

export default NamaTabsTemplate;
