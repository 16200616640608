import React, { useEffect } from 'react';
import { Dropdown, DropdownItem, Button, ButtonTypes, IButton } from 'nama-design';
import './channelSelect.scss';
export interface IChannelSelect {
  items: Array<DropdownItem>,
  selected: Array<DropdownItem>,
  onSelect(items: Array<DropdownItem>): void,
};

const ChannelSelect: React.FC<IChannelSelect> = ({
  items,
  selected,
  onSelect,
}) => {
  useEffect(() => {
    const filteredSelected: Array<DropdownItem> = selected.filter(selectedItem => items.findIndex(item => item.value === selectedItem.value) > -1);
    if (filteredSelected.length !== selected.length)
      onSelect([...filteredSelected]);
  }, [items, selected, onSelect])

  const handleSelect = (item: DropdownItem) => {
    const foundItem = selected.findIndex(selectedItem => selectedItem.value === item.value);
    if (foundItem > -1) {
      if (selected.length === 1) return;
      onSelect([...selected.slice(0, foundItem), ...selected.slice(foundItem + 1)])
    } else {
      onSelect([...selected, item]);
    }
  }
  const labelFunction = (selected: Array<DropdownItem>, items: Array<DropdownItem>) => {
    let triggerProps: IButton;
    if (selected.length === items.length) {
      triggerProps = { iconLeft: "Channels", text: "Todos os canais", iconRight: "DropdownDownSingle" }
    }
    else if (selected.length === 1) {
      triggerProps = { iconLeft: selected[0].icon, text: selected[0].label || '', iconRight: "DropdownDownSingle" }
    }
    else {
      triggerProps = { iconLeft: "Channels", text: `${selected.length} canais`, iconRight: "DropdownDownSingle" }
    }
    return triggerProps;
  }
  const triggerProps = labelFunction(selected, items);
  return <Dropdown
    items={items}
    selected={selected}
    trigger={
      <Button
        type={ButtonTypes.flat}
        {...triggerProps}
      />}
    onSelect={handleSelect}
    multiple={true}
  />
};

export {
  ChannelSelect
};