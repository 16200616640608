import React, { Component } from "react";
import "./input.atom.scss";
import { Field, ErrorMessage } from "formik";
import { Translate } from "ns-react-i18next";

export interface NamaInputProps {
  ["name"]: string;
  ["label"]?: string;
  ["step"]?: string;
  ["hint"]?: string;
  ["min"]?: string;
  ["size"]?: string;
  ["type"]:
    | "text"
    | "select"
    | "select"
    | "email"
    | "number"
    | "file"
    | "password"
    | "textarea";
  ["children"]?: any;
  ["validate"]?: any;
  ["value"]?: any;
  ["onChange"]?: Function;
  ["disabled"]?: boolean;
  ["className"]?: string;
}

class NamaInput extends Component {
  public formik: any;
  public formfield: any;
  constructor(public props: NamaInputProps) {
    super(props);
    this.formfield = React.createRef();
  }

  render() {
    return (
      <fieldset className={`${this.props.className} ${this.props.size}`}>
        {this.props.label && (
          <label>
            <Translate>{this.props.label}</Translate>
          </label>
        )}
        {this.props.children ? (
          <Field
            {...this.props.onChange && { onChange: this.props.onChange }}
            {...this.props.value && { value: this.props.value }}
            type={this.props.type}
            component="select"
            disabled={this.props.disabled}
            name={this.props.name}
          >
            {this.props.children}
          </Field>
        ) : this.props.type === "textarea" ? (
          <Field
            component="textarea"
            {...this.props.value && { value: this.props.value }}
            {...this.props.onChange && { onChange: this.props.onChange }}
            disabled={this.props.disabled}
            name={this.props.name}
          />
        ) : (
          <Field {...this.props} />
        )}
        {this.props.hint && <div className="nama-hint">{this.props.hint}</div>}
        <ErrorMessage
          name={this.props.name}
          render={msg => (
            <div className="field-error-message">
              <Translate>{msg}</Translate>
            </div>
          )}
        />
      </fieldset>
    );
  }
}

export default NamaInput;
