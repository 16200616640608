import * as React from "react";
import { NamaMessageGroup, NamaDayDivider, NamaEventMessage, NamaButton, NamaInfiniteScroll, NamaChatBegginingMessage, NamaViewPlaceholder } from "@prakriti/prakriti";
import { ChatModel } from '@purusha/models/chat.model';
import { ReactComponent as SVGChilling } from "@prakriti/assets/arts/chilling.svg";
import './chatView.scss';
import moment from "moment";

export enum structureItemTypes {
  dayDivider = "dayDivider",
  systemMessage = "systemMessage",
  messageGroup = "messageGroup",
}
export interface ChatViewComponentProps {
  ["chat"]?: ChatModel;
  ["organizarionId"]?: Number;
  ["loadMore"]: any;
}

export interface ChatViewComponentState {
  ["chatStructure"]: Array<any>;
}

class ChatViewComponent extends React.Component<
  ChatViewComponentProps,
  ChatViewComponentState
  > {
  private chatContent = React.createRef<NamaInfiniteScroll>();
  constructor(
    public props: ChatViewComponentProps
  ) {
    super(props);

    this.state = {
      chatStructure: [],
    }
  }

  private updateScroll() {
    const chatContent = this.chatContent.current;
    if (chatContent) {
      chatContent.scrollToBegin();
    }
  }

  private buildChatStructure() {
    const messages = this.props.chat ? this.props.chat.messages : [];
    const chatStructure = messages.reduce<Array<any>>(
      (structure, message) => {
        if (structure.length === 0 || (
          structure[structure.length - 1].messages &&
          moment(message.created_at)
            .diff(structure[structure.length - 1].messages[0].created_at, "days") > 1
        )) {
          structure.push({
            type: structureItemTypes.dayDivider,
            date: message.created_at,
          });
        }

        if (message.metadata && message.metadata.system_message === "true") {
          structure.push({
            type: structureItemTypes.systemMessage,
            message,
          });
          return structure;
        }

        if (structure[structure.length - 1].type !== 'messageGroup') {
          structure.push({
            type: structureItemTypes.messageGroup,
            messages: [message],
          });
          return structure
        }

        if (
          moment(message.created_at)
            .diff(structure[structure.length - 1].messages[0].created_at, "minutes") > 1
        ) {
          structure.push({
            type: structureItemTypes.messageGroup,
            messages: [message],
          });
          return structure;
        }

        structure[structure.length - 1].messages.push(message);

        return structure;
      }, []
    );
    //this.setState({ chatStructure });
    return chatStructure;
  }



  public componentWillUpdate(prevProps: ChatViewComponentProps, prevState: ChatViewComponentState) {
    const { chat } = this.props;
    if (chat)
      this.buildChatStructure();
  }

  public componentDidUpdate(prevProps: ChatViewComponentProps, prevState: ChatViewComponentState) {
    const { chat } = this.props;

    if (
      (chat &&
        prevProps.chat &&
        chat.id !== prevProps.chat.id) ||
      (chat &&
        !prevProps.chat)
    ) {
      this.updateScroll();
    }
  }


  public render() {
    const { chat, loadMore, organizarionId, } = this.props;
    if (!chat) {
      return <NamaViewPlaceholder
        svgImage={SVGChilling}
        title="Histórico de conversas"
        content="Para começar, escolha uma conversa ao lado."
      />
    }
    return (
      <div className="chat-view">
        <NamaInfiniteScroll canLoadMore={!chat.allMessagesLoaded} load={() => loadMore(organizarionId, chat.id, chat.messages[0].id)} ref={this.chatContent}>
          <div className="fill-top"></div>
          {
            !chat.allMessagesLoaded ?
              <NamaButton onClick={() => loadMore(organizarionId, chat.id, chat.messages[0].id)}>
                Carregar mensagens mais antigas
              </NamaButton>
              :
              <NamaChatBegginingMessage text="Essa conversa começa aqui" />
          }

          {
            this.buildChatStructure().map(
              item => {
                if (item.type === structureItemTypes.dayDivider) {
                  return <NamaDayDivider key={new Date(item.date).getTime()} date={item.date} />
                }
                if (item.type === structureItemTypes.systemMessage) {
                  return <NamaEventMessage message={item.message} />
                }
                if (item.type === structureItemTypes.messageGroup) {
                  return <NamaMessageGroup key={`${item.messages[0].id}_${item.messages[item.messages.length - 1].id}`} messages={item.messages} />;
                }
              }
            )
          }
        </NamaInfiniteScroll>

      </div>
    );
  }
}

export default ChatViewComponent;