import * as React from "react";
import { bindActionCreators } from "redux";
import { DashboardModel, UserActions, UserModel } from "@purusha/purusha";
import { connect } from "react-redux";
import NamaAccountFormApp from "./account.form";
import { UserInitialState } from "../../models/user.initial";

export interface AccountProps {
  ["user"]: UserModel;
  ["fetchCurrentUser"]: Function;
  ["updateUserInfo"]: Function;
}
export interface AccountState {
  ["user"]: any;
}

class AccountApp extends React.Component<AccountProps, AccountState> {
  constructor(public props: AccountProps, public state: AccountState) {
    super(props);
    this.state = {
      user: this.props.user
    };
  }

  public clean = () => {
    this.setState({ user: null });
  };

  private submit = (e: UserModel) => {
    const avatar = e.avatarUrl || e.avatar!.url;
    this.props.updateUserInfo(e.name, avatar);
  };

  public componentWillMount() {
    this.props.fetchCurrentUser();
  }

  public render() {
    return (
      <section className="nama-content center">
        <p>
          Set information about yourself. Customers will be able to see them
          during a chat.
        </p>
        {this.props.user === null ? (
          <div>Loading</div>
        ) : (
            <NamaAccountFormApp
              errors={this.props.user.errors}
              initial={this.props.user}
              onSubmit={this.submit}
            />
          )}
      </section>
    );
  }
}

const mapStateToProps = (state: DashboardModel) => ({
  user: state.user
});
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(UserActions, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountApp);
