import * as React from "react";
import {
  NamaButton,
  NamaIcon,
  NamaList,
  NamaModal,
  NamaConfirmation
} from "@prakriti/prakriti";
import {
  OrganizationsActions,
  OrganizationsModel,
  OrganizationModel,
  OrganizationInitialState,
  DashboardModel
} from "@purusha/purusha";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Translate } from "ns-react-i18next";
import NamaOrganizationFormApp from "./organization.form";

export interface NamaOrganizationsProps {
  ["organizations"]: OrganizationsModel;
  ["fetchOrganizations"]: any;
  ["createOrganization"]: any;
  ["updateOrganization"]: any;
  ["deleteOrganization"]: any;
  ["selectOrganization"]: any;
}
export interface NamaOrganizationsState {
  ["organization"]: OrganizationModel;
  ["isModalOpen"]: boolean;
}

class NamaOrganizationsApp extends React.Component<
  NamaOrganizationsProps,
  NamaOrganizationsState
  > {
  public actions: Array<any>;
  public confirmation: React.RefObject<NamaConfirmation>;

  constructor(
    public props: NamaOrganizationsProps,
    public state: NamaOrganizationsState
  ) {
    super(props);

    this.state = {
      organization: OrganizationInitialState,
      isModalOpen: false
    };
    this.confirmation = React.createRef<NamaConfirmation>();

    this.actions = [
      { name: "edit", icon: "edit", onClick: this.edit },
    ];
  }

  public openModal = () => {
    this.clean();
    this.setState({ isModalOpen: true });
  };

  public closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  public select = (item: any) => {
    this.setState({
      organization: {
        ...this.state.organization,
        name: item.title,
        id: item.id
      }
    });
  };

  public clean = () => {
    this.setState({ organization: OrganizationInitialState });
  };

  public edit = (e: any) => {
    this.select(e.detail);
    this.setState({ isModalOpen: true });
  };

  public componentWillMount = () => {
    this.props.fetchOrganizations();
  };

  public parseOrganizationToListItem = (
    organizations: Array<OrganizationModel>
  ): Array<any> => {
    return organizations.map(item => {
      return { id: item.id, title: item.name, label: item.role, url: item.url };
    });
  };

  public submit = (e: OrganizationModel) => {
    if (e.id == 0) {
      this.props.createOrganization(e);
    } else {
      this.props.updateOrganization(e);
    }
    this.clean();
    this.closeModal();
  };

  public selectOrganization = (e: OrganizationModel) => {
    this.props.selectOrganization(e);
  };

  public render = () => {
    return (
      <section className="nama-content small center">
        <p>
          <Translate> organizations_text </Translate>
        </p>
        <NamaButton
          className="clear big icon left fill-info"
          onClick={this.openModal}
        >
          <NamaIcon icon="add" /> <Translate> organization_new </Translate>
        </NamaButton>
        <NamaList
          onClick={this.selectOrganization}
          data={this.parseOrganizationToListItem(this.props.organizations.list)}
          actions={this.actions}
        />
        <NamaModal
          isOpen={this.state.isModalOpen}
          onDiscardByUser={this.closeModal}
        >
          <NamaOrganizationFormApp
            initial={this.state.organization}
            onSubmit={this.submit}
            onCancel={this.closeModal}
          />
        </NamaModal>
      </section>
    );
  };
}

const mapStateToProps = (state: DashboardModel) => ({
  organizations: state.organizations
});
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(OrganizationsActions, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NamaOrganizationsApp);
