import * as React from "react";
import * as Yup from "yup";
import {
  NamaButton,
  NamaInput,
  NamaAccordeon,
  NamaIcon
} from "@prakriti/prakriti";
import { Formik, Form } from "formik";
import ConversableComponent from "../conversable.component";
import {
  ConversableModel,
  FacebookActions,
  DashboardModel
} from "@purusha/purusha";
import FacebookPagesComponent from "./facebook.pages";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { OrganizationModel } from "../../../models/organization.model";

export interface FacebookChannelComponentProps {
  ["channel"]: any;
  ["publish"]?: any;
  ["cancel"]: Function;
  ["submit"]: Function;
  // injected
  ["pages"]?: Array<any>;
  ["fb_page"]?: any;
  ["selectPage"]: Function;
  ["organization"]?: OrganizationModel;
  ["fetchPages"]: Function;
}
export interface FacebookChannelComponentState {
  ["conversable"]?: ConversableModel;
  ["channel"]?: any;
  ["fb_page"]?: any;
  ["fbConnected"]: boolean;
  ["fbInitialized"]: boolean;
}

class FacebookChannelComponent extends React.Component<
  FacebookChannelComponentProps,
  FacebookChannelComponentState
> {
  public formik: any;
  public initial: any;
  constructor(public props: FacebookChannelComponentProps) {
    super(props);
    this.formik = React.createRef();
    this.state = {
      channel: this.props.publish,
      fb_page: this.props.publish ? this.props.publish.page : undefined,
      fbConnected: false,
      fbInitialized: false
    };

    // add conversable direct to state when team
    if (
      this.props.publish &&
      this.props.publish.conversable &&
      this.props.publish.conversable.type === "team"
    ) {
      this.state = {
        ...this.state,
        conversable: this.props.publish.conversable
      };
    }
  }

  public changeConversable = (conversable: ConversableModel) => {
    this.setState({ conversable });
    this.props;
  };

  public facebookInit = () => {
    (window as any).FB.init({
      appId: "857416350991357",
      cookie: true, // enable cookies to allow the server to access
      // the session
      xfbml: true, // parse social plugins on this page
      version: "v2.1" // use version 2.1
    });
  };

  public componentDidMount = () => {
    let post_execute = typeof (window as any).fbAsyncInit == "function";
    (window as any).fbAsyncInit = () => {
      this.facebookInit();

      (window as any).FB.getLoginStatus((response: any) => {
        this.setState({
          fbInitialized: true
        });
        this.statusChangeCallback(response);
      });
    };

    if (post_execute) {
      (window as any).fbAsyncInit();
    }
    // Load the SDK asynchronously
    (function(d, s, id) {
      var js: any;
      var fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  };

  public submit = () => {
    if (this.state.conversable && this.state.fb_page) {
      this.props.submit({
        id: this.state.channel ? this.state.channel.id : undefined,
        title: this.state.fb_page.name,
        conversable: this.state.conversable,
        config: JSON.stringify({}),
        token: this.state.fb_page.access_token,
        fb_id: this.state.fb_page.fb_id,
        icon_url: this.state.fb_page.icon_url
      });
    }
  };

  public statusChangeCallback = (response: any) => {
    if (response.status === "connected") {
      // Logged into your app and Facebook.
      this.setState({
        fbConnected: true
      });
      // get assiciated facebook pages from server
      if (this.props.organization) {
        this.props.fetchPages(
          this.props.organization.id,
          response.authResponse.accessToken
        );
      }
    } else if (response.status === "not_authorized") {
      // The person is logged into Facebook, but not your app.
      this.setState({
        fbConnected: false
      });
    } else {
      // The person is not logged into Facebook, so we're not sure if
      // they are logged into this app or not.
      this.setState({
        fbConnected: false
      });
    }
  };

  // This function is called when someone finishes with the Login
  // Button.  See the onlogin handler attached to it in the sample
  // code below.
  public checkLoginState = () => {
    (window as any).FB.getLoginStatus((response: any) => {
      this.statusChangeCallback(response);
    });
  };

  public logout = () => {
    (window as any).FB.logout((response: any) => {
      this.statusChangeCallback(response);
      this.facebookInit();
    });
  };

  public handleClick = () => {
    (window as any).FB.login(this.checkLoginState());
  };

  public render() {
    // this is to make facebook button can reach checkLoginState
    (window as any).checkLoginState = this.checkLoginState;

    return (
      <div>
        <p>
          Connect to a Facebook account and choose a page to start receiving
          chats from your customers
        </p>
        <ConversableComponent
          {...this.props.publish && {
            conversable: this.props.publish.conversable
          }}
          change={this.changeConversable}
          name="conversable"
        />

        <section className="nama-destacked-content" style={{ marginTop: 32 }}>
          <div className="header" />
          <p>{!this.state.fb_page}</p>

          {!this.state.fb_page && !this.state.fbInitialized && (
            <div>
              <p>Initializing facebook api...</p>
            </div>
          )}

          {this.state.fb_page && (
            <NamaAccordeon
              avatar={
                this.state.fb_page.icon_url
                  ? this.state.fb_page.icon_url
                  : undefined
              }
              title={this.state.fb_page.name || "-"}
            >
              <div style={{ display: "table" }}>
                <NamaButton
                  className="clear icon left small"
                  onClick={() => {
                    this.setState({
                      fb_page: undefined
                    });
                    this.props.selectPage(undefined);
                  }}
                >
                  <NamaIcon className="fill-red-300" icon="delete" /> Select
                  another page
                </NamaButton>
              </div>
            </NamaAccordeon>
          )}

          {!this.state.fb_page && this.state.fbConnected && (
            <FacebookPagesComponent
              pages={this.props.pages}
              select={(selected_page: any) => {
                let fb_page: any = {
                  icon_url: selected_page.picture.url,
                  fb_id: selected_page.page.id,
                  ...selected_page.page
                };

                this.setState({
                  fb_page: fb_page
                });
                this.props.selectPage(fb_page);
              }}
            />
          )}
          <div>
            {this.state.fbConnected ? (
              <a onClick={this.logout} className="nama-button-old primary padding">
                Logout from Facebook
              </a>
            ) : (
              <div
                className="fb-login-button"
                data-max-rows="1"
                data-size="large"
                data-scope="public_profile,email,manage_pages,read_page_mailboxes,pages_messaging"
                data-onlogin="checkLoginState();"
                data-button-type="continue_with"
                data-show-faces="false"
                data-auto-logout-link="false"
                data-use-continue-as="true"
              />
            )}
          </div>
        </section>

        <Form className="nama-form" style={{ display: "block" }}>
          <div className="actions" style={{ width: "100%", float: "left" }}>
            <NamaButton
              className={`nama-button-old primary ${
                this.state.conversable && this.state.fb_page
                  ? "active"
                  : "disabled"
              }`}
              onClick={() => {
                this.submit();
              }}
            >
              Save
            </NamaButton>
            <NamaButton className="clear" onClick={this.props.cancel}>
              Cancel
            </NamaButton>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state: DashboardModel) => ({
  organization: state.organizations.selected,
  pages: state.facebook.pages,
  fb_page: state.facebook.selected
});
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...FacebookActions
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FacebookChannelComponent);
