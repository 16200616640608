import {
  OrganizationsModel,
  OrganizationModel,
  OrganizationsInitialState
} from "@purusha/purusha";

export function OrganizationsReducer(
  state: OrganizationsModel = OrganizationsInitialState,
  action: any
) {
  switch (action.type) {
    case "FETCH_INFORMATION":
      if (action.payload.data) {
        return {
          ...state,
          selected: {
            ...state.selected,
            settings: action.payload.data,
          }
        };
      } else {
        return state;
      }

    case "CREATE_ORGANIZATION":
      if (action.payload.status == 200) {
        action.payload.data.organization.role = "admin";
        return {
          ...state,
          list: [...state.list, action.payload.data.organization]
        };
      } else {
        return state;
      }

    case "UPDATE_ORGANIZATION":
      if (action.payload.status == 200) {
        let updatedList: Array<OrganizationModel> = state.list.map(
          (organization: OrganizationModel) => {
            if (organization.id == action.payload.data.organization.id) {
              action.payload.data.organization.role = organization.role;
              return action.payload.data.organization;
            } else {
              return organization;
            }
          }
        );
        return { ...state, list: updatedList };
      } else {
        return state;
      }

    case "DELETE_ORGANIZATION":
      if (action.payload.status == 200) {
        let updatedList: Array<OrganizationModel> = state.list.filter(
          (organization: OrganizationModel) => {
            return organization.id != action.payload.data.organization.id;
          }
        );
        return { ...state, list: updatedList };
      } else {
        return state;
      }

    case "FETCH_ORGANIZATIONS":
      if (action.payload.data) {
        let list = action.payload.data.map((item: any) => {
          return {
            id: item.organization_id,
            role: item.role,
            name: item.organization.name,
            token: item.organization.token
          };
        });
        return { ...state, list: list };
      } else {
        return state;
      }

    case "SELECT_ORGANIZATION":
      let selectedOrganization = state.list.filter(
        (e: OrganizationModel) => e.id === action.payload.id
      );
      localStorage.setItem('selectedOrganization', JSON.stringify(selectedOrganization[0]))
      return { ...state, selected: selectedOrganization[0] };

    case "UNSELECT_ORGANIZATION":
      return { ...state, selected: undefined };

    case "DELETE_ORGANIZATION_DOMAIN":
      if (action.payload.status == 200) {
        let updatedList: Array<any> = state.domains.filter((domain: any) => {
          return domain.id != action.payload.data.domain.id;
        });
        return { ...state, domain: updatedList };
      } else {
        return state;
      }

    case "FETCH_ORGANIZATION_DOMAIN":
      return {
        ...state,
        domains: action.payload.data.map((item: any) => {
          return {
            id: item.id,
            domain: item.domain
          };
        })
      };

    case "ADD_ORGANIZATION_DOMAIN":
      return {
        ...state,
        domains: [
          ...state.domains,
          {
            id: action.payload.data.domain.id,
            domain: action.payload.data.domain.domain
          }
        ]
      };

    default:
      return state;
  }
}
