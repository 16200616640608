import WidgetOptions from "./options.widget.model";
import { host_url } from "@purusha/config";

export const WidgetOptionsInitialState: WidgetOptions = {
  apiToken: "",
  cname: "nama-sdk-user",
  serverUrl: host_url || "https://dashboard.nama.ai",
  poolingTimeout: 1000,
  poolingStep: 2000,
  poolingLimit: 40000,
  singleUse: false,
  autoStart: true,
  onboarding: {
    text: ""
  },
  //widget
  maxTop: 150,
  title: "Channel`s Title",
  call: "Hello World",
  size: "default",
  behavior: "default",
  placeholder: "Type your message...",
  noClose: false,
  whiteLabel: false
};
