import { FacebookService } from "@purusha/purusha";

export let fetchPages = (organization_id: string, access_token: string) => {
  const request = FacebookService.fetch_pages(organization_id, access_token);
  return {
    type: "FETCH_FACEBOOK_PAGES",
    payload: request
  };
};

export let selectPage = (page: any) => {
  return {
    type: "SELECT_FACEBOOK_PAGE",
    payload: page
  };
};
