import * as React from "react";
import moment from "moment";
import { AssistantModel } from "@purusha/purusha";
import {
  NamaButton,
  NamaIcon,
  NamaAccordeon,
  NamaModal
} from "@prakriti/prakriti";
import { Translate } from "ns-react-i18next";
import FlowVersionForm from "./flow-version.form";

export interface FlowVersionsComponentProps {
  ["assistant"]?: AssistantModel;
  ["errors"]?: any;
  ["submit"]: Function;
}
export interface FlowVersionsComponentState {
  ["isModalOpen"]: boolean;
  ["zip_file"]?: File;
}

class FlowVersionsComponent extends React.Component<
  FlowVersionsComponentProps,
  FlowVersionsComponentState
> {
  public formik: any;

  constructor(
    public props: FlowVersionsComponentProps,
    public state: FlowVersionsComponentState
  ) {
    super(props, state);
    this.state = {
      isModalOpen: false
    };
  }

  public closeFlowModal = () => {
    this.setState({ isModalOpen: false });
  };

  public openFlowModal = (e: any) => {
    this.setState({ isModalOpen: true });
  };

  public download = (e: any) => {
    const link = document.createElement("a");
    link.href = e;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  public render() {
    return (
      this.props.assistant && (
        <div>
          <section className="nama-destacked-content">
            <div className="header">
              <NamaIcon icon="flow" className="fill-blue-300" />
              <NamaButton
                onClick={this.openFlowModal}
                className="background-blue-300 color-white small padded"
              >
                <Translate>new_flow</Translate>
              </NamaButton>
            </div>

            {this.props.assistant.flow_versions &&
              this.props.assistant.flow_versions.map(
                (flow_version: any, index: number) => {
                  return (
                    <NamaAccordeon
                      key={index}
                      title={flow_version.title}
                      subtitle={moment(flow_version.created_at).fromNow()}
                    >
                      <h4>
                        Flow created in{" "}
                        {moment(flow_version.created_at).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </h4>
                      <p>{flow_version.description}</p>
                      <NamaButton
                        className="clear icon left small"
                        onClick={() => {
                          this.download(flow_version.zip_file.url);
                        }}
                      >
                        <NamaIcon className="fill-blue-300" icon="folder" />{" "}
                        Download Files
                      </NamaButton>
                      <NamaButton
                        style={{ float: "right" }}
                        className="clear icon left small"
                      >
                        <NamaIcon icon="trash" /> Delete Version
                      </NamaButton>
                    </NamaAccordeon>
                  );
                }
              )}
          </section>

          <NamaModal
            isOpen={this.state.isModalOpen}
            size="large"
            onDiscardByUser={this.closeFlowModal}
          >
            <h3>
              <Translate>upload_flow_tilte</Translate>
            </h3>
            <p>
              <Translate>upload_flow_description</Translate>
            </p>
            <FlowVersionForm
              submit={(e: any) => {
                this.props.submit(e);
                this.closeFlowModal();
              }}
              dismiss={this.closeFlowModal}
            />
          </NamaModal>
        </div>
      )
    );
  }
}

export default FlowVersionsComponent;
