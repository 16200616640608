import * as React from "react";
import { MessageModel } from '@purusha/models/message.model';
import './message-bubble.scss';

export interface MessageBubbleComponentProps {
  ["message"]: MessageModel
}

class MessageBubbleComponent extends React.Component<
  MessageBubbleComponentProps
  > {
  constructor(
    public props: MessageBubbleComponentProps
  ) {
    super(props);
  }

  public getMessageOwner() {
    const { sent_by_user, metadata } = this.props.message;
    if (sent_by_user) {
      return 'user';
    }
    if (metadata && metadata.operator) {
      return 'operator';
    }
    return 'bot';
  }

  public render() {
    const { text } = this.props.message;
    return (
      <div className={`message-bubble ${this.getMessageOwner()}`}>
        {text}
      </div>
    );
  }
}

export default MessageBubbleComponent;