import * as React from "react";
import { AssistantModel } from "@purusha/purusha";
import {
  NamaButton,
  NamaConversable,
  NamaConfirmation
} from "@prakriti/prakriti";
import { Translate } from "ns-react-i18next";
import FlowVersionsComponent from "./flow-versions.component";

export interface AssistantComponentProps {
  ["assistant"]?: AssistantModel;
  ["errors"]?: any;
  ["action"]: Function;
  ["add"]: Function;
  ["load"]: Function;
}
export interface AssistantComponentState {
  ["isModalOpen"]: boolean;
  ["zip_file"]?: File;
}

class AssistantComponent extends React.Component<
  AssistantComponentProps,
  AssistantComponentState
  > {
  public formik: any;

  constructor(
    public props: AssistantComponentProps,
    public state: AssistantComponentState
  ) {
    super(props, state);
    this.state = {
      isModalOpen: false
    };
  }
  public closeFlowModal = () => {
    this.setState({ isModalOpen: false });
  };

  public openFlowModal = (e: any) => {
    this.setState({ isModalOpen: true });
  };

  public componentWillMount() {
    this.props.load();
  }

  public componentWillUpdate(next: any) {
    if (this.props.assistant) {
      if (this.props.assistant.id !== next.assistant.id) {
        this.props.load();
      }
    }
  }

  public render() {
    const confirmation = React.createRef<NamaConfirmation>();
    return (
      <section className="nama-content">
        {this.props.assistant && (
          <div>
            <NamaConversable
              {...this.props.assistant}
              url={this.props.assistant.avatar}
            >
              <NamaButton
                onClick={() => {
                  this.props.action("edit", this.props.assistant);
                }}
                className="nama-button-old"
              >
                Editar
              </NamaButton>
              <NamaButton
                onClick={() => {
                  if (confirmation.current) {
                    confirmation.current.open();
                  }
                }}
                className="nama-button-old"
              >
                Delete
              </NamaButton>
            </NamaConversable>

            <FlowVersionsComponent
              submit={this.props.add}
              assistant={this.props.assistant}
            />

            <NamaConfirmation
              onSuccess={() => {
                this.props.action("delete", this.props.assistant);
              }}
              ref={confirmation}
              confirmation={this.props.assistant.name}
            >
              <div>
                <h3>
                  <Translate> assistant_delete_title </Translate>
                </h3>
                <p>
                  <Translate> assistant_delete_descript </Translate>
                </p>
              </div>
            </NamaConfirmation>
          </div>
        )}
      </section>
    );
  }
}

export default AssistantComponent;
