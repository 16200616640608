import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ReactComponent as SVGAssistantsNew } from "../../../prakriti/assets/arts/assistants-art.svg";
import {
  AssistantsActions,
  DashboardModel,
  AssistantsModel,
  AssistantModel,
  AssistantInitialState,
  OrganizationModel
} from "@purusha/purusha";
import { NamaNavigationTemplate, NamaTabsTemplate } from "@prakriti/prakriti";
import AssistantComponent from "./assistant.component";
import NamaAssistantFormApp from "./assistant.form";
import { fetchFlowVersions } from "../../actions/assistants.actions";

export interface AssistantsProps {
  ["assistants"]: AssistantsModel;
  ["organization"]?: OrganizationModel;
  ["updateAssistant"]: any;
  ["discardAssistant"]: any;
  ["createAssistant"]: any;
  ["selectAssistant"]: any;
  ["fetchAssistants"]: any;
  ["fetchFlowVersions"]: any;
  ["addFlowVersion"]: any;
}

export interface AssistantsState {
  ["assistant"]: AssistantModel;
}

class AssistantsApp extends React.Component<AssistantsProps, AssistantsState> {
  constructor(public props: AssistantsProps, public state: AssistantsState) {
    super(props);
    this.state = {
      assistant: AssistantInitialState
    };
  }

  public componentWillMount = () => {
    if (this.props.organization) {
      this.props.fetchAssistants(this.props.organization.id);
    }
  };

  public select = (id: string, action?: string) => {
    this.props.selectAssistant(id, action);
  };

  public submit = (e: AssistantModel) => {
    if (this.props.organization) {
      if (e.id == 0) {
        this.props.createAssistant(this.props.organization.id, e);
      } else {
        this.props.updateAssistant(this.props.organization.id, e);
      }
    }
  };

  public cancel = () => {
    if (this.props.assistants.selected) {
      this.props.selectAssistant(this.props.assistants.selected.id);
    } else {
      this.props.selectAssistant("0");
    }
  };

  public delete = (e: number) => {
    if (this.props.organization) {
      this.props.discardAssistant(this.props.organization.id, e.toString());
    }
  };

  public fetchFlowVersions = () => {
    if (this.props.assistants.selected && this.props.organization) {
      this.props.fetchFlowVersions(
        this.props.organization.id,
        this.props.assistants.selected.token
      );
    }
  };

  public addFlowVersion = (e: any) => {
    if (this.props.assistants.selected && this.props.organization) {
      this.props.addFlowVersion(
        this.props.organization.id,
        this.props.assistants.selected.token,
        e
      );
    }
  };

  public render() {
    return (
      <NamaNavigationTemplate
        title="Assistant"
        selected={this.props.assistants.selected}
        items={this.props.assistants.list.map((assistant: AssistantModel) => {
          return {
            id: assistant.id,
            name: assistant.name,
            avatar: assistant.avatar || ""
          };
        })}
        action={this.props.assistants.action}
        templates={{
          empty: (
            <section
              className="nama-content micro"
              style={{ textAlign: "center" }}
            >
              <SVGAssistantsNew />
              <p style={{ width: 300, margin: "auto" }}>
                Select or create an assistant to see their settings, manage
                their flow or publish them to start chatting with your
                customers.{" "}
              </p>
            </section>
          ),
          view: (
            <AssistantComponent
              assistant={this.props.assistants.selected}
              load={this.fetchFlowVersions}
              errors={this.props.assistants.errors}
              add={this.addFlowVersion}
              action={(action: string, payload: AssistantModel) => {
                if (action === "delete") {
                  this.delete(payload.id);
                } else {
                  this.select(payload.id.toString(), action);
                }
              }}
            />
          ),
          edit: (
            <section className="nama-content micro">
              <NamaAssistantFormApp
                errors={this.props.assistants.errors}
                initial={this.props.assistants.selected || this.state.assistant}
                onSubmit={this.submit}
                onCancel={this.cancel}
              />
            </section>
          ),
          new: (
            <section className="nama-content micro">
              <NamaAssistantFormApp
                errors={this.props.assistants.errors}
                initial={this.props.assistants.selected || this.state.assistant}
                onSubmit={this.submit}
                onCancel={this.cancel}
              />
            </section>
          )
        }}
        select={(payload: string, action: string) => {
          this.select(payload, action);
        }}
      />
    );
  }
}

const mapStateToProps = (state: DashboardModel) => ({
  assistants: state.assistants,
  organization: state.organizations.selected
});
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(AssistantsActions, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssistantsApp);
