import * as React from "react";
import { FormikProps, FormikState, connect } from "formik";

export interface IEffectProps<TValues = {}> {
  onChange(
    currentState: FormikState<TValues>,
    prevState: FormikState<TValues>
  ): void;
  formik: FormikProps<TValues>;
}

const Effect = class FormikEffect<TValues = {}> extends React.Component<
  IEffectProps<TValues>,
  {}
> {
  componentDidUpdate(prevProps: IEffectProps<TValues>) {
    const {
      values,
      touched,
      errors,
      isSubmitting,
      isValidating
    } = this.props.formik;
    const {
      values: prevValues,
      touched: prevTouched,
      errors: prevErrors,
      isSubmitting: prevIsSubmitting,
      isValidating: prevIsValidating
    } = prevProps.formik;
    if (
      this.props.formik &&
      prevProps.formik.values !== this.props.formik.values
    ) {
      this.props.onChange(
        {
          values,
          touched,
          errors,
          isSubmitting,
          isValidating
        } as FormikState<TValues>,
        {
          values: prevValues,
          touched: prevTouched,
          errors: prevErrors,
          isSubmitting: prevIsSubmitting,
          isValidating: prevIsValidating
        } as FormikState<TValues>
      );
    }
  }

  render() {
    return null;
  }
};

export default connect(Effect);
