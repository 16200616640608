import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { OrganizationsActions, DashboardModel, OrganizationModel } from "@purusha/purusha";
import {
  NamaIcon,
  NamaAccordeon,
  NamaAvatar,
  NamaButton,
  NamaMiniDonutChart,
} from "@prakriti/prakriti";

import {
  Card as NamaCard
} from "nama-design";

export interface InformationComponentProps {
  ["organization"]?: OrganizationModel;
  ["fetchInformation"]?: any;
}

export interface InformationComponentState { }

class InformationComponent extends React.Component {
  constructor(
    public props: InformationComponentProps,
    public state: InformationComponentState
  ) {
    super(props);
    this.state = {};
  }


  public componentWillMount = () => {
    if (this.props.organization) {
      this.props.fetchInformation(this.props.organization);
    }
  };

  private renderAssistantInfo = () => (
    <div></div>
    // <div className="row">
    //   <div className="col-md-3">
    //     <section className="nama-card dark">
    //       <div className="nama-card-header">
    //         <NamaIcon icon="chat" />
    //         <h1>API Calls</h1>
    //       </div>
    //       <div className="nama-card-content">
    //         <div className="card-items">
    //           <span>Total/month</span>
    //           <strong className="color-blue-200">100.000</strong>
    //           <hr />
    //           <span>Used</span> <strong>51.092</strong>
    //           <hr />
    //           <span>Available</span> <strong>48.908</strong>
    //         </div>
    //       </div>
    //     </section>

    //     <NamaButton className="expanded primary">Upgrade my plan</NamaButton>
    //   </div>

    //   <div className="col-md-9">
    //     <NamaAccordeon
    //       title="title"
    //       subtitle={
    //         <NamaMiniDonutChart
    //           current={51092}
    //           maximum={100000}
    //           show_as_percentage
    //         />
    //       }
    //     >
    //       <ul className="ul-list">
    //         <li className="row">
    //           <span className="col-md">Total chats this month</span>
    //           <span className="col-md end-md">12.012</span>
    //         </li>
    //         <li className="row">
    //           <span className="col-md">Total API Calls this month</span>
    //           <span className="col-md end-md">1203.012</span>
    //         </li>
    //       </ul>
    //     </NamaAccordeon>
    //   </div>
    // </div>
  );

  private renderTeamInfo = (team: any, index: number, total: number = 10) => (
    <NamaAccordeon
      title={team.name}
      noArrow={true}
      key={index}
      subtitle={
        <NamaMiniDonutChart
          current={team.users.length}
          maximum={total}
        />
      }
    >
    </NamaAccordeon>
  )

  private renderDeskInfo = () => (
    <div className="row space-above">
      <div className="col-md-3">
        <NamaCard
          header={{
            iconName: "Desk",
            label: "Users",
            iconColor: "blue-300"
          }}
          items={
            [
              {
                label: "Total",
                value: this.props.organization && this.props.organization.settings ? this.props.organization.settings.members.total : ""
              },
              {
                label: "Used",
                value: this.props.organization && this.props.organization.settings ? this.props.organization.settings.members.used : ""
              },
              {
                label: "Available",
                value: this.props.organization && this.props.organization.settings ? this.props.organization.settings.members.total - this.props.organization.settings!.members.used : ""
              },
            ]
          }
          dark={true}
        ></NamaCard>

        {/* <NamaButton className="expanded primary">Get more seats</NamaButton> */}
      </div>

      <div className="col-md-9">
        {
          this.props.organization &&
          this.props.organization.settings &&
          this.props.organization.settings.members &&
          this.props.organization.settings.members.teams.map((team: any, index: number) => {
            return this.renderTeamInfo(team, index, this.props.organization!.settings!.members!.total)
          })
        }
      </div>
    </div>
  );

  public render() {
    return (
      <section className="nama-content center">


        {this.renderAssistantInfo()}

        {this.props.organization && <div>
          <p>Organization: {this.props.organization.name}</p>
          <p>Organization ID: {this.props.organization.id}</p>
          <p>Organization Token: {this.props.organization.token}</p>
        </div>}

        {this.renderDeskInfo()}
      </section>
    );
  }
}

const mapStateToProps = (state: DashboardModel) => ({
  organization: state.organizations.selected
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(OrganizationsActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InformationComponent);
