import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import { host_url } from "@purusha/config";
var obj2fd = require("obj2fd").default;

export default class BotsService {
  public static base_url: string = `${host_url}/api/v1`

  public static create(organization_id: string, bot: any): AxiosPromise<any> {
    let url: string = `${this.base_url}/organizations/${organization_id}/bots`;
    return axios.post(url, {
      bot: bot
    });
  }

  public static list(organization_id: string): AxiosPromise<Array<any>> {
    const url: string = `${this.base_url}/organizations/${organization_id}/bots`;
    return axios.get(url);
  }

  public static update(organization_id: string, bot: any): AxiosPromise {
    const url: string = `${this.base_url}/organizations/${organization_id}/bots/${bot.id}`;
    return axios.put(url, { bot });
  }

  public static discard(
    organization_id: string,
    bot_id: string
  ): AxiosPromise<Array<any>> {
    const url: string = `${this.base_url}/organizations/${organization_id}/bots/${bot_id}`;
    return axios.delete(url);
  }

  public static restart(
    organization_id: string,
    bot_id: string
  ): AxiosPromise<Array<any>> {
    const url: string = `${this.base_url}/organizations/${organization_id}/bots/${bot_id}/restart_bot`;
    return axios.post(url);
  }
}
