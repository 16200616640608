import axios, { AxiosPromise } from "axios";
import { host_url } from "@purusha/config";

export default class TeamsService {
  public static base_url: string = `${host_url}/api/v1`

  public static teams(organization_id: string): AxiosPromise<Array<any>> {
    const url: string = `${this.base_url}/organizations/${organization_id}/teams`;
    return axios.get(url);
  }

  public static create(organization_id: string, team: any) {
    let url: string = `${this.base_url}/organizations/${organization_id}/teams`;
    return axios.post(url, { team: team });
  }

  public static update(organization_id: string, team: any): AxiosPromise {
    const url: string = `${this.base_url}/organizations/${organization_id}/teams/${team.id}`;
    return axios.put(url, { team: team });
  }

  public static discard(organization_id: string, id: string): AxiosPromise {
    const url: string = `${this.base_url}/organizations/${organization_id}/teams/${id}`;
    return axios.delete(url);
  }

  public static members_team(
    organization_id: string,
    team_id: string
  ): AxiosPromise {
    const url: string = `${this.base_url}/organizations/${organization_id}/teams/${team_id}/members`;
    return axios.get(url);
  }

  public static discard_team_member(
    organization_id: string,
    team_id: string,
    user_id: string
  ): AxiosPromise {
    const url: string = `${this.base_url}/organizations/${organization_id}/teams/${team_id}/remove_user/${user_id}`;
    return axios.delete(url);
  }

  public static add_member_in_team(
    organization_id: string,
    team_id: string,
    user_id: string,
    max_calls: number
  ): AxiosPromise {
    const url: string = `${this.base_url}/organizations/${organization_id}/teams/${team_id}/add_member`;
    return axios.post(url, {
      desk_option: {
        max_simultaneous_calls: max_calls.toString(),
        user_id: user_id
      }
    });
  }

  public static update_member_in_team(
    organization_id: string,
    team_id: string,
    user_id: string,
    max_simultaneous_calls: number
  ): AxiosPromise {
    const url: string = `${this.base_url}/organizations/${organization_id}/teams/${team_id}/update_user/${user_id}`;
    return axios.put(url, {
      desk_option: {
        max_simultaneous_calls: max_simultaneous_calls.toString()
      }
    });
  }
}
