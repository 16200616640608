export const Translations = {
  en: {
    default: {
      // actions
      create: "Create",
      cancel: "Cancel",
      update: "Update",
      delete: "Delete",
      confirm: "Confirm",
      invite: "Invite",
      //  substantivos
      name: "Name",
      avatar: "Avatar",
      description: "Description",
      email: "E-mail",
      role: "Role",
      teams: "Teams",
      members: "Members",
      member: "Member",
      // adjetivos
      administrator: "Administrator",
      operator: "Operator",
      developer: "Developer",
      // validations
      validation_short: "This field is to short",
      validation_long: "This field is to long",
      validation_required: "You must have to fill this field",
      validation_email: "You must have to fill this field with valid e-mai",
      validation_same: "The confirmation name and the input should be the same",
      // teams
      team_delete_title: "Confirm team deletion",
      team_delete_descript: "To delete, write the name of team below:",
      select_user_to_add: "Select a member to add",
      // channels
      new_channel: "New Channel Connection",
      channel_delete_title: "Confirm channel deletion",
      channel_delete_descript: "To delete, write the name of channel below:",
      // assistants
      assistant_delete_title: "Confirm assistant deletion",
      new_flow: "New Flow",
      assistant_delete_descript:
        "To delete, write the name of assistant below:",
      upload_flow_tilte: "Upload New Flow",
      upload_flow_description: "Follow the next steps to add a new flow",
      // assistants
      member_delete_title: "Confirm member deletion",
      member_delete_descript: "To delete, write the name of member below:",
      // organization
      organizations_text:
        "An organization represents your business and allows you to manage users and other settings on accounts and features.",
      organization_new: "New Organization",
      organization_create_title: "Create Organization",
      organization_create_descript:
        "An organization represents your business and allows you to manage users and other settings on accounts and features.",
      organization_edit_title: "Update Organization",
      organization_edit_descript:
        "An organization represents your business and allows you to manage users and other settings on accounts and features.",
      organization_delete_title: "Confirm organization deletion",
      organization_delete_descript:
        "To delete, write the name of organization below:"
    }
  }
};
