import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { OrganizationsActions, DashboardModel } from "@purusha/purusha";
import { NamaTabsTemplate } from "@prakriti/prakriti";
import MembersComponent from "./members.component";
import TeamsComponent from "./teams.component";
import { withRouter } from "react-router-dom";
export interface MembersProps {
  ["members"]: any;
  ["location"]: any;
  ["history"]: any;
  ["match"]: any;
}
export interface MembersState {}
class MembersApp extends React.Component<MembersProps, MembersState> {
  public render() {
    return (
      <NamaTabsTemplate
        tabs={[
          {
            component: MembersComponent,
            title: "members"
          },
          {
            component: TeamsComponent,
            title: "teams"
          }
        ]}
      />
    );
  }
}

const mapStateToProps = (state: DashboardModel) => ({ members: state });
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(OrganizationsActions, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MembersApp));
