import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ReactComponent as SVGMeditation } from "@prakriti/assets/arts/meditating.svg";
import {
  ChatsActions,
  OrganizationModel,
  DashboardModel,
  ChatModel
} from "@purusha/purusha";
import { NamaConversationsLayout, NamaChatList, NamaChatView, NamaViewPlaceholder } from "@prakriti/prakriti";
import { IDateRange, DropdownItem } from "nama-design";

export interface ConversationsComponentProps {
  ["organization"]?: OrganizationModel;
  ["chatList"]: Array<ChatModel>;
  ["selected"]?: ChatModel;
  ["allChatsLoaded"]: Boolean;
  ["fetchChatHistory"]: any;
  ["clearChats"]: any;
  ["loadChatMessages"]: any;
  ["selectChat"]: any;
  ["dateFilter"]?: IDateRange;
  ["channelFilter"]?: Array<DropdownItem>;
}

class ConversationsComponent extends React.Component<
  ConversationsComponentProps
  > {

  constructor(
    public props: ConversationsComponentProps
  ) {
    super(props);

    this.state = {
      selectedId: undefined,
    }
  }

  componentDidUpdate(prevProps: ConversationsComponentProps) {
    const { organization, clearChats, fetchChatHistory, dateFilter, channelFilter } = this.props;
    if ((prevProps.dateFilter !== this.props.dateFilter) ||
      (prevProps.channelFilter !== this.props.channelFilter)) {
      organization &&
        fetchChatHistory(organization.id, { dateFilter, channelFilter });
    }
  }

  componentDidMount() {
    const { organization, clearChats, fetchChatHistory, dateFilter, channelFilter } = this.props;
    organization &&
      fetchChatHistory(organization.id, { dateFilter, channelFilter });
  }

  loadMoreChats = () => {
    const { chatList, fetchChatHistory, organization, dateFilter, channelFilter } = this.props;
    if (!organization) {
      return;
    }
    fetchChatHistory(organization.id, { chatId: chatList[chatList.length - 1].id, dateFilter, channelFilter });
    return
  }

  selectChat = (chat: ChatModel) => {
    const { loadChatMessages, organization, selectChat } = this.props;
    if (!chat.messages && organization) {
      loadChatMessages(organization.id, chat.id);
    } else {
      selectChat(chat)
    }
  }

  public render() {
    const { chatList, organization, loadChatMessages, selected, allChatsLoaded } = this.props;
    return (
      <NamaConversationsLayout>
        {
          chatList.length > 0 ?
            <>
              <NamaChatList
                selected={selected}
                chatList={chatList}
                selectChat={this.selectChat}
                organizationId={organization && organization.id}
                loadMore={!allChatsLoaded && this.loadMoreChats}
              />
              <NamaChatView chat={selected} loadMore={loadChatMessages} organizarionId={organization && organization.id} />
            </>
            :
            <NamaViewPlaceholder
              svgImage={SVGMeditation}
              title="Ainda não há conversas"
              content="Divulgue seu canal de chat para poder ver as conversas por aqui."
            />
        }

      </NamaConversationsLayout>
    );
  }
}

const mapStateToProps = (state: DashboardModel) => {
  const {
    list,
    selected,
    isAllLoaded,
  } = state.chats;

  const {
    dateFilter,
    channelFilter,
  } = state.insights;

  const {
    selected: selectedOrganization,
  } = state.organizations;

  return {
    chatList: list,
    organization: selectedOrganization,
    allChatsLoaded: isAllLoaded,
    selected,
    dateFilter,
    channelFilter,
  };
}
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ ...ChatsActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationsComponent);
