import * as React from "react";

export interface ChannelsProps {
  ["nav"]: React.ReactChild;
  ["content"]: React.ReactChild;
  ["notPadded"]?: boolean;
  ["alwaysOpen"]?: boolean;
}

export interface ChannelsState {
  ["isNavOpen"]?: boolean;
}
class NamaNavigationLayout extends React.Component<
  ChannelsProps,
  ChannelsState
  > {
  constructor(public props: ChannelsProps) {
    super(props);
    this.state = {
      isNavOpen: this.props.alwaysOpen
    };
  }
  public toggleNav = () => {
    const { alwaysOpen } = this.props;
    if (alwaysOpen) return;
    this.setState({
      isNavOpen: !this.state.isNavOpen
    });
  };
  public render() {
    const { alwaysOpen } = this.props;
    return (
      <div className="navigation-template">
        <div className={`nama-nav ${!this.state.isNavOpen ? "small" : ""}  ${!this.props.notPadded ? "padded" : ""}`}>
          {this.props.nav}
        </div>
        {
          !alwaysOpen &&
          <div
            className={`nama-nav-cursor ${!this.state.isNavOpen && "small"}`}
            onClick={this.toggleNav}
          />
        }
        <div className={`content ${!this.state.isNavOpen && "small"}`}>
          {this.props.content}
        </div>
      </div>
    );
  }
}

export default NamaNavigationLayout;
