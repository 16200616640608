import React, { Component } from "react";
import "./list.molecule.scss";
import { NamaButton, NamaIcon, NamaAvatar } from "@prakriti/prakriti";

interface NamaListProps {
  ["data"]: Array<NamaListItemModel>;
  ["actions"]: Array<NamaListActionsProps>;
  ["onClick"]: Function;
}

interface NamaListActionsProps {
  ["icon"]: string;
  ["name"]: string;
  ["onClick"]: any;
}

interface NamaListItemModel {
  ["id"]: number;
  ["title"]: string;
  ["url"]?: string;
  ["label"]?: string;
}

interface NamaListItemProps {
  ["item"]: NamaListItemModel;
  ["actions"]: Array<NamaListActionsProps>;
  ["onClick"]: Function;
}

class NamaList extends Component {
  constructor(public props: NamaListProps) {
    super(props);
  }

  render() {
    return (
      <div className="nama-list">
        {this.props.data.map((item: NamaListItemModel, i: number) => {
          return (
            <NamaListItem
              key={i}
              item={item}
              actions={this.props.actions}
              onClick={this.props.onClick}
            />
          );
        })}
      </div>
    );
  }
}

class NamaListAction extends Component {
  constructor(public props: NamaListActionsProps) {
    super(props);
  }

  render() {
    return (
      <NamaButton className="padded" onClick={this.props.onClick}>
        <NamaIcon icon={this.props.icon} />
        {this.props.name}
      </NamaButton>
    );
  }
}

class NamaListActions extends Component<NamaListItemProps> {
  public dispatchAction = (action: any, e: any) => {
    e.detail = this.props.item;
    e.stopPropagation();
    return action.onClick(e);
  };
  render() {
    return (
      <div className="actions">
        {this.props.actions.map((action: NamaListActionsProps, i: number) => {
          return (
            <NamaListAction
              key={i}
              icon={action.icon}
              onClick={(e: any) => {
                e.preventDefault();
                this.dispatchAction(action, e);
                return false;
              }}
              name={action.name}
            />
          );
        })}
      </div>
    );
  }
}

class NamaListItem extends Component<NamaListItemProps> {
  public onClickItem = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onClick(this.props.item);
    return false;
  };

  render() {
    return (
      <div className="nama-list-item" onClick={this.onClickItem}>
        <NamaAvatar
          size="small"
          url={this.props.item.url}
          name={this.props.item.title}
        />
        <div className="description">
          <h4>{this.props.item.title}</h4>
          <span>{this.props.item.label}</span>
        </div>
        <NamaListActions {...this.props} />
      </div>
    );
  }
}

export default NamaList;
