import { AssistantsService, AssistantModel } from "@purusha/purusha";

export let selectAssistant = (assistant: number, action?: string) => {
  return {
    type: "SELECT_ASSISTANT",
    payload: {
      assistant,
      action
    }
  };
};

export let selectAssistantByToken = (token: string, action?: string) => {
  return {
    type: "SELECT_ASSISTANT_BY_TOKEN",
    payload: {
      token,
      action
    }
  };
};

export let fetchAssistants = (organization_id: string) => {
  const request = AssistantsService.list(organization_id);
  return {
    type: "FETCH_ASSISTANTS",
    payload: request
  };
};

export let createAssistant = (
  organization_id: string,
  assistant: AssistantModel
) => {
  assistant = { ...assistant, conversable_avatar: assistant.avatar } as any;
  const request = AssistantsService.create(organization_id, assistant);
  return {
    type: "CREATE_ASSISTANT",
    payload: request
  };
};

export let updateAssistant = (
  organization_id: string,
  assistant: AssistantModel
) => {
  assistant = { ...assistant, conversable_avatar: assistant.avatar } as any;
  const request = AssistantsService.update(organization_id, assistant);
  return {
    type: "UPDATE_ASSISTANT",
    payload: request
  };
};

export let discardAssistant = (
  organization_id: string,
  assistant_id: string
) => {
  const request = AssistantsService.delete(organization_id, assistant_id);
  return {
    type: "DISCARD_ASSISTANT",
    payload: request
  };
};

export let fetchFlowVersions = (
  organization_id: string,
  assistant_token: string
) => {
  const request = AssistantsService.list_flow_versions(
    organization_id,
    assistant_token
  );
  return {
    type: "FETCH_FLOW_VERSIONS",
    payload: request
  };
};

export let addFlowVersion = (
  organization_id: string,
  assistant_token: string,
  data: any
) => {
  const request = AssistantsService.create_flow_version(
    organization_id,
    assistant_token,
    data
  );
  return {
    type: "ADD_FLOW_VERSION",
    payload: request
  };
};
