import * as React from "react";
import "./infinite-scroll.scss";

export interface InfiniteScrollComponentProps {
  ["load"]: any;
  ["className"]?: string;
  ["children"]: any;
  ["canLoadMore"]?: Boolean;
}

class InfiniteScrollComponent extends React.Component<
  InfiniteScrollComponentProps
  > {
  private container = React.createRef<HTMLDivElement>();
  constructor(
    public props: InfiniteScrollComponentProps
  ) {
    super(props);
  }

  public loadContent = async () => {
    const { load, canLoadMore } = this.props;
    const container = this.container.current;
    if (container && container.scrollTop === 0 && canLoadMore) {
      const before = container.scrollHeight;
      await load();
      const after = container.scrollHeight;
      container.scrollBy(0, after - before);
    }
  };

  public scrollToBegin = () => {
    const container = this.container.current;
    if (container) {
      container.scrollBy(0, container.scrollHeight);
    }
  }

  public componentDidMount() {
    const container = this.container.current;
    if (container) {
      container.addEventListener("scroll", this.loadContent)
    }
  }

  public componentWillUnmount() {
    const container = this.container.current;
    if (container) {
      container.removeEventListener("scroll", this.loadContent)
    }
  }

  public render() {
    const { className, children } = this.props;
    return (
      <div ref={this.container} className={`content infinite-scroll ${className || ''}`}>
        {children}
      </div>
    );
  }
}

export default InfiniteScrollComponent;