import { MemberModel } from "@purusha/purusha";

export const MemberInitialState: MemberModel = {
  desk_options: [],
  id: 0,
  organization_id: 0,
  role: "",
  access_token: "",
  email: "",
  name: "",
  avatar: {
    url: ""
  },
  created_at: ""
};
