import React from "react";
import { Formik, Form } from "formik";
import {
  OrganizationModel,
  AsyncErrorEffect,
  ModalFormCleanEffect
} from "@purusha/purusha";
import { NamaInput, NamaButton } from "@prakriti/prakriti";
import { Translate } from "ns-react-i18next";
import * as Yup from "yup";
export interface NamaMemberFormProps {
  ["onSubmit"]: any;
  ["onCancel"]: any;
  ["errors"]: any;
  ["isOpen"]: boolean;
  ["initial"]: OrganizationModel;
}

class NamaMemberFormApp extends React.Component {
  public formik: any;
  public memberSchema: any;
  constructor(public props: NamaMemberFormProps) {
    super(props);
    this.formik = React.createRef();
    this.memberSchema = Yup.object().shape({
      name: Yup.string()
        .min(2, "validation_short")
        .max(70, "validation_long")
        .required("validation_required"),
      role: Yup.string().required("validation_required"),
      email: Yup.string()
        .email("validation_email")
        .required("validation_required")
    });
  }

  public submit = (values: any) => {
    this.props.onSubmit(values);
  };

  public render() {
    return (
      <div>
        <h3>
          <Translate>member</Translate>
        </h3>
        <Formik
          ref={this.formik}
          initialValues={this.props.initial}
          validationSchema={this.memberSchema}
          enableReinitialize={true}
          onSubmit={this.submit}
          render={(props: any) => (
            <Form className="nama-form">
              <ModalFormCleanEffect
                isOpen={this.props.isOpen}
                formik={this.formik}
              />
              <AsyncErrorEffect
                formik={this.formik}
                errors={this.props.errors}
              />
              <NamaInput
                type="text"
                name="name"
                label="name"
              />
              <NamaInput
                type="text"
                disabled={this.props.initial.id !== 0}
                name="email"
                label="email"
              />
              <NamaInput type="select" name="role" label="role">
                <option> Select Role </option>
                <option value="admin">administrator</option>
                <option value="dev">developer</option>
                <option value="operator">operator</option>
              </NamaInput>
              <div className="actions">
                <button type="submit" className="nama-button-old primary">
                  {this.props.initial.id === 0 ? (
                    <Translate> create </Translate>
                  ) : (
                    <Translate> update </Translate>
                  )}
                </button>
                <NamaButton onClick={this.props.onCancel} className="clear">
                  <Translate> cancel </Translate>
                </NamaButton>
              </div>
            </Form>
          )}
        />
      </div>
    );
  }
}

export default NamaMemberFormApp;
