import * as React from "react";
import { NamaButton, NamaModal, NamaInput, NamaIcon } from "@prakriti/prakriti";
import { Formik, Form } from "formik";
import {
  DashboardModel,
  AssistantsActions,
  TeamsAction,
  ConversableModel,
  OrganizationModel,
  TeamsModel,
  AssistantsModel,
  TeamModel,
  AssistantModel,
  TeamsService
} from "@purusha/purusha";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import AssistantsService from "../../services/assistants.service";
import { AxiosResponse } from "axios";

export interface ConversableComponentProps {
  ["conversable"]?: ConversableModel;
  ["change"]: Function;
  ["name"]: string;
  // injected
  ["assistants"]: AssistantsModel;
  ["organization"]?: OrganizationModel;
  ["teams"]: TeamsModel;
  ["fetchTeams"]: any;
  ["fetchAssistants"]: any;
  ["fetchFlowVersions"]: any;
  ["selectAssistantByToken"]: any;
}
export interface ConversableComponentState {
  ["isModalOpen"]: boolean;
  ["conversable"]?: ConversableModel;
  ["showFlow"]: boolean;
  ["isSelected"]: boolean;
}

class ConversableComponent extends React.Component<
  ConversableComponentProps,
  ConversableComponentState
> {
  constructor(
    public props: ConversableComponentProps,
    public state: ConversableComponentState
  ) {
    super(props, state);
    this.state = {
      conversable: this.props.conversable,
      showFlow: false,
      isSelected: false,
      isModalOpen: false
    };

    if (
      this.props.conversable &&
      this.props.conversable.answerer_type == "FlowVersion"
    ) {
      this.changeBotAndFlow(this.props.conversable);
    }

    if (
      this.props.conversable &&
      this.props.conversable.answerer_type == "Team"
    ) {
      this.changeTeamConversable(this.props.conversable);
    }
  }

  public changeType = (e: any) => {
    if (this.props.organization) {
      switch (e.target.value) {
        case "Team":
          this.props.fetchTeams(this.props.organization.id);
        case "FlowVersion":
          this.props.fetchAssistants(this.props.organization.id);
      }
      this.setState({
        conversable: {
          answerer_type: e.target.value,
          answerer_id: undefined,
          assistant_token: undefined
        },
        isSelected: false,
        showFlow: false
      });
    }
  };

  public changeAssistant = (e: any) => {
    let assistant: AssistantModel = this.props.assistants.list.filter(
      (i: any) => i.token == e
    )[0];

    if (assistant && this.props.organization && this.state.conversable) {
      this.setState({
        showFlow: true,
        isSelected: false,
        conversable: {
          answerer_type: "FlowVersion",
          answerer_id: undefined,
          assistant_token: assistant.token
        }
      });
      this.props.selectAssistantByToken(e, "none");
      this.props.fetchFlowVersions(this.props.organization.id, e);
    } else {
      this.setState({
        showFlow: false,
        isSelected: false,
        conversable: {
          answerer_type: "FlowVersion",
          answerer_id: undefined,
          assistant_token: undefined
        }
      });
    }
  };

  public changeFlow = (e: any) => {
    if (
      this.props.assistants.selected &&
      this.props.assistants.selected.flow_versions
    ) {
      let flow = this.props.assistants.selected.flow_versions.filter(
        (i: any) => i.id == e
      )[0];
      if (flow && this.state.conversable) {
        this.setState({
          conversable: {
            ...this.state.conversable,
            answerer_id: flow.id.toString()
          },
          isSelected: true
        });
      } else {
        this.setState({
          conversable: {
            ...this.state.conversable,
            answerer_id: undefined
          },
          isSelected: false
        });
      }
    } else {
      this.setState({
        conversable: {
          ...this.state.conversable,
          answerer_id: undefined
        },
        isSelected: false
      });
    }
  };

  public changeBotAndFlow(conversable: ConversableModel) {
    this.changeType({
      target: {
        value: "FlowVersion"
      }
    });
    if (this.props.organization) {
      let organization_id = this.props.organization.id.toString();

      AssistantsService.list(organization_id).then(
        (payload_assistants: AxiosResponse<any[]>) => {
          let assistant = payload_assistants.data.filter(
            (i: any) => i.id == conversable.answerer.assistant_project_id
          )[0];
          this.changeAssistant(assistant.token);
          if (this.props.organization) {
            AssistantsService.list_flow_versions_by_id(
              this.props.organization.id.toString(),
              conversable.answerer.assistant_project_id
            ).then(payload => {
              let flow = payload.data.filter(
                (i: any) => i.id == conversable.answerer_id
              )[0];
              this.changeFlow(flow.id);
              this.setState({
                showFlow: true,
                isSelected: true,
                conversable: {
                  answerer_type: "FlowVersion",
                  assistant_token: assistant.token,
                  answerer_id: flow.id.toString()
                }
              });
              this.props.change(this.state.conversable);
            });
          }
        }
      );
    }
  }

  public changeTeamConversable(conversable: ConversableModel) {
    this.changeType({
      target: {
        value: "Team"
      }
    });
    this.state = {
      ...this.state,
      showFlow: true,
      isSelected: true,
      conversable: {
        ...this.state.conversable,
        answerer_type: "Team",
        assistant_token: undefined,
        answerer_id: conversable.answerer.id.toString()
      }
    };
    this.props.change(this.state.conversable);
  }

  public changeTeam = (e: any) => {
    let team = this.props.teams.list.filter(
      (i: any) => i.id == e.target.value
    )[0];
    if (team) {
      this.setState({
        conversable: {
          ...this.state.conversable,
          answerer_type: "Team",
          assistant_token: undefined,
          answerer_id: team.id.toString()
        },
        isSelected: true
      });
    } else {
      this.setState({
        conversable: {
          answerer_type: "Team",
          assistant_token: undefined,
          answerer_id: undefined
        },
        isSelected: false
      });
    }
  };

  public select = () => {
    this.closeModal();

    if (this.state.conversable) {
      this.props.change(this.state.conversable);
    }
  };

  public findAnswerer = (conversable: ConversableModel): any => {
    if (conversable.answerer_type == "Team") {
      let team = this.props.teams.list.filter(
        (i: any) => i.id == conversable.answerer_id
      )[0];
      return {
        team: team
      };
    } else {
      let assistant = this.props.assistants.list.filter(
        (i: any) => i.token == conversable.assistant_token
      )[0];
      if (
        this.props.assistants.selected &&
        this.props.assistants.selected.flow_versions
      ) {
        let flow = this.props.assistants.selected.flow_versions.filter(
          (i: any) => i.id == conversable.answerer_id
        )[0];
        return {
          flow: flow,
          assistant: assistant
        };
      }
    }
  };

  public conversableTag = (conversable?: ConversableModel): JSX.Element => {
    if (conversable) {
      let answerer: any = this.findAnswerer(conversable);
      if (answerer && answerer.flow) {
        return (
          <span>
            <NamaIcon icon="bot" />
            {answerer.flow.title} <br />
            <small>
              Flow created in{" "}
              {moment(answerer.flow.created_at).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}
            </small>
          </span>
        );
      } else if (answerer) {
        if (answerer.team) {
          return (
            <span>
              <NamaIcon icon="teams" /> {answerer.team.name}
            </span>
          );
        } else {
          return <span>--</span>;
        }
      } else {
        return <span>--</span>;
      }
    } else {
      return <span>--</span>;
    }
  };

  // modal
  public closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  public openModal = (e: any) => {
    this.setState({ isModalOpen: true });
  };

  public render() {
    return (
      <div className="conversable-selector-component">
        <p>Send the first attendance to</p>
        <div className="conversable">
          <h3> {this.conversableTag(this.state.conversable)} </h3>
          <NamaButton className="primary" onClick={this.openModal}>
            select
          </NamaButton>
        </div>

        <NamaModal
          isOpen={this.state.isModalOpen}
          onDiscardByUser={this.closeModal}
        >
          <Formik
            initialValues={{}}
            enableReinitialize={true}
            onSubmit={() => false}
            render={(props: any) => (
              <Form className="nama-form">
                <p>
                  Follow the next steps to add a choose who is the first
                  attendace of your channel
                </p>

                <NamaInput
                  type="text"
                  name="type"
                  value={
                    this.state.conversable &&
                    this.state.conversable.answerer_type
                  }
                  label="Send attendance to:"
                  onChange={this.changeType}
                >
                  <option value="not">Selecione</option>
                  <option value="FlowVersion">Assistant</option>
                  <option value="Team">Desk Team</option>
                </NamaInput>

                {this.state.conversable &&
                  this.state.conversable.answerer_type === "FlowVersion" && (
                    <NamaInput
                      type="text"
                      name="bots"
                      value={this.state.conversable.assistant_token}
                      onChange={(e: any) =>
                        this.changeAssistant(e.target.value)
                      }
                      label="Select which of your assistant you want to use"
                    >
                      <option>Selecione</option>
                      {this.props.assistants.list.map(
                        (assistant: AssistantModel, index: number) => {
                          return (
                            <option key={index} value={assistant.token}>
                              {assistant.name}
                            </option>
                          );
                        }
                      )}
                    </NamaInput>
                  )}

                {this.state.conversable &&
                  this.state.conversable.answerer_type === "FlowVersion" &&
                  this.state.showFlow &&
                  this.props.assistants.selected && (
                    <NamaInput
                      type="text"
                      name="flow"
                      value={this.state.conversable.answerer_id}
                      onChange={(e: any) => this.changeFlow(e.target.value)}
                      label="Select which of your assistant you want to use"
                    >
                      <option>Selecione</option>
                      {this.props.assistants.selected.flow_versions &&
                        this.props.assistants.selected.flow_versions.sort(sortById).map(
                          (flow: any, index: number) => {
                            return (
                              <option key={index} value={flow.id}>
                                {moment(flow.created_at).format(
                                  "DD/MM/YYYY  hh:mm:ss"
                                )}{" "}
                                - {flow.title}
                              </option>
                            );
                          }
                        )}
                      }
                    </NamaInput>
                  )}

                {this.state.conversable &&
                  this.state.conversable.answerer_type === "Team" && (
                    <NamaInput
                      type="text"
                      name="teams"
                      onChange={this.changeTeam}
                      value={this.state.conversable.answerer_id}
                      label="Select which of your desk teams you want to use"
                    >
                      <option>Selecione</option>
                      {this.props.teams.list.map(
                        (team: TeamModel, index: number) => {
                          return (
                            <option key={index} value={team.id}>
                              {team.name}
                            </option>
                          );
                        }
                      )}
                    </NamaInput>
                  )}

                <div className="actions">
                  <NamaButton
                    disabled={!this.state.isSelected}
                    onClick={this.select}
                    className="nama-button-old primary"
                  >
                    Select
                  </NamaButton>
                  <NamaButton className="clear" onClick={this.closeModal}>
                    Cancel
                  </NamaButton>
                </div>
              </Form>
            )}
          />
        </NamaModal>
      </div>
    );
  }
}

const sortById = (a: any, b: any) => {
  return a.id - b.id;
};

const mapStateToProps = (state: DashboardModel) => ({
  organization: state.organizations.selected,
  assistants: state.assistants,
  teams: state.teams
});
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...TeamsAction,
      ...AssistantsActions
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversableComponent);
