import React, { Component } from "react";
import "./tag-input.atom.scss";
import TagsInput from "react-tagsinput";
import { Translate } from "ns-react-i18next";

export interface NamaTagInputProps {
  ["placeholder"]?: string;
  ["label"]?: string;
  ["name"]: string;
  ["tags"]?: any;
  ["hint"]?: string;
  ["size"]?: string;
  ["className"]?: string;
  ["onlyUnique"]?: boolean;
  ["onChange"]?: Function;
  ["validateFunction"]?: (tags: Array<any>) => boolean;
  ["filterFunction"]?: (tags: Array<any>) => Array<any>;
}
export interface NamaTagInputState {
  ["tags"]: any;
}

class NamaTagInput extends Component<NamaTagInputProps, NamaTagInputState> {
  constructor(
    public props: NamaTagInputProps,
    public state: NamaTagInputState
  ) {
    super(props);
    this.state = { tags: this.props.tags || [] };
  }

  public componentDidUpdate = (next: any) => {
    if (this.props.tags != next.tags) {
      this.setState({
        tags: this.props.tags
      });
    }
  };

  public handleChange = (tags: Array<any>) => {
    if (
      !this.props.validateFunction ||
      (this.props.validateFunction && this.props.validateFunction(tags))
    ) {
      if (this.props.filterFunction) {
        tags = this.props.filterFunction(tags);
      }
      this.setState({ tags });
      if (this.props.onChange) {
        this.props.onChange({
          target: {
            name: this.props.name,
            value: tags
          }
        });
      }
    }
  };

  render() {
    return (
      <fieldset className={`${this.props.className} ${this.props.size}`}>
        {this.props.label && (
          <label>
            <Translate>{this.props.label}</Translate>
          </label>
        )}
        <TagsInput
          value={this.state.tags}
          inputProps={{
            placeholder: this.props.placeholder
          }}
          onChange={this.handleChange}
          onlyUnique={this.props.onlyUnique}
        />
        {this.props.hint && <div className="nama-hint">{this.props.hint}</div>}
      </fieldset>
    );
  }
}

export default NamaTagInput;
