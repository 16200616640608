import { ChatModel, ChatsService } from "@purusha/purusha";
import { IDateRange, DropdownItem } from "nama-design";

export const fetchChatHistory = (organizationId: Number, options?: { chatId?: Number, dateFilter?: IDateRange, channelFilter?: Array<DropdownItem> }) => {
  return async (dispatch: any) => {
    const { data } = await ChatsService.list_history(organizationId, options);
    dispatch({
      type: "FETCH_CHAT_HISTORY",
      payload: {
        data,
        reset: !Boolean(options && options.chatId)
      },
    });
  };
};

export const selectChat = (chat: ChatModel) => {
  return {
    type: "SELECT_CHAT",
    payload: { chat },
  }
}

export const loadChatMessages = (organizationId: Number, chatId: Number, messageId?: Number) => {
  return async (dispatch: any) => {
    const { data } = await ChatsService.list_chat_messages(
      chatId,
      organizationId,
      messageId
    );

    dispatch({
      type: "LOAD_CHAT_MESSAGES",
      payload: { messages: data, chatId }
    });
  }
}

export const clearChats = () => {
  return {
    type: "CLEAR_CHATS"
  };
};
