import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import { host_url } from "@purusha/config";
var obj2fd = require("obj2fd").default;

export default class AssistantsService {
  public static base_url: string = `${host_url}`;

  public static list(organization_id: string): AxiosPromise<Array<any>> {
    const url: string = `${this.base_url}/api/v1/organizations/${organization_id}/assistant_projects`;
    const config = {
      headers: { authorization: "Token token=b43ahgFEBoAwyFeoV4KqTfBX" }
    };
    return axios.get(url, config);
  }

  public static create(
    organization_id: string,
    assistant: any
  ): AxiosPromise<any> {
    let url: string = `${this.base_url}/api/v1/organizations/${organization_id}/assistant_projects`;
    const config = {
      headers: { authorization: "Token token=b43ahgFEBoAwyFeoV4KqTfBX" }
    };
    return axios.post(
      url,
      {
        assistant_project: {
          description: assistant.description,
          title: assistant.name,
          avatar: assistant.conversable_avatar
        }
      },
      config
    );
  }

  public static update(organization_id: string, assistant: any): AxiosPromise {
    const url: string = `${this.base_url}/api/v1/organizations/${organization_id}/assistant_projects/${
      assistant.id
      }`;
    assistant.title = assistant.name;
    assistant.name = undefined;
    const config = {
      headers: { authorization: "Token token=b43ahgFEBoAwyFeoV4KqTfBX" }
    };
    return axios.put(url, { assistant_project: assistant }, config);
  }

  public static delete(
    organization_id: string,
    assistant_id: string
  ): AxiosPromise {
    const url: string = `${this.base_url}/api/v1/organizations/${organization_id}/assistant_projects/${assistant_id}`;
    const config = {
      headers: { authorization: "Token token=b43ahgFEBoAwyFeoV4KqTfBX" }
    };
    return axios.delete(url, config);
  }

  public static list_flow_versions(
    organization_id: string,
    assistant_token: string
  ): AxiosPromise {
    const url: string = `${this.base_url}/api/v1/organizations/${organization_id}/assistant_projects/${assistant_token}/flow_versions`;
    const config = {
      headers: { authorization: "Token token=b43ahgFEBoAwyFeoV4KqTfBX" }
    };
    return axios.get(url, config);
  }

  public static list_flow_versions_by_id(
    organization_id: string,
    assistant_id: string
  ): AxiosPromise {
    const url: string = `${this.base_url}/api/v1/organizations/${organization_id}/assistant_project/${assistant_id}/flow_versions`;
    const config = {
      headers: { authorization: "Token token=b43ahgFEBoAwyFeoV4KqTfBX" }
    };
    return axios.get(url, config);
  }

  public static create_flow_version(
    organization_id: string,
    assistant_token: string,
    data: any
  ): AxiosPromise {
    const url: string = `${this.base_url}/api/v1/organizations/${organization_id}/assistant_projects/${assistant_token}/flow_versions`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: "Token token=b43ahgFEBoAwyFeoV4KqTfBX"
      }
    };
    return axios.post(url, obj2fd({ flow_version: data }), config);
  }
}
