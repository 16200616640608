import { InsightsModel } from "@purusha/purusha";
import { subDays, startOfToday, endOfToday } from 'date-fns';
import { IDateRange } from "nama-design";

const savedDateFilter = localStorage.getItem('date-filter');
const savedChannelFilter = localStorage.getItem('channel-filter');

let inicialDateFilter: IDateRange = { from: subDays(startOfToday(), 30), to: endOfToday() };
if (savedDateFilter) {
  const savedDateFilterJSON = JSON.parse(savedDateFilter);
  inicialDateFilter = { from: new Date(savedDateFilterJSON.from), to: new Date(savedDateFilterJSON.to) };
}


export const initialStatsState: InsightsModel = {
  chatStats: undefined,
  dateFilter: inicialDateFilter,
  channelList: [],
  channelFilter: savedChannelFilter ? JSON.parse(savedChannelFilter) : [],
};

export function InsightsReducer(
  state: InsightsModel = initialStatsState,
  action: any
) {
  switch (action.type) {
    case "FETCH_CHAT_STATS":
      return { ...state, chatStats: action.payload.data };
    case "SET_DATE_FILTER":
      const dateFilter = action.dateFilter;
      localStorage.setItem('date-filter', JSON.stringify(dateFilter));
      return { ...state, dateFilter };
    case "FETCH_ALL_CHANNELS":
      return { ...state, channelList: action.payload.data };
    case "SET_CHANNEL_FILTER":
      const channelFilter = action.channelFilter;
      localStorage.setItem('channel-filter', JSON.stringify(channelFilter));
      return { ...state, channelFilter };
    case "RESET_STATS":
      return initialStatsState;
  }
  return state;
}
