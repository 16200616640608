import * as React from "react";
import { NamaAccordeon, NamaList } from "@prakriti/prakriti";

export interface FacebookPagesComponentProps {
  ["pages"]?: Array<any>;
  ["select"]: Function;
}
export interface FacebookPagesComponentState {}

class FacebookPagesComponent extends React.Component<
  FacebookPagesComponentProps,
  FacebookPagesComponentState
> {
  public actions: Array<any>;

  constructor(public props: FacebookPagesComponentProps) {
    super(props);
    this.actions = [
      {
        name: "publish",
        icon: "publish",
        onClick: (e: any) => {
          if (this.props.pages) {
            let selected_page = this.props.pages.filter(
              (page: any) => page.page.id == e.detail.id
            );
            this.props.select(selected_page[0]);
          }
        }
      }
    ];
  }

  public parse = (pages?: Array<any>) => {
    return (
      pages &&
      pages.map(page => {
        return {
          id: page.page.id,
          title: page.page.name,
          label: "label",
          url: page.picture.url
        };
      })
    );
  };

  public render() {
    return (
      <div>
        {(typeof this.props.pages == "undefined" ||
          this.props.pages.length == 0) && <div> Loading... </div>}

        {this.props.pages && (
          <NamaList
            onClick={() => {}}
            data={this.parse(this.props.pages) || []}
            actions={this.actions}
          />
        )}
      </div>
    );
  }
}

export default FacebookPagesComponent;
