import * as React from "react";
import './conversations-layout.scss';

export interface ConversationsLayoutProps {
  ["children"]?: any;
}

class ConversationsLayout extends React.Component<
  ConversationsLayoutProps
  > {
  constructor(public props: ConversationsLayoutProps) {
    super(props);
  }

  public render() {
    const { children } = this.props;
    return (
      <div className="conversations-layout">
        {
          children
        }
      </div>
    );
  }
}

export default ConversationsLayout;
