import * as React from "react";
import * as Yup from "yup";
import { AsyncErrorEffect } from "@purusha/purusha";
import { NamaButton, NamaInput, NamaDropFile } from "@prakriti/prakriti";
import { Formik, Form } from "formik";

export interface FlowVersionFormProps {
  ["submit"]: Function;
  ["dismiss"]: Function;
  ["errors"]?: any;
}

export interface FlowVersionFormState {
  ["zip_file"]?: File;
}

class FlowVersionForm extends React.Component<
  FlowVersionFormProps,
  FlowVersionFormState
  > {
  public formik: any;

  constructor(
    public props: FlowVersionFormProps,
    public state: FlowVersionFormState
  ) {
    super(props, state);
  }

  public submit = (e: any) => {
    e.zip_file = this.state.zip_file;
    this.props.submit(e);
  };

  public setFile = (zip_file: File) => {
    this.setState({
      zip_file: zip_file
    });
  };

  public render() {
    this.formik = React.createRef();
    let memberAssistantSchema = Yup.object().shape({
      title: Yup.string().required("validation_required"),
      // files_url: Yup.string().required("validation_required"),
      comment: Yup.string(),
      google_analytics_id: Yup.string(),
      chatbase_api_key: Yup.string(),
      webhook_url: Yup.string(),
      sistemaai_token: Yup.string(),
      deeplearning_minimum_confidence: Yup.number()
    });
    return (
      <Formik
        ref={this.formik}
        validationSchema={memberAssistantSchema}
        initialValues={{
          title: "",
          comment: "",
          google_analytics_id: "",
          chatbase_api_key: "",
          webhook_url: "",
          sistemaai_token: "",
          deeplearning_minimum_confidence: 0.3
        }}
        enableReinitialize={true}
        onSubmit={this.submit}
        render={(props: any) => (
          <Form className="nama-form">
            <AsyncErrorEffect formik={this.formik} errors={this.props.errors} />

            <div style={{ width: "50%", float: "left" }}>
              <NamaInput type="text" name="title" label="Title" />
              <NamaInput type="textarea" name="comment" label="Comment" />
              <NamaDropFile
                accept="application/zip,application/x-zip,application/x-zip-compressed"
                setFile={this.setFile}
              />
            </div>
            <div style={{ width: "50%", float: "left" }}>
              <NamaInput
                type="text"
                name="google_analytics_id"
                label="Google analytics"
              />
              <NamaInput
                type="text"
                name="chatbase_api_key"
                label="Chatbase api key"
              />
              <NamaInput type="text" name="webhook_url" label="Webhook url" />
              <NamaInput
                type="text"
                name="sistemaai_token"
                label="Sistema.ai App Token"
              />
              <NamaInput
                type="number"
                step="1"
                name="deeplearning_minimum_confidence"
                label="Confidence Level"
              />
            </div>

            <div className="actions" style={{ width: "100%", float: "left" }}>
              <button type="submit" className="nama-button-old primary">
                Upload
              </button>
              <NamaButton className="clear" onClick={this.props.dismiss}>
                Cancel
              </NamaButton>
            </div>
          </Form>
        )}
      />
    );
  }
}

export default FlowVersionForm;
