import React, { Component } from "react";
import "./avatar.atom.scss";
import NamaIcon from "../icon/icon.atom";

interface NamaAvatarProps {
  ["url"]?: string;
  ["name"]: string;
  ["size"]?: string;
}

class NamaAvatar extends Component {
  constructor(public props: NamaAvatarProps) {
    super(props);
  }

  public getInitials(name: any) {
    const nameSplit = name ? name.split(" ") : [];
    if (nameSplit.length >= 2) {
      return (
        nameSplit[0].charAt(0).toUpperCase() +
        nameSplit[1].charAt(0).toUpperCase()
      );
    } else if (nameSplit.length === 1) {
      return nameSplit[0].charAt(0).toUpperCase();
    } else {
      return <NamaIcon icon="profile" className="fill-grey-700" />
    }
  }

  public hasImage(): boolean {
    return typeof this.props.url === "string"
      ? this.props.url.trim() !== ""
      : false;
  }

  public fill() {
    if (this.hasImage()) {
      return <img src={this.props.url} />;
    } else {
      return (
        <div className="initials">{this.getInitials(this.props.name)}</div>
      );
    }
  }

  render() {
    return (
      <div className={`nama-avatar ${this.props.size || "default"}`}>
        {this.fill()}
      </div>
    );
  }
}

export default NamaAvatar;
