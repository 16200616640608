import * as React from "react";
import * as Yup from "yup";

import { NamaButton, NamaInput } from "@prakriti/prakriti";
import { Translate } from "ns-react-i18next";
import { Formik, Form, ErrorMessage } from "formik";
import ConversableComponent from "../conversable.component";
import { ConversableModel } from "@purusha/purusha";
import {
  ChannelModel,
  WidgetOptions,
  WidgetOptionsInitialState
} from "@purusha/purusha";
import WidgetWrapperComponent from "./widget.wrapper";
import WidgetOptionsComponent from "./widget.options";

export interface WebChannelComponentProps {
  ["channel"]?: ChannelModel;
  ["cancel"]: Function;
  ["submit"]: Function;
}
export interface WebChannelComponentState {
  ["conversable"]?: ConversableModel;
  ["widgetOptions"]: WidgetOptions;
  ["renderWidgetOptions"]?: WidgetOptions;
}

class WebChannelComponent extends React.Component<
  WebChannelComponentProps,
  WebChannelComponentState
> {
  public formik: any;
  public initial: any;
  constructor(public props: WebChannelComponentProps) {
    super(props);
    this.formik = React.createRef();

    this.state = {
      widgetOptions: WidgetOptionsInitialState
    };

    if (this.props.channel && this.props.channel.conversable) {
      this.state = {
        ...this.state,
        conversable: this.props.channel.conversable
      };
    }
    // add widget option with loaded
    if (this.props.channel && this.props.channel.config) {
      let config = this.props.channel.config
      if(typeof config == "string") {
        config = JSON.parse(this.props.channel.config)
      }
      
      let widget_options = {
        ...config,
        title: this.props.channel.title,
        apiToken: this.props.channel.token,
        serverUrl: WidgetOptionsInitialState.serverUrl
      };
      this.state = {
        ...this.state,
        widgetOptions: widget_options,
        renderWidgetOptions: widget_options
      };
    }
  }

  public componentWillUpdate = (next: WebChannelComponentProps) => {
    if (
      this.props.channel != next.channel &&
      next.channel &&
      next.channel.config
    ) {
      let config = next.channel.config
      if(typeof config == "string") {
        config = JSON.parse(next.channel.config)
      }
 
      this.setState({
        widgetOptions: config,
        renderWidgetOptions: config
      });
    }
  };

  public changeWidgetOptions = (options: WidgetOptions) => {
    let widget_options = {
      ...options,
      title: this.props.channel ? this.props.channel.title : options.title,
      apiToken: this.props.channel
        ? this.props.channel.token
        : options.apiToken,
      serverUrl: WidgetOptionsInitialState.serverUrl
    };
    this.setState({
      widgetOptions: widget_options
    });
  };

  public conversableToChannel = (
    title: string,
    options: any,
    conversable: ConversableModel
  ): ChannelModel | undefined => {
    if (conversable && conversable.answerer_type) {
      return {
        id: this.props.channel ? this.props.channel.id : undefined,
        title: title,
        conversable: conversable,
        config: JSON.stringify(options)
      };
    }
  };

  public submit = () => {
    if (this.formik.current) {
      if (this.state.conversable && this.state.widgetOptions) {
        let d = this.formik.current.state.values;
        d.conversable = this.state.conversable;
        d.widgetOptions = this.state.widgetOptions;
        this.setState({
          renderWidgetOptions: this.state.widgetOptions
        });
        this.props.submit(
          this.conversableToChannel(
            d.title,
            this.state.widgetOptions,
            this.state.conversable
          )
        );
      }
    }
  };

  public changeConversable = (conversable: ConversableModel) => {
    this.setState({
      conversable: conversable
    });
  };

  public render() {
    let WebChannelSchema = Yup.object().shape({
      title: Yup.string().required("validation_required"),
      conversable: Yup.object().required("validation_required"),
      first_message: Yup.string(),
      placehold: Yup.string(),
      behavior: Yup.string(),
      size: Yup.string(),
      color: Yup.string(),
      disclaimer: Yup.string()
    });

    return (
      <div>
        <p>Fill the form below to set up your widget.</p>
        <p>
          You can check how it will look like by interacting with the widget on
          this page.
        </p>

        <ConversableComponent
          conversable={this.state.conversable}
          change={this.changeConversable}
          name="conversable"
        />

        <WidgetWrapperComponent
          options={this.state.renderWidgetOptions || WidgetOptionsInitialState}
        />

        <Formik
          ref={this.formik}
          validationSchema={WebChannelSchema}
          initialValues={this.props.channel}
          enableReinitialize={true}
          onSubmit={this.submit}
          render={(props: any) => (
            <Form className="nama-form">
              <NamaInput label="Channel`s Title" name="title" type="text" />

              <ErrorMessage
                name="conversable"
                render={msg => (
                  <div className="field-error-message">
                    <Translate>{msg}</Translate>
                  </div>
                )}
              />
            </Form>
          )}
        />
        <WidgetOptionsComponent
          options={this.state.widgetOptions}
          onChange={this.changeWidgetOptions}
        />
        <Form className="nama-form">
          <div className="actions" style={{ width: "100%", float: "left" }}>
            <NamaButton
              className="nama-button-old primary"
              onClick={() => {
                this.submit();
              }}
            >
              {this.props.channel ? "Update" : "Save"}
            </NamaButton>
            <NamaButton className="clear" onClick={this.props.cancel}>
              Cancel
            </NamaButton>
          </div>
        </Form>
      </div>
    );
  }
}

export default WebChannelComponent;
