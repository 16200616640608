import React from "react";
import * as Yup from "yup";
import { host_url } from "@purusha/config";

import { NamaButton, NamaInput, NamaIcon } from "@prakriti/prakriti";
import { Translate } from "ns-react-i18next";
import { Formik, Form, ErrorMessage } from "formik";
import ConversableComponent from "../conversable.component";
import { ConversableModel } from "@purusha/purusha";

import { ChannelModel } from "@purusha/purusha";

export interface SlackChannelComponentProps {
  ["channel"]?: ChannelModel;
  ["cancel"]: Function;
  ["submit"]: Function;
}

export interface SlackChannelComponentState {
  ["conversable"]?: ConversableModel;  
}

class SlackChannelComponent extends React.Component<
  SlackChannelComponentProps,
  SlackChannelComponentState
  > {
    public formik: any;
    public initial: any;
    public state: SlackChannelComponentState;
    constructor(public props: SlackChannelComponentProps) {
      super(props);
      this.formik = React.createRef();

      this.state = {};
      if (this.props.channel && this.props.channel.conversable) {
        this.state = {
          ...this.state,
          conversable: this.props.channel.conversable
        };
      }
    }

    public parseInitialChannel(channel?: ChannelModel) {
      const config = channel && channel.config ? channel.config: {}
      return {
        ...channel
      }
    }

    public componentWillUpdate = (next: SlackChannelComponentProps) => {
      if (
        this.props.channel != next.channel &&
        next.channel &&
        next.channel.config
      ) {
      }
    };

    public submit = () => {
      if (this.formik.current) {
        if (this.state.conversable) {
          const formValues = this.formik.current.state.values;
          const channel: ChannelModel = {
            ...this.props.channel,
            title: formValues.title,
            conversable: this.state.conversable       
          }
          this.props.submit(channel, "slack")
        }
      }
    };

    public changeConversable = (conversable: ConversableModel) => {
      this.setState({
        conversable: conversable
      });
    }

    public render() {
      let SlackChannelSchema = Yup.object().shape({
        title: Yup.string().required("validation_required"),
        slack_token: Yup.string().required("validation_required")
      });

      return (        
        <div>
          <p>Fill the form below to set up your widget.</p>
          <p>
            You can check how it will look like by interacting with the widget on
            this page.
          </p>
          <p>
            The authorization token and the application token must be setup in the
            adapters
          </p>
          <ConversableComponent
            conversable={this.state.conversable}
            change={this.changeConversable}
            name="conversable"
          />

          <Formik
            ref={this.formik}
            validationSchema={SlackChannelSchema}
            initialValues={this.parseInitialChannel(this.props.channel)}
            enableReinitialize={true}
            onSubmit={this.submit}
            render={(props: any) => (
              <Form className="nama-form">
                <NamaInput label="Channel's Title" name="title" type="text" />
                <ErrorMessage
                  name="conversable"
                  render={msg => (
                    <div className="field-error-message">
                      <Translate>{msg}</Translate>
                    </div>
                  )}
                />
              </Form>
            )}
          />
          <Form className="nama-form">
            <div className="actions" style={{ width: "100%", float: "left" }}>
              <NamaButton
                className="nama-button-old primary"
                onClick={() => {
                  this.submit();
                }}>
                  {this.props.channel ? "Update" : "Save"}
              </NamaButton>
              <NamaButton className="clear" onClick={this.props.cancel}>
                Cancel
              </NamaButton>
            </div>
          </Form>
        </div>
      );
    }
}

export default SlackChannelComponent;