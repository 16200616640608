import React from "react";
import { Formik, Form } from "formik";
import { TeamModel, AsyncErrorEffect } from "@purusha/purusha";
import { NamaInput, NamaButton, NamaUploadAvatar } from "@prakriti/prakriti";
import { Translate } from "ns-react-i18next";
import * as Yup from "yup";
import { Link } from "react-router-dom";

export interface NamaTeamFormProps {
  ["onSubmit"]: any;
  ["onCancel"]: any;
  ["errors"]: any;
  ["initial"]: TeamModel;
}

class NamaTeamFormApp extends React.Component {
  public formik: any;
  public teamSchema: any;
  constructor(public props: NamaTeamFormProps) {
    super(props);
    this.formik = React.createRef();
    this.teamSchema = Yup.object().shape({
      name: Yup.string()
        .min(2, "validation_short")
        .max(70, "validation_long")
        .required("validation_required"),
      description: Yup.string().required("validation_required")
    });
  }

  public submit = (values: any) => {
    this.props.onSubmit(values);
  };

  public render() {
    return (
      <div>
        <Formik
          ref={this.formik}
          initialValues={this.props.initial}
          validationSchema={this.teamSchema}
          enableReinitialize={true}
          onSubmit={this.submit}
          render={(props: any) => (
            <Form className="nama-form">
              <AsyncErrorEffect
                formik={this.formik}
                errors={this.props.errors}
              />
              <NamaUploadAvatar
                name="avatar"
                label="avatar"
                title={this.props.initial.name}
                url={this.props.initial.avatar}
                formik={this.formik}
              />
              <NamaInput type="text" name="name" label="name" />
              <NamaInput
                type="textarea"
                name="description"
                label="description"
              />
              <div className="actions">
                <button type="submit" className="nama-button-old primary">
                  {this.props.initial.id === 0 ? (
                    <Translate> create </Translate>
                  ) : (
                    <Translate> update </Translate>
                  )}
                </button>
                <NamaButton
                  onClick={this.props.onCancel}
                  className="nama-button-old clear"
                >
                  <Translate> cancel </Translate>
                </NamaButton>
              </div>
            </Form>
          )}
        />
      </div>
    );
  }
}

export default NamaTeamFormApp;
