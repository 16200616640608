import * as React from "react";
import "./modal.organism.scss";
import { Transition, animated, interpolate, config } from "react-spring";
import ReactDOM from "react-dom";

export interface NamaModalProps {
  ["isOpen"]: boolean;
  ["children"]?: any;
  ["size"]?: string;
  ["onDiscardByUser"]?: Function;
}

export interface NamaModalState {}

class NamaModal extends React.Component {
  constructor(public props: NamaModalProps, public state: NamaModalState) {
    super(props);
  }

  public close = () => {
    if (this.props.onDiscardByUser) {
      this.props.onDiscardByUser();
    }
  };

  private transitionProps = {
    from: {
      opacity: 0,
      visible: false,
      y_percentage: -20
    },
    enter: {
      opacity: 1,
      visible: true,
      y_percentage: -50
    },
    leave: {
      opacity: 0,
      visible: false,
      y_percentage: -20
    },
    config: config.stiff
  };

  public render() {
    return (
      <BodyEnd>
        <Transition native items={this.props.isOpen} {...this.transitionProps}>
          {toggle =>
            toggle &&
            (({ opacity, visible, y_percentage }) => (
              <animated.div
                className="nama-modal-courtin"
                style={{
                  opacity,
                  visibility: visible ? "visible" : "hidden"
                }}
              >
                <div className="out-click-handle" onClick={this.close} />
                <animated.div
                  className={`nama-modal ${this.props.size}`}
                  style={{
                    transform: interpolate(
                      [y_percentage],
                      y => `translate3d(0px, ${y}%, 0px)`
                    )
                  }}
                >
                  {this.props.children}
                </animated.div>
              </animated.div>
            ))
          }
        </Transition>
      </BodyEnd>
    );
  }
}

export class BodyEnd extends React.Component {
  public el: HTMLElement;
  constructor(props: any) {
    super(props);
    this.el = document.createElement("div");
    this.el.style.display = "contents";
  }

  componentDidMount() {
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default NamaModal;
