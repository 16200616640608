import React, { Component, ReactChild } from "react";
import { Icons } from "./icon.manifest.atom";
import "./icon.atom.scss";

interface NamaIconProps {
  ["icon"]: string;
  ["svg"]?: ReactChild;
  ["className"]?: string;
}

class NamaIcon extends Component {
  constructor(public props: NamaIconProps) {
    super(props);
  }

  render() {
    return (
      <div className={`nama-icon ${this.props.className}`}>
        {this.props.svg ? this.props.svg : Icons(this.props.icon)}
      </div>
    );
  }
}

export default NamaIcon;
