import React, { Component } from "react";
import "./upload-avatar.atom.scss";
import { Translate } from "ns-react-i18next";
import { NamaAvatar } from "@prakriti/prakriti";
import { Field } from "formik";

export interface NamaUploadAvatarProps {
  ["name"]: string;
  ["title"]: string;
  ["url"]?: string;
  ["label"]: string;
  ["formik"]: any;
}

export interface NamaUploadAvatarState {
  ["url"]: string;
}

class NamaUploadAvatar extends Component {
  public inputFileRef: React.RefObject<HTMLInputElement>;
  constructor(
    public props: NamaUploadAvatarProps,
    public state: NamaUploadAvatarState
  ) {
    super(props);
    this.inputFileRef = React.createRef();
    this.state = {
      url: this.props.url || ""
    };
  }

  private onInputChange = (e: any) => {
    const input = e.target;
    if (input && input.files) {
      let files = input.files;
      if (!files.length || !(window as any).FileReader) return;
      if (/^image/.test(files[0].type)) {
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onloadend = (data: any) => {
          this.props.formik.current.setFieldValue(
            this.props.name,
            data.currentTarget.result || "",
            false
          );
          this.setState({ url: data.currentTarget.result || "" });
        };
      }
    }
  };

  private onEditClick = () => {
    if (this.inputFileRef && this.inputFileRef.current) {
      this.inputFileRef.current.click();
    }
  };

  public componentWillReceiveProps = (nextProps: any) => {
    if (nextProps.url !== this.props.url) {
      this.setState({ url: nextProps.url || "" });
    }
  };

  render() {
    return (
      <fieldset className="nama-upload-avatar">
        <label>
          <Translate>{this.props.label}</Translate>
        </label>

        <div className="nama-upload-avatar-wrapper">
          <NamaAvatar size="big" name={this.props.title} url={this.state.url} />
          <a onClick={this.onEditClick}>Edit</a>
        </div>

        <input
          type="file"
          ref={this.inputFileRef}
          onChange={this.onInputChange}
        />

        {/* <Field name={this.props.name} /> */}
      </fieldset>
    );
  }
}

export default NamaUploadAvatar;
