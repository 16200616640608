import { TeamModel, TeamsService } from "@purusha/purusha";
import { discardMember } from "./members.actions";

export let fetchTeams = (organization_id: string) => {
  const request = TeamsService.teams(organization_id);
  return {
    type: "FETCH_TEAMS",
    payload: request
  };
};

export let fetchTeamMembers = (organization_id: string, team_id: string) => {
  const request = TeamsService.members_team(organization_id, team_id);
  return {
    type: "FETCH_TEAM_MEMBERS",
    payload: request
  };
};

export let discardTeamMember = (
  organization_id: string,
  team_id: string,
  user_id: string
) => {
  const request = TeamsService.discard_team_member(
    organization_id,
    team_id,
    user_id
  );
  return {
    type: "DISCARD_TEAM_MEMBERS",
    payload: request
  };
};

export let addTeamMember = (
  organization_id: string,
  team_id: string,
  user_id: string,
  max_calls: number
) => {
  const request = TeamsService.add_member_in_team(
    organization_id,
    team_id,
    user_id,
    max_calls
  );
  return {
    type: "ADD_TEAM_MEMBER",
    payload: request
  };
};

export let updateTeamMember = (
  organization_id: string,
  team_id: string,
  user_id: string,
  max_calls: number
) => {
  const request = TeamsService.update_member_in_team(
    organization_id,
    team_id,
    user_id,
    max_calls
  );
  return {
    type: "UPDATE_TEAM_MEMBER",
    payload: request
  };
};

export let openTeamModal = (organization_id: string) => {
  return {
    type: "OPEN_TEAM_MODAL",
    payload: organization_id
  };
};

export let closeTeamModal = () => {
  return {
    type: "CLOSE_TEAM_MODAL"
  };
};

export let createTeam = (organization_id: string, team: TeamModel) => {
  team = { ...team, conversable_avatar: team.avatar } as any;
  const request = TeamsService.create(organization_id, team);
  return {
    type: "CREATE_TEAM",
    payload: request
  };
};

export let selectTeam = (team: number, action?: string) => {
  return {
    type: "SELECT_TEAM",
    payload: {
      team,
      action
    }
  };
};

export let updateTeam = (organization_id: string, team: TeamModel) => {
  team = { ...team, conversable_avatar: team.avatar } as any;
  const request = TeamsService.update(organization_id, team);
  return {
    type: "UPDATE_TEAM",
    payload: request
  };
};

export let discardTeam = (organization_id: string, team_id: string) => {
  const request = TeamsService.discard(organization_id, team_id);
  return {
    type: "DISCARD_TEAM",
    payload: request
  };
};
