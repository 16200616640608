import axios, { AxiosPromise } from "axios";
import { host_url } from "@purusha/config";
import { IDateRange, DropdownItem } from "nama-design";

export default class ChatService {
  public static base_url: string = `${host_url}/api/v1`;
  // /api/v:api_version/chats
  public static list_history(
    organizationId: Number,
    options?: {
      chatId?: Number,
      dateFilter?: IDateRange,
      channelFilter?: Array<DropdownItem>,
    }
  ): AxiosPromise<any> {
    const url: string = `${
      this.base_url
      }/organizations/${organizationId}/chats?${
      options && options.chatId ? `older_than=${options.chatId}&` : ""
      }${
      options && options.dateFilter ? `by_date_begin=${(new Date(options.dateFilter.from)).toISOString()}&by_date_end=${(new Date(options.dateFilter.to)).toISOString()}&` : ''
      }${
      options && options.channelFilter ? `channel_tokens=${options.channelFilter.map(channel => channel.value).join(',')}` : ''
      }`;
    return axios.get(url);
  }

  public static list_chat_messages(
    chatId: Number,
    organizationId: Number,
    messageId?: Number,
  ): AxiosPromise<any> {
    const url: string = `${
      this.base_url
      }/organizations/${organizationId}/chats/${chatId}/messages${
      messageId ? `?older_than=${messageId}` : ""
      }`;
    return axios.get(url);
  }
}
