import React, { Component } from "react";
import "./conversable.molecule.scss";
import { NamaAvatar } from "@prakriti/prakriti";
import NamaIcon from "@prakriti/atoms/icon/icon.atom";

interface NamaConversableProps {
  ["name"]: string;
  ["description"]?: string;
  ["url"]?: string;
  ["token"]?: string;
}

class NamaConversable extends Component<NamaConversableProps> {
  render() {
    return (
      <div className="nama-conversable">
        <div className="conversable-description">
          <NamaAvatar name={this.props.name} url={this.props.url} />
          <h1>{this.props.name}</h1>
          <p>{this.props.description}</p>
          <div className="nama-copy-value">
            <NamaIcon icon="copy" />
            TOKEN: {this.props.token}
          </div>
        </div>
        <div className="nama-button-old dropdown">
          <NamaIcon icon="settings" />
          <div className="subactions">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default NamaConversable;
