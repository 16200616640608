import { AssistantModel } from "@purusha/purusha";

export const AssistantInitialState: AssistantModel = {
  id: 0,
  organization_id: 0,
  flow_versions: [],
  token: "",
  name: "",
  description: "",
  avatar: ""
};
