import { UserModel, UserInitialState } from "@purusha/purusha";

export function UserReducer(state: UserModel = UserInitialState, action: any) {
  switch (action.type) {
    case "FETCH_CURRENT_USER":
      if (action.payload.data) {
        let user = action.payload.data;
        return {
          id: user.id,
          access_token: user.access_token,
          avatar: {
            url: user.avatar.url
          },
          email: user.email,
          name: user.name || ""
        };
      } else {
        return state;
      }

    case "UPDATE_CURRENT_USER":
      if (action.payload.data) {
        let user = action.payload.data;
        return {
          id: user.id,
          access_token: user.access_token,
          avatar: {
            url: user.avatar.url
          },
          email: user.email,
          name: user.name
        };
      } else {
        return state;
      }
    case "SINGOUT":
      if (action.payload) {
        // (document as any).location.href = (document as any).location.origin;
      }

    default:
      return state;
  }
}
