import {
  MemberInitialState,
  MembersInitialState,
  MembersModel,
  MemberModel
} from "@purusha/purusha";

export function MembersReducer(
  state: MembersModel = MembersInitialState,
  action: any
) {
  switch (action.type) {
    case "FETCH_MEMBERS":
      if (action.payload.data) {
        let list = action.payload.data.map((item: any) => {
          return {
            ...item.user,
            role: item.role,
            team:
              item.desk_options.length > 0 && item.desk_options[0].team
                ? item.desk_options[0].team.name
                : undefined
          };
        });

        // filtra usuarios da nama
        list = list.filter((item: any) => {
          return item.email !== "nama@nama.ai";
        });

        return { ...state, list: list };
      } else {
        return state;
      }

    case "OPEN_MEMBER_MODAL":
      let selectedMember: Array<MemberModel> = state.list.filter(
        (member: MemberModel) => {
          return member.id.toString() === action.payload;
        }
      );
      return {
        ...state,
        errors: undefined,
        form: selectedMember[0] || MembersInitialState,
        showModal: true
      };

    case "CLOSE_MEMBER_MODAL":
      return {
        ...state,
        errors: undefined,
        form: MemberInitialState,
        showModal: false
      };

    case "CREATE_MEMBER":
      if (action.payload.status == 201) {
        return {
          ...state,
          form: MemberInitialState,
          errors: undefined,
          list: [
            ...state.list,
            {
              ...action.payload.data.user,
              role: action.payload.data.user.organizations_users[0].role
            }
          ],
          showModal: false
        };
      } else if (action.payload.response.data.errors) {
        return {
          ...state,
          errors: action.payload.response.data.errors
        };
      } else {
        return state;
      }

    case "UPDATE_MEMBER":
      if (action.payload.status == 200) {
        let updatedList: Array<MemberModel> = state.list.map(
          (member: MemberModel) => {
            if (member.id == action.payload.data.organizations_user.user_id) {
              return {
                ...member,
                ...action.payload.data.organizations_user,
                id: action.payload.data.organizations_user.user_id,
                role: action.payload.data.organizations_user.role
              };
            } else {
              return member;
            }
          }
        );
        return { ...state, showModal: false, list: updatedList };
      } else {
        return state;
      }

    case "DISCARD_MEMBER":
      if (action.payload.status == 200) {
        let updatedList: Array<MemberModel> = state.list.filter(
          (member: MemberModel) => {
            return member.id != action.payload.data.user.id;
          }
        );
        return { ...state, list: updatedList };
      } else {
        return state;
      }

    default:
      return state;
  }
}
