import * as React from "react";
import "./view-placeholder.scss";

export interface ViewPlaceholderTemplateProps {
  ["svgImage"]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  ["title"]: string;
  ["content"]: string;
}

export default class ViewPlaceholderTemplate extends React.Component<ViewPlaceholderTemplateProps> {
  public render() {
    const { svgImage, title, content } = this.props;
    return (
      <div className="view-placeholder">
        {
          svgImage({})
        }
        <h1>{title}</h1>
        <h2>{content}</h2>
      </div>
    );
  }
}