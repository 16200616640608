import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ReactComponent as SVGteamsNew } from "../../../prakriti/assets/arts/teams-art.svg";
import {
  TeamsAction,
  DashboardModel,
  TeamsModel,
  TeamModel,
  OrganizationModel,
  MembersModel
} from "@purusha/purusha";
import { NamaNavigationTemplate } from "@prakriti/prakriti";
import NamaTeamFormApp from "./team.form";
import { TeamInitialState } from "../../models/team.initial";
import TeamComponent from "./team.component";
import { MemberInitialState } from "@purusha/purusha";
import { addTeamMember } from "../../actions/teams.actions";

export interface TeamsComponentProps {
  ["teams"]: TeamsModel;
  ["members"]: MembersModel;
  ["organization"]?: OrganizationModel;
  ["fetchTeams"]: any;
  ["createTeam"]: any;
  ["openTeamModal"]: any;
  ["closeTeamModal"]: any;
  ["updateTeam"]: any;
  ["discardTeam"]: any;
  ["selectTeam"]: any;
  ["addTeamMember"]: any;
  ["discardTeamMember"]: any;
  ["fetchTeamMembers"]: any;
  ["updateTeamMember"]: any;
  ["match"]: any;
}
export interface TeamsComponentState {
  ["team"]: TeamModel;
}
class TeamsComponent extends React.Component<
  TeamsComponentProps,
  TeamsComponentState
> {
  constructor(
    public props: TeamsComponentProps,
    public state: TeamsComponentState
  ) {
    super(props);
    this.state = {
      team: TeamInitialState
    };
  }

  public componentWillMount = () => {
    if (this.props.organization) {
      this.props.fetchTeams(this.props.organization.id);
    }
  };

  public select = (id: string, action?: string) => {
    this.props.selectTeam(id, action);
    if (id !== "0") {
      if (this.props.organization) {
        this.props.fetchTeamMembers(this.props.organization.id, id);
      }
    }
  };

  public cancel = () => {
    if (this.props.teams.selected) {
      this.props.selectTeam(this.props.teams.selected.id);
    } else {
      this.props.selectTeam("0");
    }
  };

  public delete = (e: number) => {
    if (this.props.organization) {
      this.props.discardTeam(this.props.organization.id, e.toString());
    }
  };

  public addTeamMember = (e: any) => {
    if (this.props.organization && this.props.teams.selected) {
      this.props.addTeamMember(
        this.props.organization.id,
        this.props.teams.selected.id,
        e.user,
        e.max
      );
    }
  };

  public discardTeamMember = (e: any) => {
    if (this.props.organization && this.props.teams.selected) {
      this.props.discardTeamMember(
        this.props.organization.id,
        this.props.teams.selected.id,
        e.detail.id
      );
    }
  };

  public updateTeamMember = (e: any) => {
    if (this.props.organization && this.props.teams.selected) {
      this.props.updateTeamMember(
        this.props.organization.id,
        this.props.teams.selected.id,
        e.detail.id,
        e.detail.max_simultaneous_calls
      );
    }
  };

  public submit = (e: TeamModel) => {
    if (this.props.organization) {
      if (e.id == 0) {
        this.props.createTeam(this.props.organization.id, e);
      } else {
        this.props.updateTeam(this.props.organization.id, e);
      }
    }
  };

  public render() {
    return (
      <NamaNavigationTemplate
        title="Team"
        selected={this.props.teams.selected}
        items={this.props.teams.list.map((team: TeamModel) => {
          return {
            id: team.id,
            name: team.name,
            avatar: team.avatar || ""
          };
        })}
        action={this.props.teams.action}
        templates={{
          empty: (
            <section
              className="nama-content small"
              style={{ textAlign: "center" }}
            >
              <SVGteamsNew />
            </section>
          ),
          view: (
            <TeamComponent
              team={this.props.teams.selected}
              members={this.props.members}
              add={this.addTeamMember}
              update={this.updateTeamMember}
              remove={this.discardTeamMember}
              action={(action: string, payload: TeamModel) => {
                if (action === "delete") {
                  this.delete(payload.id);
                } else {
                  this.select(payload.id.toString(), action);
                }
              }}
            />
          ),
          edit: (
            <section className="nama-content">
              <NamaTeamFormApp
                errors={this.props.teams.errors}
                initial={this.props.teams.selected || this.state.team}
                onSubmit={this.submit}
                onCancel={this.cancel}
              />
            </section>
          ),
          new: (
            <section className="nama-content">
              <NamaTeamFormApp
                errors={this.props.teams.errors}
                initial={this.state.team}
                onSubmit={this.submit}
                onCancel={this.cancel}
              />
            </section>
          )
        }}
        select={(payload: string, action: string) => {
          this.select(payload, action);
        }}
      />
    );
  }
}

const mapStateToProps = (state: DashboardModel) => ({
  teams: state.teams,
  members: state.members,
  organization: state.organizations.selected
});
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(TeamsAction, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamsComponent);
