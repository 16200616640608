import moment from "moment";
import "moment/locale/pt-br";

moment.locale("pt-BR");

// Templates
import NamaDashboardTemplate from "./templates/dashboard.template";
import NamaNavigationTemplate from "./templates/navigation.template";
import NamaNavigationLayout from "./templates/navigation.layout";
import NamaTabsTemplate from "./templates/tabs.template";
import NamaConversationsLayout from "./templates/conversations.layout";
import NamaViewPlaceholder from "./templates/view-placeholder.template";
import NamaInsightsOverviewLayout from "./templates/insightsOverview.layout";

// Organisms
import NamaHeaderOrganism from "./organisms/header/header.organism";
import NamaMenuOrganism from "./organisms/menu/menu.organism";
import NamaModal from "./organisms/modal/modal.organism";
import NamaConfirmation from "./organisms/confirmation/confirmation.organism";
import ReceivedMessagesCard from "./organisms/received-messages-card/received-messages-card.organism";

// Molecules
import NamaList from "./molecules/list/list.molecule";
import NamaAccordeon from "./molecules/accordeon/accordeon.molecule";
import NamaConversable from "./molecules/conversable/conversable.molecule";
import NamaTable from "./molecules/table/table.molecule";
import NamaDashboardHeaderMenu from "./molecules/dashboard-header-menu/dashboard-header-menu.molecule";
import NamaChatItem from "./molecules/chatItem/chatItem.molecule";
import NamaChatList from "./molecules/chatList/chatList.molecule";
import NamaChatView from "./molecules/chatView/chatView.molecule";
import NamaMessageGroup from "./molecules/message-group/message-group";
import { ChannelSelect } from "./molecules/channel-select/channelSelect.molecule";

// Atoms
import NamaIcon from "./atoms/icon/icon.atom";
import NamaDropFile from "./atoms/dropfile/dropfile.atom";
import NamaButton from "./atoms/button/button.atom";
import NamaAvatar from "./atoms/avatar/avatar.atom";
import NamaInput from "./atoms/input/input.atom";
import NamaMiniDonutChart from "./atoms/mini-donut-chart/mini-donut-chart.atom";
import NamaPercentageBar from "./atoms/percentage-bar/percentage-bar.atom";
import NamaTag from "./atoms/tag/tag.atom";
import NamaDisplay from "./atoms/display/display.atom";
import NamaUploadAvatar from "./atoms/upload-avatar/upload-avatar.atom";
import NamaTagInput from "./atoms/tag-input/tag-input.atom";
import NamaColorInput from "./atoms/color-input/color-input.atom";
import NamaMessageBubble from "./atoms/message-bubble/message-bubble.atom";
import NamaDayDivider from "./atoms/day-divider/day-divider.atom";
import NamaEventMessage from "./atoms/event-message/event-message.atom";
import NamaInfiniteScroll from "./atoms/infinite-scroll/infinite-scroll.atom";
import NamaChatBegginingMessage from './atoms/chat-beggining-message/chat-begginning-message.atom';

export {
  NamaDashboardTemplate,
  NamaNavigationTemplate,
  NamaTabsTemplate,
  NamaNavigationLayout,
  NamaHeaderOrganism,
  NamaMenuOrganism,
  NamaTable,
  NamaModal,
  NamaDropFile,
  NamaList,
  NamaConfirmation,
  NamaAccordeon,
  NamaDashboardHeaderMenu,
  NamaChatItem,
  NamaChatList,
  NamaIcon,
  NamaTagInput,
  NamaButton,
  NamaAvatar,
  NamaTag,
  NamaInput,
  NamaMiniDonutChart,
  NamaPercentageBar,
  NamaUploadAvatar,
  NamaDisplay,
  NamaColorInput,
  NamaConversable,
  NamaMessageBubble,
  NamaChatView,
  NamaConversationsLayout,
  NamaMessageGroup,
  NamaDayDivider,
  NamaEventMessage,
  NamaInfiniteScroll,
  NamaChatBegginingMessage,
  NamaViewPlaceholder,
  NamaInsightsOverviewLayout,
  ReceivedMessagesCard,
  ChannelSelect,
};
