import axios from "axios";
import { AxiosPromise } from "axios";
import { UserModel } from "@purusha/purusha";
import { host_url } from "@purusha/config";

export default class UserService {
  public static base_url: string = `${host_url}`

  static current_user(): AxiosPromise<UserModel> {
    const url: string = `${this.base_url}/current_user`;
    return axios.get(url, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  static update_user_info(name: string, avatar: string): AxiosPromise {
    const url: string = `${this.base_url}/update_current_user`;
    return axios.put(url, {
      user: {
        avatar: avatar,
        name: name
      }
    });
  }

  static sing_out() {
    (document as any).querySelector("#logout").click();
  }
}
