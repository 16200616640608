import React, { Component } from "react";
import "./display.atom.scss";
import { Translate } from "ns-react-i18next";
import NamaIcon from "../icon/icon.atom";

export interface NamaDisplayProps {
  ["type"]: string;
  ["title"]?: string;
  ["icon"]?: string;
  ["children"]?: React.ReactChild;
}

class NamaDisplay extends Component<NamaDisplayProps> {
  render() {
    return (
      <div className={`nama-display ${this.props.type}`}>
        {this.props.title ? (
          <div className="title">
            <h3>
              {this.props.icon && <NamaIcon icon={this.props.icon} />}
              <Translate>{this.props.title}</Translate>
            </h3>
          </div>
        ) : (
          ""
        )}
        {this.props.children}
      </div>
    );
  }
}

export default NamaDisplay;
