import { MemberModel, MemberService } from "@purusha/purusha";

export let fetchMembers = (organization_id: string) => {
  const request = MemberService.members(organization_id);
  return {
    type: "FETCH_MEMBERS",
    payload: request
  };
};

export let openMemberModal = (organization_id: string) => {
  return {
    type: "OPEN_MEMBER_MODAL",
    payload: organization_id
  };
};

export let closeMemberModal = () => {
  return {
    type: "CLOSE_MEMBER_MODAL"
  };
};

export let createMember = (organization_id: string, user: MemberModel) => {
  const request = MemberService.create(organization_id, user);
  return {
    type: "CREATE_MEMBER",
    payload: request
  };
};

export let updateMember = (organization_id: string, user: MemberModel) => {
  const request = MemberService.update(organization_id, user);
  return {
    type: "UPDATE_MEMBER",
    payload: request
  };
};

export let discardMember = (organization_id: string, user_id: string) => {
  const request = MemberService.discard(organization_id, user_id);
  return {
    type: "DISCARD_MEMBER",
    payload: request
  };
};
