import * as React from "react";
import {
  NamaIcon,
  NamaButton,
  NamaAccordeon,
  NamaConfirmation
} from "@prakriti/prakriti";
import { Translate } from "ns-react-i18next";
import { ChannelModel } from "@purusha/models/channel.model";

export interface ChannelComponentProps {
  ["channel_type"]: any;
  ["discard"]: any;
  ["select"]: any;
  ["new"]: Function;
  ["reset"]: Function;
  ["delete"]?: Function;
  ["flows"]?: Array<any>;
  ["teams"]?: Array<any>;
}

export interface ChannelComponentState {}

class ChannelComponent extends React.Component<
  ChannelComponentProps,
  ChannelComponentState
> {
  public channel = () => {
    return (
      <div>
        <h2>{this.props.channel_type.title}</h2>
        <NamaButton
          onClick={this.props.new}
          className="background-purple-300 color-white small padded"
        >
          <Translate>new_channel</Translate>
        </NamaButton>

        <section className="nama-destacked-content" style={{ marginTop: 32 }}>
          <div className="header">
            <NamaIcon icon="bot" className="fill-purple-300" />
          </div>
          {this.props.flows &&
            this.props.flows.map((channel: any, index: number) => {
              return (
                <ChannelItemComponent
                  channel={channel}
                  key={index}
                  type="bot"
                  discard={(e: any) => this.props.discard(channel)}
                  reset={(e: any) => this.props.reset(channel)}
                  select={(e: any) => {
                    this.props.select(channel);
                  }}
                />
              );
            })}
        </section>
        <section className="nama-destacked-content" style={{ marginTop: 32 }}>
          <div className="header">
            <NamaIcon icon="teams" className="fill-purple-300" />
          </div>
          {this.props.teams &&
            this.props.teams.map((channel: any, index: number) => {
              return (
                <ChannelItemComponent
                  key={index}
                  type="team"
                  channel={channel}
                  reset={(e: any) => this.props.reset(channel)}
                  discard={(e: any) => this.props.discard(channel)}
                  select={(e: any) => this.props.select(channel)}
                />
              );
            })}
        </section>
      </div>
    );
  };

  public render() {
    switch (this.props.channel_type.key) {
      case "web":
        return this.channel();
      case "facebook":
        return this.channel();
      case "infobip":
        return this.channel();
      case "slack":
        return this.channel();
      case "workplace":
        return this.channel();
      default:
        return (
          <div>
            <h2>soon</h2>
          </div>
        );
    }
  }
}

class ChannelItemComponent extends React.Component<{
  select: Function;
  discard: Function;
  type: string;
  channel: ChannelModel;
  reset: Function;
}> {
  public confirmation = React.createRef<NamaConfirmation>();

  public render() {
    return (
      <NamaAccordeon
        avatar={this.props.channel.avatar}
        forceAvatar={true}
        title={this.props.channel.title || "-"}
      >
        <div style={{ display: "table" }}>
          <div className="nama-copy-value">
            <NamaIcon icon="copy" />
            TOKEN: {this.props.channel.token}
          </div>
          <br />
          <div className="nama-copy-value">
            <NamaIcon icon="copy" />
            ID: {this.props.channel.id}
          </div>
        </div>

        <NamaButton
          className="clear icon left small"
          onClick={() => {
            this.props.reset(this.props.channel);
          }}
        >
          <NamaIcon className="fill-blue-300" icon="refresh" /> Force update
          channel
        </NamaButton>

        <NamaButton
          className="clear icon left small"
          onClick={() => {
            window.open(
              (document as any).location.origin +
                "/preview/" +
                this.props.channel.token
            );
          }}
        >
          <NamaIcon className="fill-blue-300" icon="web" /> Preview Widget
        </NamaButton>

        <NamaButton
          className="clear icon left small"
          onClick={() => {
            this.props.select(this.props.channel);
          }}
        >
          <NamaIcon className="fill-blue-300" icon="edit" /> Edit Channel
        </NamaButton>
        <NamaButton
          style={{ float: "right" }}
          className="clear icon left small"
          onClick={(e: any) => {
            if (this.confirmation.current) {
              this.confirmation.current.open(e.detail);
            }
          }}
        >
          <NamaIcon icon="trash" /> Delete Channel
        </NamaButton>
        <NamaConfirmation
          onSuccess={this.props.discard}
          ref={this.confirmation}
          confirmation={this.props.channel.title || "-"}
        >
          <div>
            <h3>
              <Translate> channel_delete_title </Translate>
            </h3>
            <p>
              <Translate> channel_delete_descript </Translate>
            </p>
          </div>
        </NamaConfirmation>
      </NamaAccordeon>
    );
  }
}

export default ChannelComponent;
