import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import * as i18n from "i18next";
import { Translations } from "./purusha/i18n";
import { I18NextProvider } from "ns-react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { DashboardStore } from "./purusha/stores/dashboard.store";

i18n.use(LanguageDetector).init({
  resources: Translations,
  fallbackLng: "en",
  ns: ["default"],
  defaultNS: "default",
  fallbackNS: "default"
});

const oldSavedState = localStorage.getItem('nama-dashboard-state');
if (oldSavedState) {
  localStorage.clear();
}

ReactDOM.render(
  <Provider store={DashboardStore}>
    <I18NextProvider i18n={i18n}>
      <App />
    </I18NextProvider>
  </Provider>,
  document.getElementById("nama-platform")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
