import { TeamModel } from "@purusha/purusha";

export const TeamInitialState: TeamModel = {
  id: 0,
  organization_id: 0,
  token: "",
  name: "",
  description: "",
  avatar: ""
};
