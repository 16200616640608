import { ChatModel, MessageModel } from "@purusha/purusha";

export type ChatsStateModel = {
  ["list"]: Array<ChatModel>;
  ["selected"]?: ChatModel;
  ["isAllLoaded"]: Boolean;
};

export const initialChatsState: ChatsStateModel = {
  list: [],
  selected: undefined,
  isAllLoaded: false,
};

export function ChatsReducer(
  state: ChatsStateModel = initialChatsState,
  action: any
) {
  switch (action.type) {
    case "FETCH_CHAT_HISTORY":
      let isAllLoaded = false;
      if (action.payload.data.length < 20) {
        isAllLoaded = true
      }
      if (action.payload.reset) {
        return { ...initialChatsState, list: [...initialChatsState.list, ...action.payload.data], isAllLoaded, selected: undefined };
      }
      return { ...state, list: [...state.list, ...action.payload.data], isAllLoaded, selected: undefined };
    case "SELECT_CHAT":
      return { ...state, selected: action.payload.chat };
    case "LOAD_CHAT_MESSAGES":
      const { messages, chatId } = action.payload;
      const chatIndex = state.list.findIndex(c => c.id === chatId);
      const oldMessages = state.list[chatIndex].messages || []
      let newList = [...state.list];
      if (messages.length < 20) {
        newList[chatIndex].allMessagesLoaded = true;
      }
      newList[chatIndex].messages = [...messages, ...oldMessages];
      return { ...state, list: newList, selected: newList[chatIndex] };
    case "CLEAR_CHATS":
      return initialChatsState;
  }
  return state;
}
