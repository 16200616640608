import { FacebookModel } from "@purusha/purusha";

export function FacebookReducer(
  state: FacebookModel = { pages: [] },
  action: any
) {
  switch (action.type) {
    case "FETCH_FACEBOOK_PAGES":
      if (action.payload.status == 200) {
        return {
          ...state,
          pages: action.payload.data,
          selected: undefined
        };
      }
    case "SELECT_FACEBOOK_PAGE":
      return {
        ...state,
        selected: action.payload
      };

    default:
      return state;
  }
}
