import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import { ChannelModel } from "@purusha/models/channel.model";
import { host_url } from "@purusha/config";
var obj2fd = require("obj2fd").default;

export class ChannelsService {
  public static base_url: string = `${host_url}`
  // /api/v:api_version/channels
  public static create(
    organization_id: string,
    channel_type: string,
    channel: ChannelModel
  ): AxiosPromise<any> {
    let url: string = `${this.base_url}/api/v1/organizations/${organization_id}/channels`;
    const config = {
      headers: { authorization: "Token token=b43ahgFEBoAwyFeoV4KqTfBX" }
    };
    return axios.post(
      url,
      {
        channel_type: channel_type,
        channel: {
          ...channel,
          ...channel.conversable
        }
      },
      config
    );
  }

  public static update(
    organization_id: string,
    channel_type: string,
    channel: ChannelModel
  ): AxiosPromise<any> {
    let url: string = `${this.base_url}/api/v1/organizations/${organization_id}/channels/${
      channel.id
      }`;
    const config = {
      headers: { authorization: "Token token=b43ahgFEBoAwyFeoV4KqTfBX" }
    };
    return axios.post(
      url,
      {
        channel_type: channel_type,
        channel: {
          ...channel,
          ...channel.conversable,
          id: undefined,
          assistant_token: undefined,
          conversable: undefined
        }
      },
      config
    );
  }

  public static fetch(
    organization_id: string,
    channel_type?: string
  ): AxiosPromise<Array<any>> {
    const url: string = `/api/v1/organizations/${organization_id}/channels${channel_type ? `?channel_type=${channel_type}` : ''}`;
    const config = {
      headers: { authorization: "Token token=b43ahgFEBoAwyFeoV4KqTfBX" }
    };
    return axios.get(url, config);
  }

  public static reset(
    organization_id: string,
    channel_token: string
  ): AxiosPromise<Array<any>> {
    const url: string = `${this.base_url}/api/v1/organizations/${organization_id}/channels/restart`;
    const config = {
      headers: { authorization: "Token token=b43ahgFEBoAwyFeoV4KqTfBX" }
    };
    return axios.post(
      url,
      {
        channel_token: channel_token
      },
      config
    );
  }

  public static discard(
    organization_id: string,
    channel_id: string
  ): AxiosPromise<Array<any>> {
    const url: string = `${this.base_url}/api/v1/organizations/${organization_id}/channels/${channel_id}`;
    const config = {
      headers: { authorization: "Token token=b43ahgFEBoAwyFeoV4KqTfBX" }
    };
    return axios.delete(url, config);
  }
}
