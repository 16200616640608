import React, {
  Component,
  ReactChildren,
  ReactNode,
  ReactElement
} from "react";
import "./accordeon.molecule.scss";
import { NamaButton, NamaIcon, NamaAvatar } from "@prakriti/prakriti";

interface NamaAccordeonProps {
  ["title"]: string;
  ["forceAvatar"]?: boolean;
  ["noArrow"]?: boolean;
  ["avatar"]?: string;
  ["subtitle"]?: string | ReactElement<HTMLElement>;
  ["children"]: ReactNode;
}

class NamaAccordeon extends Component {
  constructor(public props: NamaAccordeonProps, public state: any) {
    super(props);
    this.state = {
      open: false
    };
  }

  public toggle = () => {
    if(!this.props.noArrow){
      this.setState({ open: !this.state.open });
    }
  };

  render() {
    return (
      <div
        className={`nama-accordeon accordeon ${
          this.state.open ? "opened" : ""
        }`}
      >
        <div className="flex-wrapper" onClick={this.toggle}>
          <div className="circle" />
          {(this.props.avatar || this.props.forceAvatar) && (
            <NamaAvatar
              size="small"
              url={this.props.avatar ? this.props.avatar : undefined}
              name={this.props.title}
            />
          )}
          <div className="name">
            <span className="title">{this.props.title}</span>
            <span className="subtitle" />
            {
              !this.props.noArrow &&
              <NamaButton className="clear small accordeon-icon">
                <NamaIcon icon="dropdown-down-single" />
              </NamaButton>
            }
            
          </div>
          <div className="timestamp">{this.props.subtitle}</div>
        </div>
        <div className="description">{this.props.children}</div>
      </div>
    );
  }
}

export default NamaAccordeon;
