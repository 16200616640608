import * as React from "react";
import * as Yup from "yup";
import { NamaButton, NamaInput, NamaModal } from "@prakriti/prakriti";
import { Formik, Form } from "formik";
import { Translate } from "ns-react-i18next";

export interface NamaConfirmationProps {
  ["onSuccess"]: any;
  ["children"]: any;
  ["confirmation"]: string;
}

export interface NamaConfirmationState {
  ["isOpen"]: boolean;
}

class NamaConfirmation extends React.Component {
  public confirmationModal: React.RefObject<NamaModal>;
  public payload: any;
  public formik: any;
  constructor(
    public props: NamaConfirmationProps,
    public state: NamaConfirmationState
  ) {
    super(props);
    this.formik = React.createRef();
    this.state = {
      isOpen: false
    };
    this.confirmationModal = React.createRef<NamaModal>();
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.submit = this.submit.bind(this);
    this.payload = {};
  }

  public open(payload: any = {}) {
    this.payload = payload;
    this.setState({ isOpen: true });
  }

  public close() {
    const form = this.formik.current;
    if (form) {
      this.setState({ isOpen: false });
      form.resetForm();
    }
  }

  public submit() {
    this.close();
    this.props.onSuccess(this.payload);
  }

  public render() {
    let confirmationSchema = Yup.object().shape({
      name: Yup.string()
        .matches(
          new RegExp(this.props.confirmation || "", "i"),
          "validation_same"
        )
        .required("validation_required")
    });
    return (
      <NamaModal isOpen={this.state.isOpen} onDiscardByUser={this.close}>
        {this.props.children}
        <Formik
          ref={this.formik}
          initialValues={{ name: "" }}
          validationSchema={confirmationSchema}
          enableReinitialize={true}
          onSubmit={this.submit}
        >
          <Form className="nama-form">
            <NamaInput
              type="text"
              name="name"
              label={this.props.confirmation}
            />
            <div className="actions">
              <button type="submit" className="nama-button-old danger">
                <Translate> confirm </Translate>
              </button>
              <NamaButton onClick={this.close} className="clear">
                <Translate> cancel </Translate>
              </NamaButton>
            </div>
          </Form>
        </Formik>
      </NamaModal>
    );
  }
}
export default NamaConfirmation;
