import React, { Component } from "react";
import "./color-input.atom.scss";
import TagsInput from "react-tagsinput";
import { Translate } from "ns-react-i18next";
import { BlockPicker } from "react-color";

export interface NamaColorInputProps {
  ["label"]?: string;
  ["name"]: string;
  ["hint"]?: string;
  ["size"]?: string;
  ["className"]?: string;
  ["onChange"]?: Function;
  ["color"]: string;
}
export interface NamaColorInputState {
  ["color"]: string;
}

class NamaColorInput extends Component<
  NamaColorInputProps,
  NamaColorInputState
> {
  constructor(
    public props: NamaColorInputProps,
    public state: NamaColorInputState
  ) {
    super(props);
    this.state = { color: this.props.color || "#2962FF" };
  }

  public handleChange = (color: any) => {
    this.setState({ color: color.hex });
    if (this.props.onChange) {
      this.props.onChange({
        target: {
          name: this.props.name,
          value: color.hex
        }
      });
    }
  };

  render() {
    return (
      <fieldset className={`${this.props.className} ${this.props.size}`}>
        {this.props.label && (
          <label>
            <Translate>{this.props.label}</Translate>
          </label>
        )}
        <div className="nama-color-picker">
          <BlockPicker
            width="100%"
            color={this.state.color}
            onChange={this.handleChange}
            colors={[
              "#2962FF",
              "#00BFA5",
              "#FF7043",
              "#FFB300",
              "#8D25AA",
              "#F50057"
            ]}
          />
        </div>
        {this.props.hint && <div className="nama-hint">{this.props.hint}</div>}
      </fieldset>
    );
  }
}

export default NamaColorInput;
