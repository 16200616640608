import { InsightsService, ChannelsService } from "@purusha/purusha";
import { IDateRange, DropdownItem } from "nama-design";

export const fetchChatStats = (organization_id: number, options?: { dateFilter?: IDateRange, channelFilter?: Array<DropdownItem> }) => {
  const request = InsightsService.FETCH_CHAT_STATS(organization_id, options);
  return {
    type: "FETCH_CHAT_STATS",
    payload: request
  };
};

export const setDateFilter = (dateRange: IDateRange) => {
  return {
    type: "SET_DATE_FILTER",
    dateFilter: dateRange
  };
};

export const fetchChannels = (organization_id: number) => {
  const request = ChannelsService.fetch(organization_id.toString());
  return {
    type: "FETCH_ALL_CHANNELS",
    payload: request
  };
};

export const setChannelFilter = (channels: Array<DropdownItem>) => {
  return {
    type: "SET_CHANNEL_FILTER",
    channelFilter: channels
  };
};

