import * as React from "react";
import { NamaAvatar } from "@prakriti/prakriti";
import { ChatModel } from '@purusha/models/chat.model';
import './chatItem.scss';
import moment from "moment";

export interface ChatItemComponentProps {
  ["chat"]: ChatModel;
  ["selected"]: boolean;
  ["onClick"]: any;
}

class ChatItemComponent extends React.Component<
  ChatItemComponentProps
  > {
  constructor(
    public props: ChatItemComponentProps
  ) {
    super(props);
  }


  public render() {
    const { chat, onClick, selected } = this.props;
    const { user_name, service_name, id, last_message } = chat;
    return (
      <div className={`chat-item${selected ? ' selected' : ''}`} onClick={onClick}>
        <NamaAvatar name={user_name} />
        <div className="content">
          <h1>Conversa #{id}</h1>
          <p>{last_message && last_message.text}</p>
          <footer>Via <b>{service_name}</b></footer>
        </div>
        <div className="timestamp">
          {moment(last_message.created_at).format("HH:mm")}
        </div>
      </div>
    );
  }
}

export default ChatItemComponent;