import React from "react";
import { connect, FormikContext } from "formik";

type EffectProps = {
  ["formik"]: FormikContext<{}>;
  ["errors"]?: any;
};

class AsyncErrorEffect extends React.Component<EffectProps> {
  public parseErrors(erros: any) {
    let p_errors: any = {};
    for (let error in erros) {
      p_errors[error] = erros[error][0];
    }
    return p_errors;
  }

  public componentWillReceiveProps = (nextProps: any) => {
    if (nextProps.errors !== this.props.errors) {
      this.props.formik.setErrors(this.parseErrors(this.props.errors));
    }
  };

  render() {
    return null;
  }
}

export default connect(AsyncErrorEffect);
