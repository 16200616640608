import * as React from "react";
import "./dashboard.template.scss";
import {
  Switch,
  Route,
  Router,
  Redirect,
  NavLink,
} from "react-router-dom";
import { createBrowserHistory } from "history"

import { Transition, animated } from "react-spring";
import {
  NamaIcon,
  NamaMenuOrganism,
  NamaHeaderOrganism
} from "@prakriti/prakriti";

interface NamaDashboardTemplateProps {
  ["header"]: React.ReactChild;
  ["default"]: string;
  ["showMenu"]: boolean;
  ["white"]?: boolean;
  ["apps"]: Array<NamaAppTemplateProps>;
}

interface NamaAppTemplateProps {
  ["component"]: any;
  ["icon"]: string;
  ["title"]: string;
  ["route"]: string;
  ["hidden"]?: boolean;
}

interface NamaDashboardTemplateState {}

class NamaDashboardTemplate extends React.Component<
  NamaDashboardTemplateProps,
  NamaDashboardTemplateState
> {
  public history: any;
  constructor(
    public props: NamaDashboardTemplateProps,
    public state: NamaDashboardTemplateState
  ) {
    super(props);
    this.history = createBrowserHistory();
  }

  public animation = (style: any, app: any) => (
    <animated.div className="dashboard-animate" style={style}>
      <app.component />
    </animated.div>
  );

  public header = (title: string, icon: string) => {
    return (
      <NamaHeaderOrganism icon={icon} title={title}>
        {this.props.header}
      </NamaHeaderOrganism>
    );
  };

  public menu = () => {
    return (
      <NamaMenuOrganism show={this.props.showMenu}>
        {this.props.apps.map((app: NamaAppTemplateProps, index: number) => {
          return (
            app.hidden || (
              <div className="item" key={index}>
                <NavLink  to={app.route} activeClassName="active">
                  <NamaIcon icon={app.icon} />
                </NavLink>
              </div>
            )
          );
        })}
      </NamaMenuOrganism>
    );
  };

  public dashboard = (location: any) => {
    const animationProps = {
      from: { opacity: 0, transform: "translate3d(-200px, 0px, 0px)" },
      enter: { opacity: 1, transform: "translate3d(0px, 0px, 0px)" },
      leave: { opacity: 0, transform: "translate3d(200px, 0px, 0px)" }
    };

    return (
      <div id="dashboard">
        {this.props.apps.map((app: NamaAppTemplateProps, index: number) => {
          return (
            <Route
              key={index}
              exact={app.route ? false : true}
              path={app.route}
              render={props => this.header(app.title, app.icon)}
            />
          );
        })}
        <Route
          exact
          path="/"
          render={() => <Redirect to={this.props.default} />}
        />
        {this.menu()}
        <div
          id="apps"
          className={`${this.props.showMenu ? "show-menu" : ""} ${
            this.props.white ? "white-bg" : ""
          }`}
        >
          <Transition
            native
            {...animationProps}
            items={location}
            keys={location => location.pathname.split("/")[1]}
          >
            {location => style => (
              <Switch location={location}>
                {this.props.apps.map(
                  (app: NamaAppTemplateProps, index: number) => {
                    return (
                      <Route
                        key={location.pathname.split("/")[1]}
                        exact={app.route ? false : true}
                        path={app.route}
                        render={props => this.animation(style, app)}
                      />
                    );
                  }
                )}
              </Switch>
            )}
          </Transition>
        </div>
      </div>
    );
  };

  public componentDidUpdate = () => {};

  public render() {
    return (
      <Router history={this.history}>
        <Route render={({ location }) => this.dashboard(location)} />
      </Router>
    );
  }
}

export default NamaDashboardTemplate;
