import * as React from "react";
import * as Yup from "yup";

import {
  TeamModel,
  AsyncErrorEffect,
  MembersModel,
  MemberModel
} from "@purusha/purusha";
import {
  NamaButton,
  NamaConversable,
  NamaIcon,
  NamaConfirmation,
  NamaTable,
  NamaInput
} from "@prakriti/prakriti";
import { Translate } from "ns-react-i18next";
import { Formik, Form } from "formik";

export interface TeamComponentProps {
  ["team"]?: TeamModel;
  ["members"]: MembersModel;
  ["errors"]?: any;
  ["action"]: Function;
  ["add"]: Function;
  ["update"]: Function;
  ["remove"]: Function;
}
export interface TeamComponentState {}

class TeamComponent extends React.Component<
  TeamComponentProps,
  TeamComponentState
> {
  public formik: any;

  public submit = (e: any) => {
    this.props.add(e);
  };

  public render() {
    this.formik = React.createRef();
    let memberTeamSchema = Yup.object().shape({
      user: Yup.string().required("validation_required")
    });
    const confirmation = React.createRef<NamaConfirmation>();
    return (
      <section className="nama-content">
        {this.props.team ? (
          <div>
            <NamaConversable {...this.props.team} url={this.props.team.avatar}>
              <NamaButton
                onClick={() => {
                  this.props.action("edit", this.props.team);
                }}
                className="nama-button-old"
              >
                Editar
              </NamaButton>
              <NamaButton
                onClick={() => {
                  if (confirmation.current) {
                    confirmation.current.open();
                  }
                }}
                className="nama-button-old"
              >
                Delete
              </NamaButton>
            </NamaConversable>

            <section className="nama-row">
              <NamaTable
                color="clear"
                actions={[
                  {
                    name: "minus",
                    icon: "minimize",
                    onClick: (e: any) => {
                      if(e.detail.max_simultaneous_calls > 1){
                        e.detail.max_simultaneous_calls =
                          e.detail.max_simultaneous_calls - 1;
                        this.props.update(e);
                      }
                    }
                  },
                  {
                    name: "plus",
                    icon: "add",
                    onClick: (e: any) => {
                      e.detail.max_simultaneous_calls =
                        e.detail.max_simultaneous_calls + 1;
                      this.props.update(e);
                    }
                  },
                  {
                    name: "delete",
                    icon: "trash",
                    onClick: (e: any) => {
                      this.props.remove(e);
                    }
                  }
                ]}
                header={[
                  {
                    key: "name",
                    value: "Name",
                    direction: "left"
                  },
                  {
                    key: "email",
                    value: "email",
                    direction: "left"
                  },
                  {
                    key: "role",
                    value: "Role",
                    direction: "left"
                  },
                  {
                    key: "max_simultaneous_calls",
                    value: "Max Simultaneous Chats",
                    direction: "center"
                  }
                ]}
                data={this.props.team.members || []}
              />
            </section>

            <Formik
              ref={this.formik}
              validationSchema={memberTeamSchema}
              initialValues={{
                user: undefined,
                max: 5
              }}
              enableReinitialize={true}
              onSubmit={this.submit}
              render={(props: any) => (
                <Form className="nama-form inline">
                  <AsyncErrorEffect
                    formik={this.formik}
                    errors={this.props.errors}
                  />
                  <NamaInput type="select" name="user" label="Operators">
                    <option>Select a member to add</option>
                    {this.props.members.list.map(
                      (member: MemberModel, index: number) => {
                        return member.role === "operator" ? (
                          <option key={index} value={member.id}>
                            {member.name}
                          </option>
                        ) : (
                          ""
                        );
                      }
                    )}
                  </NamaInput>
                  <NamaInput
                    size="small"
                    type="number"
                    name="max"
                    label="Max Calls"
                    step="1"
                    min="1"
                  />
                  <div className="actions">
                    <button type="submit" className="nama-button-old primary">
                      <NamaIcon icon="add" />
                    </button>
                  </div>
                </Form>
              )}
            />

            <NamaConfirmation
              onSuccess={() => {
                this.props.action("delete", this.props.team);
              }}
              ref={confirmation}
              confirmation={this.props.team.name}
            >
              <div>
                <h3>
                  <Translate> team_delete_title </Translate>
                </h3>
                <p>
                  <Translate> team_delete_descript </Translate>
                </p>
              </div>
            </NamaConfirmation>
          </div>
        ) : (
          <p>teste</p>
        )}
      </section>
    );
  }
}

export default TeamComponent;
