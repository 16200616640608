import axios from "axios";
import { AxiosPromise } from "axios";
import { host_url } from "@purusha/config";
export default class OrganizationsService {

  public static base_url: string = `${host_url}/api/v1/organizations`

  static available(): AxiosPromise {
    const url: string = `${this.base_url}/available`;
    return axios.get(url,
      {
        withCredentials: true
      });
  }

  static information(organization_id: string): AxiosPromise {
    const url: string = `${this.base_url}/${organization_id}/information`;
    return axios.get(url);
  }

  static create(name: string): AxiosPromise {
    const url: string = `${this.base_url}`;
    return axios.post(url, {
      name: name
    }, {
      withCredentials: true
    });
  }

  static addDomain(organization_id: string, domain: string): AxiosPromise {
    const url: string = `${this.base_url}/${organization_id}/whitelist`;
    return axios.post(url, {
      domain: domain
    });
  }

  static deleteDomain(
    organization_id: string,
    domain_id: string
  ): AxiosPromise {
    const url: string = `${this.base_url}/${organization_id}/whitelist?domain_id=${domain_id}`;
    return axios.delete(url);
  }

  static getDomains(organization_id: string): AxiosPromise {
    const url: string = `${this.base_url}/${organization_id}/whitelist`;
    return axios.get(url);
  }

  static update(id: string, name: string): AxiosPromise {
    const url: string = `${this.base_url}/${id}`;
    return axios.put(url, {
      name: name,
      id: id
    });
  }

  static discard(id: string): AxiosPromise {
    const url: string = `${this.base_url}/${id}`;
    return axios.delete(url);
  }

  static list_api_calls(id: string): AxiosPromise {
    const url: string = `${this.base_url}/${id}/data`;
    return axios.get(url);
  }
}
