import { OrganizationsInitialState } from "@purusha/purusha";
import { MembersInitialState } from "@purusha/purusha";
import { UserInitialState } from "@purusha/purusha";
import { AssistantsInitialState } from "@purusha/purusha";

export const DashboardInitial = {
  organizations: OrganizationsInitialState,
  user: UserInitialState,
  members: MembersInitialState,
  assistants: AssistantsInitialState
};
