import { createStore, applyMiddleware } from "redux";
import { DashboardInitial, DashboardModel } from "@purusha/purusha";
import promises from "redux-promise";
import { LocalLoadState } from "../services/localstorage.service";
import { DashboardReducer } from "@purusha/reducers/dashboard.reducer";
import thunk from "redux-thunk";

let loadInitialValues = (): any => {
  const localStore = LocalLoadState("nama-dashboard-state") as DashboardModel;
  return localStore || DashboardInitial;
};

export let DashboardStore = applyMiddleware(promises, thunk)(createStore)(
  DashboardReducer,
  loadInitialValues()
);


