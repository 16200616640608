import * as React from "react";
import './chat-beggining-message.scss';
import NamaIcon from "../icon/icon.atom";

export interface ChatBegginingMessageomponentProps {
  ["text"]: String;
}

class ChatBegginingMessageomponent extends React.Component<
  ChatBegginingMessageomponentProps
  > {
  constructor(
    public props: ChatBegginingMessageomponentProps
  ) {
    super(props);
  }

  public render() {
    const { text } = this.props;
    return (
      <div className="chat-beggining-message">
        <NamaIcon className="fill-grey-700" icon="arrow_down" />
        <p>
          {text}
        </p>
      </div>
    );
  }
}

export default ChatBegginingMessageomponent;