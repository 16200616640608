import React from 'react';
import { ReactComponent as SVGTOPic } from './20/TOPic.svg'
import { ReactComponent as SVGabove } from './20/above.svg'
import { ReactComponent as SVGadd } from './20/add.svg'
import { ReactComponent as SVGanalytics } from './20/analytics.svg'
import { ReactComponent as SVGattachement } from './20/attachement.svg'
import { ReactComponent as SVGback } from './20/back.svg'
import { ReactComponent as SVGnext } from './20/next.svg'
import { ReactComponent as SVGbackward } from './20/backward.svg'
import { ReactComponent as SVGbelow } from './20/below.svg'
import { ReactComponent as SVGbot } from './20/bot.svg'
import { ReactComponent as SVGbuttons } from './20/buttons.svg'
import { ReactComponent as SVGcalendar } from './20/calendar.svg'
import { ReactComponent as SVGcards } from './20/cards.svg'
import { ReactComponent as SVGcategory } from './20/category.svg'
import { ReactComponent as SVGchange } from './20/change.svg'
import { ReactComponent as SVGchannels } from './20/channels.svg'
import { ReactComponent as SVGchat } from './20/chat.svg'
import { ReactComponent as SVGclock } from './20/clock.svg'
import { ReactComponent as SVGcomponents } from './20/components.svg'
import { ReactComponent as SVGconversion } from './20/conversion.svg'
import { ReactComponent as SVGcopy } from './20/copy.svg'
import { ReactComponent as SVGdebug } from './20/debug.svg'
import { ReactComponent as SVGdeletes } from './20/delete.svg'
import { ReactComponent as SVGdesk } from './20/desk.svg'
import { ReactComponent as SVGdesktop } from './20/desktop.svg'
import { ReactComponent as SVGdisconect } from './20/disconect.svg'
import { ReactComponent as SVGdivider } from './20/divider.svg'
import { ReactComponent as SVGdropdownDownSingle } from './20/dropdown_down_single.svg'
import { ReactComponent as SVGdropdownUpSingle } from './20/dropdown_up_single.svg'
import { ReactComponent as SVGdropdown } from './20/dropdown.svg'
import { ReactComponent as SVGedit } from './20/edit.svg'
import { ReactComponent as SVGemail } from './20/email.svg'
import { ReactComponent as SVGemoji } from './20/emoji.svg'
import { ReactComponent as SVGentity } from './20/entity.svg'
import { ReactComponent as SVGerror } from './20/error.svg'
import { ReactComponent as SVGexports } from './20/export.svg'
import { ReactComponent as SVGfilter } from './20/filter.svg'
import { ReactComponent as SVGflow } from './20/flow.svg'
import { ReactComponent as SVGfolder } from './20/folder.svg'
import { ReactComponent as SVGforward } from './20/forward.svg'
import { ReactComponent as SVGhome } from './20/home.svg'
import { ReactComponent as SVGimages } from './20/images.svg'
import { ReactComponent as SVGinformation } from './20/information.svg'
import { ReactComponent as SVGintent } from './20/intent.svg'
import { ReactComponent as SVGlaunch } from './20/launch.svg'
import { ReactComponent as SVGlineCopy } from './20/line_copy.svg'
import { ReactComponent as SVGline } from './20/line.svg'
import { ReactComponent as SVGlist } from './20/list.svg'
import { ReactComponent as SVGlocation } from './20/location.svg'
import { ReactComponent as SVGmessenger } from './20/messenger.svg'
import { ReactComponent as SVGminimize } from './20/minimize.svg'
import { ReactComponent as SVGnoChat } from './20/no_chat.svg'
import { ReactComponent as SVGnotification } from './20/notification.svg'
import { ReactComponent as SVGok } from './20/ok.svg'
import { ReactComponent as SVGoptions } from './20/options.svg'
import { ReactComponent as SVGpaintBucket } from './20/paint_bucket.svg'
import { ReactComponent as SVGpassword } from './20/password.svg'
import { ReactComponent as SVGpayment } from './20/payment.svg'
import { ReactComponent as SVGphone } from './20/phone.svg'
import { ReactComponent as SVGplus } from './20/plus.svg'
import { ReactComponent as SVGprofile } from './20/profile.svg'
import { ReactComponent as SVGpublish } from './20/publish.svg'
import { ReactComponent as SVGquickReply } from './20/quick_reply.svg'
import { ReactComponent as SVGrefresh } from './20/refresh.svg'
import { ReactComponent as SVGrestart } from './20/restart.svg'
import { ReactComponent as SVGsearch } from './20/search.svg'
import { ReactComponent as SVGsee_transcript } from './20/see_transcript.svg'
import { ReactComponent as SVGsend } from './20/send.svg'
import { ReactComponent as SVGsettings } from './20/settings.svg'
import { ReactComponent as SVGsmartphone } from './20/smartphone.svg'
import { ReactComponent as SVGsuccess } from './20/success.svg'
import { ReactComponent as SVGsuggestions } from './20/suggestions.svg'
import { ReactComponent as SVGtablet } from './20/tablet.svg'
import { ReactComponent as SVGteams } from './20/teams.svg'
import { ReactComponent as SVGtelegram } from './20/telegram.svg'
import { ReactComponent as SVGslack } from './20/slack.svg'
import { ReactComponent as SVGworkplace} from './20/workplace.svg'
import { ReactComponent as SVGtoolFix } from './20/tool_fix.svg'
import { ReactComponent as SVGtransfer } from './20/transfer.svg'
import { ReactComponent as SVGtrash } from './20/trash.svg'
import { ReactComponent as SVGvariable } from './20/variable.svg'
import { ReactComponent as SVGvisible } from './20/visible.svg'
import { ReactComponent as SVGweb } from './20/web.svg'
import { ReactComponent as SVGwhatsapp } from './20/whatsapp.svg';
import { ReactComponent as SVGarrowDown } from './20/arrow_down.svg';

export function Icons(icon: string) {
  switch (icon) {
    case 'TOPic': { return <SVGTOPic />; }
    case 'above': { return (<SVGabove />); }
    case 'add': { return <SVGadd />; }
    case 'analytics': { return <SVGanalytics />; }
    case 'attachement': { return <SVGattachement />; }
    case 'back': { return <SVGback />; }
    case 'next': { return <SVGnext />; }
    case 'backward': { return <SVGbackward />; }
    case 'below': { return <SVGbelow />; }
    case 'bot': { return <SVGbot />; }
    case 'buttons': { return <SVGbuttons />; }
    case 'calendar': { return <SVGcalendar />; }
    case 'cards': { return <SVGcards />; }
    case 'category': { return <SVGcategory />; }
    case 'change': { return <SVGchange />; }
    case 'channels': { return <SVGchannels />; }
    case 'chat': { return <SVGchat />; }
    case 'clock': { return <SVGclock />; }
    case 'components': { return <SVGcomponents />; }
    case 'conversion': { return <SVGconversion />; }
    case 'copy': { return <SVGcopy />; }
    case 'debug': { return <SVGdebug />; }
    case 'delete': { return <SVGdeletes />; }
    case 'desk': { return <SVGdesk />; }
    case 'desktop': { return <SVGdesktop />; }
    case 'disconect': { return <SVGdisconect />; }
    case 'divider': { return <SVGdivider />; }
    case 'dropdown-down-single': { return <SVGdropdownDownSingle />; }
    case 'dropdown-up-single': { return <SVGdropdownUpSingle />; }
    case 'dropdown': { return <SVGdropdown />; }
    case 'edit': { return <SVGedit />; }
    case 'email': { return <SVGemail />; }
    case 'emoji': { return <SVGemoji />; }
    case 'entity': { return <SVGentity />; }
    case 'error': { return <SVGerror />; }
    case 'export': { return <SVGexports />; }
    case 'filter': { return <SVGfilter />; }
    case 'flow': { return <SVGflow />; }
    case 'folder': { return <SVGfolder />; }
    case 'forward': { return <SVGforward />; }
    case 'home': { return <SVGhome />; }
    case 'images': { return <SVGimages />; }
    case 'information': { return <SVGinformation />; }
    case 'intent': { return <SVGintent />; }
    case 'launch': { return <SVGlaunch />; }
    case 'line-copy': { return <SVGlineCopy />; }
    case 'line': { return <SVGline />; }
    case 'list': { return <SVGlist />; }
    case 'location': { return <SVGlocation />; }
    case 'messenger': { return <SVGmessenger />; }
    case 'minimize': { return <SVGminimize />; }
    case 'no-chat': { return <SVGnoChat />; }
    case 'notification': { return <SVGnotification />; }
    case 'ok': { return <SVGok />; }
    case 'options': { return <SVGoptions />; }
    case 'paint-bucket': { return <SVGpaintBucket />; }
    case 'password': { return <SVGpassword />; }
    case 'payment': { return <SVGpayment />; }
    case 'phone': { return <SVGphone />; }
    case 'plus': { return <SVGplus />; }
    case 'profile': { return <SVGprofile />; }
    case 'publish': { return <SVGpublish />; }
    case 'quick-reply': { return <SVGquickReply />; }
    case 'refresh': { return <SVGrefresh />; }
    case 'restart': { return <SVGrestart />; }
    case 'search': { return <SVGsearch />; }
    case 'see_transcript': { return <SVGsee_transcript />; }
    case 'send': { return <SVGsend />; }
    case 'settings': { return <SVGsettings />; }
    case 'slack': { return <SVGslack />; }
    case 'workplace' : { return <SVGworkplace />; }
    case 'smartphone': { return <SVGsmartphone />; }
    case 'success': { return <SVGsuccess />; }
    case 'suggestions': { return <SVGsuggestions />; }
    case 'tablet': { return <SVGtablet />; }
    case 'teams': { return <SVGteams />; }
    case 'telegram': { return <SVGtelegram />; }
    case 'tool-fix': { return <SVGtoolFix />; }
    case 'transfer': { return <SVGtransfer />; }
    case 'trash': { return <SVGtrash />; }
    case 'variable': { return <SVGvariable />; }
    case 'visible': { return <SVGvisible />; }
    case 'web': { return <SVGweb />; }
    case 'whatsapp': { return <SVGwhatsapp />; }
    case 'arrow_down': { return <SVGarrowDown />; }
  }
}
