import React from "react";
import { connect, FormikContext } from "formik";

type EffectProps = {
  ["formik"]: FormikContext<{}>;
  ["name"]: string;
  ["model"]: any;
};

class AsyncFillerEffect extends React.Component<EffectProps> {
  public componentWillUpdate = (nextProps: any) => {
    let formValue = this.props.formik.values as any;
    if (
      !formValue[this.props.name] &&
      nextProps.model !== formValue[this.props.name]
    ) {
      this.props.formik.setFieldValue(
        this.props.name as never,
        this.props.model,
        false
      );
    }
  };

  render() {
    return null;
  }
}

export default connect(AsyncFillerEffect);
