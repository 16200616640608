import React, { Component } from "react";
import "./mini-donut-chart.atom.scss";

interface NamaMiniDonutChartProps {
  ["current"]: number;
  ["maximum"]?: number;
  ["suffix"]?: string;
  ["show_as_percentage"]?: boolean;
}

class NamaMiniDonutChart extends Component {
  constructor(public props: NamaMiniDonutChartProps) {
    super(props);
    this.state = {
      currentValue: this.props.current
    };
  }

  static defaultProps = {
    maximum: 100,
    show_as_percentage: false,
    suffix: ""
  };

  private dashOffsetCircle = () => {
    let dashoffset = (
      130 * (this.props.current / this.props.maximum!) * -1 -
      130
    ).toString();
    return dashoffset;
  };

  private displayValue = () => {
    if (this.props.show_as_percentage) {
      let percentage = (
        (this.props.current / this.props.maximum!) *
        100
      ).toFixed();
      return `${percentage}%`;
    }
    return `${this.props.current}${this.props.suffix}`;
  };

  render() {
    return (
      <div className="nama-mini-donut-chart">
        <svg viewBox="0 0 45 45" version="1.1">
          <defs>
            <linearGradient
              x1="11.291492%"
              y1="-11.5538207%"
              x2="105.436198%"
              y2="82.8328451%"
              id="linearGradient-1"
            >
              <stop stopColor="#41A2F0" offset="0%" />
              <stop stopColor="#91F2E5" offset="100%" />
            </linearGradient>
          </defs>
          <circle
            className="nama-mini-donut-chart-inner-circle"
            cx="22.5"
            cy="22.5"
            r="20.5"
            stroke="#EEF0F3"
            strokeWidth="4"
          />
          <circle
            className="nama-mini-donut-chart-outer-circle"
            cx="22.5"
            cy="22.5"
            r="20.5"
            stroke="url(#linearGradient-1)"
            strokeLinecap="round"
            strokeWidth="4px"
            strokeDashoffset={this.dashOffsetCircle()}
          />
        </svg>
        <div className="nama-mini-donut-chart-current-value">
          {this.displayValue()}
        </div>
      </div>
    );
  }
}

export default NamaMiniDonutChart;
