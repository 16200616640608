import { combineReducers } from "redux";
import { OrganizationsReducer } from "./organizations.reducer";
import { UserReducer } from "./user.reducer";
import { MembersReducer } from "./members.reducer";
import { TeamsReducer } from "./teams.reducer";
import { AssistantsReducer } from "./assistants.reducer";
import { ChannelsReducer } from "./channels.reducer";
import { FacebookReducer } from "./facebook.reducer";
import { ChatsReducer } from "./chats.reducer";
import { InsightsReducer } from './insights.reducer';

export const DashboardReducer = combineReducers({
  organizations: OrganizationsReducer as any,
  user: UserReducer as any,
  members: MembersReducer as any,
  teams: TeamsReducer as any,
  assistants: AssistantsReducer as any,
  channels: ChannelsReducer as any,
  facebook: FacebookReducer as any,
  chats: ChatsReducer as any,
  insights: InsightsReducer as any,
});
