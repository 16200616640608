import * as React from "react";
import WidgetOptions from "@purusha/models/options.widget.model";
export interface WidgetWrapperComponentProps {
  options: WidgetOptions;
}
export interface WidgetWrapperComponentState {}

class WidgetWrapperComponent extends React.Component<
  WidgetWrapperComponentProps,
  WidgetWrapperComponentState
> {
  public snnipet: any;
  public widget: any;
  public onChangeDebounce: any;
  constructor(public props: WidgetWrapperComponentProps) {
    super(props);
    this.snnipet = document.querySelector("body");

    if (this.props.options.apiToken !== "") {
      this.renderWidget(this.props.options);
    }
  }

  public componentWillUnmount = () => {
    if (this.widget) {
      this.widget.remove();
    }
  };

  public componentWillUpdate = (next: any) => {
    if (
      this.props.options != next.options &&
      typeof next.options.apiToken !== "undefined" &&
      next.options.apiToken != ""
    ) {
      this.renderWidget(next.options);
    }
  };

  public renderWidget = (options: WidgetOptions | undefined) => {
    options = options ? options : this.props.options;
    if (this.snnipet) {
      if (this.widget) {
        this.widget.remove();
      }
      if (options.onboarding) {
        options.onboarding = {
          ...options.onboarding,
          metadata: {}
        };
      }
      this.widget = new (window as any).NamaWidget(options);
      this.snnipet.appendChild(this.widget);
    }
  };

  public render() {
    return "";
  }
}

export default WidgetWrapperComponent;
