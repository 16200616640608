import React from "react";
import { Formik, Form } from "formik";
import { Translate } from "ns-react-i18next";
import { OrganizationModel } from "@purusha/purusha";
import { NamaInput, NamaButton } from "@prakriti/prakriti";

export interface NamaOrganizationFormProps {
  ["onSubmit"]: any;
  ["onCancel"]: any;
  ["initial"]: OrganizationModel;
}

class NamaOrganizationFormApp extends React.Component {
  public formik: any;

  constructor(public props: NamaOrganizationFormProps) {
    super(props);
    this.formik = React.createRef();
    this.description = this.description.bind(this);
    this.submit = this.submit.bind(this);
  }

  public description() {
    if (this.props.initial.id === 0) {
      return (
        <div>
          <h3>
            <Translate> organization_create_title </Translate>
          </h3>
          <p>
            <Translate> organization_create_descript </Translate>
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <h3>
            <Translate> organization_edit_title </Translate>
          </h3>
          <p>
            <Translate> organization_edit_descript </Translate>
          </p>
        </div>
      );
    }
  }

  public submit(values: any) {
    this.props.onSubmit(values);
    let formik = this.formik.current;
    if (formik) {
      formik.resetForm();
    }
  }

  public render() {
    return (
      <div>
        {this.description()}
        <Formik
          ref={this.formik}
          initialValues={this.props.initial}
          enableReinitialize={true}
          onSubmit={this.submit}
        >
          <Form className="nama-form">
            <NamaInput type="text" name="name" label="Description" />
            <div className="actions">
              <button type="submit" className="nama-button-old primary">
                {this.props.initial.id === 0 ? (
                  <Translate> create </Translate>
                ) : (
                  <Translate> update </Translate>
                )}
              </button>
              <NamaButton onClick={this.props.onCancel} className="clear">
                <Translate> cancel </Translate>
              </NamaButton>
            </div>
          </Form>
        </Formik>
      </div>
    );
  }
}

export default NamaOrganizationFormApp;
