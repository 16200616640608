import axios from "axios";
import { AxiosPromise } from "axios";
import { ChatStatsModel } from "@purusha/purusha";
import { host_url } from "@purusha/config";
import { IDateRange, DropdownItem } from "nama-design";

export default class InsightsService {
  public static base_url: string = `${host_url}/api/v1`;

  public static FETCH_CHAT_STATS(organization_id: number, options?: { dateFilter?: IDateRange, channelFilter?: Array<DropdownItem> }): AxiosPromise<ChatStatsModel> {
    let url: string = `${this.base_url}/organizations/${organization_id}/messages_summary?`;
    if (options) {
      const { dateFilter, channelFilter } = options;
      if (dateFilter) {
        url = `${url}by_date_begin=${(new Date(dateFilter.from)).toISOString()}&by_date_end=${(new Date(dateFilter.to)).toISOString()}&`
      }
      if (channelFilter) {
        url = `${url}channel_tokens=${channelFilter.map(channel => channel.value).join(',')}`;
      }
    }

    return axios.get(url);
  }
}
