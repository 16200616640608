import * as React from "react";
import './day-divider.scss';
import moment from "moment";

export interface DayDividerComponentProps {
  ["date"]: Date;
}

class DayDividerComponent extends React.Component<
  DayDividerComponentProps
  > {
  constructor(
    public props: DayDividerComponentProps
  ) {
    super(props);
  }

  public render() {
    const { date } = this.props;
    return (
      <div className="day-divider">
        <p>
          {moment(date).format("dddd, D [de] MMMM")}
        </p>
      </div>
    );
  }
}

export default DayDividerComponent;