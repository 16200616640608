import React from "react";
import * as Yup from "yup";
import { host_url } from "@purusha/config";

import { NamaButton, NamaInput, NamaIcon } from "@prakriti/prakriti";
import { Translate } from "ns-react-i18next";
import { Formik, Form, ErrorMessage } from "formik";
import ConversableComponent from "../conversable.component";
import { ConversableModel } from "@purusha/purusha";

import { ChannelModel } from "@purusha/purusha";

export interface WhatsAppChannelComponentProps {
  ["channel"]?: ChannelModel;
  ["cancel"]: Function;
  ["submit"]: Function;
}

export interface WhatsAppChannelConfig {
  ["infobip_user"]: string
  ["infobip_password"]: string
  ["infobip_scenario_key"]: string
  ["infobip_bot_key"]: string
  ["infobip_token"]?: string
}

export interface WhatsAppChannelComponentState {
  ["conversable"]?: ConversableModel;
  ["config"]?: WhatsAppChannelConfig;
}

class WhatsAppChannelComponent extends React.Component<
  WhatsAppChannelComponentProps,
  WhatsAppChannelComponentState
  > {
  public formik: any;
  public initial: any;
  public state: WhatsAppChannelComponentState;
  constructor(public props: WhatsAppChannelComponentProps) {
    super(props);
    this.formik = React.createRef();

    this.state = {};

    if (this.props.channel && this.props.channel.conversable) {
      this.state = {
        ...this.state,
        conversable: this.props.channel.conversable
      };
    }

    if (this.props.channel && this.props.channel.config) {
      let config = {
        ...this.props.channel.config
      };
      this.state = {
        ...this.state,
        config
      };
    }
  }

  public parseInitialChannel(channel?: ChannelModel) {
    const config = channel && channel.config ? channel.config : {}
    return {
      ...channel,
      ...config
    }
  }

  public componentWillUpdate = (next: WhatsAppChannelComponentProps) => {
    if (
      this.props.channel != next.channel &&
      next.channel &&
      next.channel.config
    ) {
      this.setState({
        config: next.channel.config
      });
    }
  };

  public submit = () => {

    if (this.formik.current) {
      if (this.state.conversable) {
        const formValues = this.formik.current.state.values;
        const channel: ChannelModel = {
          ...this.props.channel,
          title: formValues.title,
          conversable: this.state.conversable,
          whatsapp_number: formValues.whatsapp_number,
          config: JSON.stringify({
            infobip_user: formValues.infobip_user,
            infobip_password: formValues.infobip_password,
            infobip_scenario_key: formValues.infobip_scenario_key,
            infobip_bot_key: formValues.infobip_bot_key,
          })
        }

        this.props.submit(channel, "infobip")
      }
    }
  };

  public changeConversable = (conversable: ConversableModel) => {
    this.setState({
      conversable: conversable
    });
  };

  public render() {
    let WhatsAppChannelSchema = Yup.object().shape({
      title: Yup.string().required("validation_required"),
      infobip_user: Yup.string().required("validation_required"),
      infobip_password: Yup.string().required("validation_required"),
      infobip_scenario_key: Yup.string().when('infobip_bot_key', {
        is: val => !val,
        then: Yup.string().required("validation_required"),
        otherwise: Yup.string()
      }),      
      infobip_bot_key: Yup.string().when('infobip_scenario_key', {
        is: val => !val,
        then: Yup.string().required("validation_required"),
        otherwise: Yup.string()
      }),      
      whatsapp_number: Yup.string().required("validation_required"),
      conversable: Yup.object().required("validation_required"),
    }, [['infobip_bot_key', 'infobip_scenario_key']]);

    return (
      <div>
        <p>Fill the form below to set up your widget.</p>
        <p>
          You can check how it will look like by interacting with the widget on
          this page.
        </p>

        <ConversableComponent
          conversable={this.state.conversable}
          change={this.changeConversable}
          name="conversable"
        />

        <Formik
          ref={this.formik}
          validationSchema={WhatsAppChannelSchema}
          initialValues={this.parseInitialChannel(this.props.channel)}
          enableReinitialize={true}
          onSubmit={this.submit}
          render={(props: any) => (
            <Form className="nama-form">
              <NamaInput label="Channel`s Title" name="title" type="text" />
              <NamaInput label="InfoBip User" name="infobip_user" type="text" />
              <NamaInput label="InfoBip Password" name="infobip_password" type="password" />
              <NamaInput label="InfoBip Scenario Key" name="infobip_scenario_key" type="text" />
              <NamaInput label="InfoBip Bot Key" name="infobip_bot_key" type="text" />
              <NamaInput label="WhatsApp Number" name="whatsapp_number" type="text" />

              <ErrorMessage
                name="conversable"
                render={msg => (
                  <div className="field-error-message">
                    <Translate>{msg}</Translate>
                  </div>
                )}
              />
            </Form>
          )}
        />
        <Form className="nama-form">
          <div className="actions" style={{ width: "100%", float: "left" }}>
            <NamaButton
              className="nama-button-old primary"
              onClick={() => {
                this.submit();
              }}
            >
              {this.props.channel ? "Update" : "Save"}
            </NamaButton>
            <NamaButton className="clear" onClick={this.props.cancel}>
              Cancel
            </NamaButton>
          </div>

        </Form>
        {
          this.props.channel && this.props.channel.token &&
          <section className="nama-destacked-content" style={{ marginTop: 32 }}>
            <div>
              <p>Your channel is ready, send this URL and this token to infobip</p>

              {
                this.props.channel.config.infobip_scenario_key && (
                  <div className="nama-copy-value">
                    <NamaIcon icon="copy" />
                    URL: {host_url}/optin/infobip/{this.props.channel.whatsapp_number}
                  </div>
                )
              }
              {
                this.props.channel.config.infobip_bot_key && (
                  <div className="nama-copy-value">
                    <NamaIcon icon="copy" />
                    URL: {host_url}/optin/infobip_conversation/{this.props.channel.whatsapp_number}
                  </div>
                )
              } 
              <div className="nama-copy-value">
                <NamaIcon icon="copy" />
                TOKEN: 'Authorization: Token {this.props.channel.token}'
              </div>

            </div>
          </section>
        }
      </div>
    );
  }
}

export default WhatsAppChannelComponent;




