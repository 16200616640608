import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  InsightsActions,
  DashboardModel,
} from "@purusha/purusha";
import {
  NamaInsightsOverviewLayout
} from "@prakriti/prakriti";

const mapStateToProps = (state: DashboardModel) => {
  const {
    chatStats,
    dateFilter,
    channelFilter,
  } = state.insights;

  const {
    selected: selectedOrganization,
  } = state.organizations;

  return {
    organization: selectedOrganization,
    chatStats,
    dateFilter,
    channelFilter,
  };
}
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ ...InsightsActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NamaInsightsOverviewLayout);
