import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  OrganizationsActions,
  DashboardModel,
  OrganizationsModel
} from "@purusha/purusha";
import { NamaTabsTemplate } from "@prakriti/prakriti";
import informationComponent from "./information.component";
import settingsComponent from "./settings.component";
export interface OrganizationManagerProps {
  getDomains: Function;
}
export interface OrganizationManagerState {
  organizations: OrganizationsModel;
}
class OrganizationManagerApp extends React.Component<
  OrganizationManagerProps,
  OrganizationManagerState
> {
  constructor(
    public props: OrganizationManagerProps,
    public state: OrganizationManagerState
  ) {
    super(props);
  }

  public render() {
    return (
      <NamaTabsTemplate
        tabs={[
          {
            component: informationComponent,
            title: "Information"
          },
          {
            component: settingsComponent,
            title: "Settings"
          }
        ]}
      />
    );
  }
}

const mapStateToProps = (state: DashboardModel) => ({
  organizations: state.organizations
});
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(OrganizationsActions, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationManagerApp);
