import React, { Component } from "react";
import "./button.atom.scss";
import { boolean } from "yup";

export interface NamaButtonProps {
  ["onClick"]?: Function;
  ["className"]?: string;
  ["children"]?: any;
  ["disabled"]?: boolean;
  ["style"]?: any;
}

class NamaButton extends Component<NamaButtonProps> {
  render() {
    return (
      <a
        style={this.props.style}
        className={`nama-button-old ${this.props.className} ${this.props.disabled &&
          "disabled"}`}
        onClick={e => {
          e.preventDefault();
          if (!this.props.disabled && this.props.onClick) {
            this.props.onClick(e);
          }
        }}
      >
        {this.props.children}
      </a>
    );
  }
}

export default NamaButton;
