import * as React from "react";
import { Card as NamaCard, RadialChart } from "nama-design";
import numbro from "numbro";

const numberFormat = {
  average: true,
  optionalMantissa: true,
  mantissa: 1,
}

interface IReceivedMessagesCard {
  total: number,
  aiml_direct_response: number,
  ai: {
    over_confidence: number,
    under_confidence: number,
  }
}
class ReceivedMessagesCardOrganism extends React.Component<
  IReceivedMessagesCard
  > {
  constructor(
    public props: IReceivedMessagesCard
  ) {
    super(props);
  }

  public render() {
    const { ai, aiml_direct_response, total } = this.props;

    return (
      <NamaCard
        header={{
          label: "Mensagens recebidas",
          iconName: "Flow",
          iconColor: "purple-300"
        }}

        items={
          [
            {
              label: "Interpretadas pelo AIML",
              value: numbro(aiml_direct_response).format(numberFormat),
              color: "blue-300"
            },
            {
              label: "Interpretadas pela IA",
              value: numbro(ai.over_confidence).format(numberFormat),
              color: "green-300"
            },
            {
              label: "Não entendidas",
              value: numbro(ai.under_confidence).format(numberFormat),
              color: "red-300"
            },
          ]
        }

      >
        {
          total > 0 &&
          <RadialChart dataSet={[
            [
              { name: "underConfidence", value: ai.under_confidence, color: "#F2115A" },
              { name: "overConfidence", value: ai.over_confidence, color: "#1EBDA4" },
              { name: "AIML", value: aiml_direct_response, color: "#2D67FB" }
            ]
          ]}
          />
        }
      </NamaCard>
    );
  }
}

export default ReceivedMessagesCardOrganism;