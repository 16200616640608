import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Translate } from "ns-react-i18next";

import {
  MemberActions,
  MembersModel,
  MemberModel,
  MemberInitialState,
  DashboardModel,
  OrganizationModel
} from "@purusha/purusha";
import {
  NamaTable,
  NamaDisplay,
  NamaButton,
  NamaIcon,
  NamaModal,
  NamaConfirmation
} from "@prakriti/prakriti";
import NamaMemberFormApp from "./member.form";

export interface MembersComponentProps {
  ["members"]: MembersModel;
  ["organization"]?: OrganizationModel;
  ["fetchMembers"]: any;
  ["createMember"]: any;
  ["openMemberModal"]: any;
  ["closeMemberModal"]: any;
  ["updateMember"]: any;
  ["discardMember"]: any;
  ["selectMember"]: any;
}

export interface MembersComponentState {
  ["member"]: MemberModel;
}

class MembersComponent extends React.Component<
  MembersComponentProps,
  MembersComponentState
> {
  public actions: Array<any>;
  public header: Array<any>;
  public confirmation = React.createRef<NamaConfirmation>();
  constructor(
    public props: MembersComponentProps,
    public state: MembersComponentState
  ) {
    super(props);
    this.state = {
      member: MemberInitialState
    };
    this.actions = [
      {
        name: "edit",
        icon: "edit",
        onClick: (e: any) => {
          this.select(e.detail);
          this.props.openMemberModal();
        }
      },
      {
        name: "delete",
        icon: "trash",
        onClick: (e: any) => {
          if (this.confirmation.current) {
            this.select(e.detail);
            this.confirmation.current.open(e.detail);
          }
        }
      }
    ];
    this.header = [
      {
        key: "name",
        value: "name",
        direction: "left"
      },
      {
        key: "email",
        value: "email",
        direction: "left"
      },
      {
        key: "role",
        value: "role",
        direction: "left"
      },
      {
        key: "team",
        type: "tag",
        value: "Teams",
        direction: "left"
      }
    ];
  }

  public componentWillMount = () => {
    if (this.props.organization) {
      this.props.fetchMembers(this.props.organization.id);
    }
  };

  public closeModal = () => {
    this.props.closeMemberModal();
    if (this.props.organization) {
      this.props.fetchMembers(this.props.organization.id);
    }
  };

  public openModal = () => {
    this.setState({
      member: MemberInitialState
    });
    this.props.openMemberModal();
  };

  public delete = (e: any) => {
    if (this.props.organization) {
      this.props.discardMember(this.props.organization.id, e.id);
    }
  };

  public select(item: any) {
    this.setState({
      member: item
    });
  }

  public submit = (e: MemberModel) => {
    if (this.props.organization) {
      if (e.id == 0) {
        this.props.createMember(this.props.organization.id, e);
      } else {
        this.props.updateMember(this.props.organization.id, e);
      }
    }
  };

  public render() {
    return (
      <section className="nama-content center">
        <NamaButton onClick={this.openModal} className="primary icon left full">
          <NamaIcon icon="profile" />
          <span>
            <Translate>invite</Translate>
          </span>
        </NamaButton>
        <NamaDisplay title="members" type="card">
          <NamaTable
            header={this.header}
            actions={this.actions}
            data={this.props.members.list}
          />
        </NamaDisplay>
        <NamaModal
          isOpen={this.props.members.showModal}
          onDiscardByUser={this.closeModal}
        >
          <NamaMemberFormApp
            isOpen={this.props.members.showModal}
            errors={this.props.members.errors}
            initial={this.state.member}
            onSubmit={this.submit}
            onCancel={this.closeModal}
          />
        </NamaModal>
        <NamaConfirmation
          onSuccess={this.delete}
          ref={this.confirmation}
          confirmation={this.state.member.name || "-"}
        >
          <div>
            <h3>
              <Translate> member_delete_title </Translate>
            </h3>
            <p>
              <Translate> member_delete_descript </Translate>
            </p>
          </div>
        </NamaConfirmation>
      </section>
    );
  }
}

const mapStateToProps = (state: DashboardModel) => ({
  members: state.members,
  organization: state.organizations.selected
});
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(MemberActions, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersComponent);
