import { ChannelsModel } from "./channels.model";

export const ChannelsInitialState: ChannelsModel = {
  selected_type: {
    key: "empty",
    title: "empty",
    icon: "empty"
  },
  list: {
    flows: [],
    teams: []
  }
};
