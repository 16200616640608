import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  ChannelsActions,
  DashboardModel,
  OrganizationModel,
  TeamsAction,
  ChannelsModel
} from "@purusha/purusha";
import { NamaButton, NamaIcon, NamaNavigationLayout } from "@prakriti/prakriti";
import { ReactComponent as SVGChannelsNew } from "../../../prakriti/assets/arts/channels-art.svg";
import ChannelComponent from "./channel.component";
import WebChannelComponent from "./web/web.channel";
import WhatsAppChannelComponent from "./whatsapp/whatsapp.channel";
import FacebookChannelComponent from "./facebook/facebook.channel";
import { ChannelModel } from "@purusha/models/channel.model";
import SlackChannelComponent from "./slack/slack.channel";
import WorkplaceChannelComponent from "./workplace/workplace.channel";
export interface ChannelsProps {
  ["channels"]: ChannelsModel | any;
  ["organization"]?: OrganizationModel;
  ["selectAction"]: Function;
  ["discardChannel"]: Function;
  ["fetchChannels"]: Function;
  ["createChannel"]: Function;
  ["selectChannel"]: Function;
  ["updateChannel"]: Function;
  ["selectChannelType"]: Function;
  ["resetChannel"]: Function;
  ["unselect"]: Function;
}

export interface ChannelTypeInterface {
  ["title"]: string;
  ["key"]: string;
  ["icon"]: string;
}

export interface ChannelsState { }

class ChannelsApp extends React.Component<ChannelsProps, ChannelsState> {
  constructor(public props: ChannelsProps, public state: ChannelsState) {
    super(props);
  }

  public selectChannelType = (e: string, action?: string) => {
    this.props.selectAction(action);
    this.props.selectChannelType(e);
  };

  public select = (channel: ChannelModel) => {
    this.props.selectChannel(channel);
    this.props.selectAction("form");
  };

  public reset = (channel: ChannelModel) => {
    if (this.props.organization) {
      this.props.resetChannel(this.props.organization.id, channel.token);
    }
  };

  public submitChannel = (channel: ChannelModel, type: String = "web") => {
    if (this.props.organization) {
      if (channel.id) {
        this.props.updateChannel(this.props.organization.id, type, channel);
      } else {
        this.props.createChannel(this.props.organization.id, type, channel);
      }
    }
  };

  public discard = (channel: ChannelModel) => {
    if (this.props.organization) {
      this.props.discardChannel(this.props.organization.id, channel.id);
    }
  };

  public fetchChannels = () => {
    if (
      this.props.organization &&
      this.props.channels &&
      this.props.channels.selected_type
    ) {
      this.props.fetchChannels(
        this.props.organization.id,
        this.props.channels.selected_type.key
      );
    }
  };

  public componentWillMount = () => {
    this.fetchChannels();
  };

  public componentDidUpdate = (old: any) => {
    if (old.channels.selected_type !== this.props.channels.selected_type) {
      this.fetchChannels();
    }
  };

  // VIEWS

  public nav = () => {
    return (
      <div className="nama-button-old-list">
        {/* WEB */}
        <ChannelTypeSelector
          selected={this.props.channels.selected_type}
          channelType={{ key: "web", icon: "web", title: "Widget Web" }}
          select={this.selectChannelType}
        />
        {/* FACEBOOK MESSENGER */}
        <ChannelTypeSelector
          selected={this.props.channels.selected_type}
          channelType={{
            key: "facebook",
            icon: "messenger",
            title: "Facebook Messenger"
          }}
          select={this.selectChannelType}
        />

        {/* WhatsApp */}
        <ChannelTypeSelector
          selected={this.props.channels.selected_type}
          channelType={{ key: "infobip", icon: "whatsapp", title: "WhatsApp" }}
          select={this.selectChannelType}
        />
        
        {/* SLACK */}
        <ChannelTypeSelector
          selected={this.props.channels.selected_type}
          channelType={{ key: "slack", icon:"slack", title: "Slack" }}
          select={this.selectChannelType}
        />

        {/* WORKPLACE */}
        <ChannelTypeSelector
          selected={this.props.channels.selected_type}
          channelType={{ key: "workplace", icon:"workplace", title: "Workplace" }}
          select={this.selectChannelType}
        />

        {/* TELEGRAM */}
        <ChannelTypeSelector
          selected={this.props.channels.selected_type}
          channelType={{ key: "telegram", icon: "telegram", title: "Telegram" }}
          select={this.selectChannelType}
        />
        {/* DESK ASSISTANT */}
        <ChannelTypeSelector
          selected={this.props.channels.selected_type}
          channelType={{ key: "desk", icon: "desk", title: "Desk Assistant" }}
          select={this.selectChannelType}
        />
        {/* SUGGESTIONS */}
        <ChannelTypeSelector
          selected={this.props.channels.selected_type}
          channelType={{
            key: "suggestions",
            icon: "suggestions",
            title: "Suggestions"
          }}
          select={this.selectChannelType}
        />
      </div>
    );
  };

  public channelTemplate = (key: string) => {
    switch (key) {
      case "facebook":
        return (
          <FacebookChannelComponent
            submit={(channel: any) => this.submitChannel(channel, "facebook")}
            channel={this.props.channels.selected_type}
            publish={this.props.channels.selected_channel}
            cancel={() => this.props.selectAction(undefined)}
          />
        );
      case "web":
        return (
          <WebChannelComponent
            submit={this.submitChannel}
            channel={this.props.channels.selected_channel}
            cancel={() => this.props.selectAction(undefined)}
          />
        );
      case "slack":
        return (
          <SlackChannelComponent
            submit={this.submitChannel}
            channel={this.props.channels.selected_channel}
            cancel={() => this.props.selectAction(undefined)}
          />
        );
      case "workplace":
        return (
          <WorkplaceChannelComponent
            submit={this.submitChannel}
            channel={this.props.channels.selected_channel}
            cancel={() => this.props.selectAction(undefined)}
          />
        );
      case "infobip":
        return (
          <WhatsAppChannelComponent
            submit={this.submitChannel}
            channel={this.props.channels.selected_channel}
            cancel={() => this.props.selectAction(undefined)}
          />
        );
    }
  };

  public empty = () => {
    return (
      <section className="nama-content small" style={{ textAlign: "center" }}>
        <SVGChannelsNew />
      </section>
    );
  };

  public content = () => {
    return (
      <section
        className={`nama-content ${this.props.channels.action === "form" &&
          "micro"}`}
      >
        {typeof this.props.channels.selected_type == "undefined" ||
          this.props.channels.selected_type.key == "empty" ? (
            this.empty()
          ) : this.props.channels.selected_type &&
            this.props.channels.action === "form" ? (
              this.channelTemplate(this.props.channels.selected_type.key)
            ) : (
              <ChannelComponent
                new={() => {
                  this.props.unselect();
                  this.props.selectAction("form");
                }}
                channel_type={this.props.channels.selected_type}
                discard={this.discard}
                select={this.select}
                reset={this.reset}
                teams={this.props.channels.list && this.props.channels.list.teams}
                flows={this.props.channels.list && this.props.channels.list.flows}
              />
            )}
      </section>
    );
  };

  public render() {
    return <NamaNavigationLayout nav={this.nav()} content={this.content()} />;
  }
}

class ChannelTypeSelector extends React.Component<{
  channelType: ChannelTypeInterface;
  select: Function;
  selected?: ChannelTypeInterface;
}> {
  public render() {
    let classNameList = "huge white avatar icon";
    return (
      <NamaButton
        className={
          this.props.selected &&
            this.props.selected.key === this.props.channelType.key
            ? `${classNameList} active`
            : classNameList
        }
        onClick={() => this.props.select(this.props.channelType)}
      >
        <NamaIcon icon={this.props.channelType.icon} />
        <span>{this.props.channelType.title}</span>
        <NamaIcon icon="next" />
      </NamaButton>
    );
  }
}

const mapStateToProps = (state: DashboardModel) => ({
  channels: state.channels,
  organization: state.organizations.selected
});
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...ChannelsActions
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelsApp);
