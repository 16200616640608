import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { InsightsActions, DashboardModel, ChannelModel, OrganizationModel } from "@purusha/purusha";
import { NamaTabsTemplate, ChannelSelect } from "@prakriti/prakriti";
import ConversationsComponent from "./conversations.component";
import OverviewComponent from "./overview.component";
import { DateRangePicker, IDateRange, DropdownItem } from "nama-design";

export interface InsightsProps {
  ["organization"]?: OrganizationModel;
  ["setDateFilter"]: any;
  ["dateFilter"]: IDateRange;
  ["fetchChannels"]: any;
  ["channelList"]: Array<ChannelModel>;
  ["channelFilter"]: Array<DropdownItem>;
  ["setChannelFilter"]: any;
}

const channelIcons: { [key: string]: string } = {
  web: "Web",
  infobip: "Whatsapp",
  facebook: "Messenger",
  slack: "Slack",
  workplace: "Workplace"
}

const channelToDropdownItem = (channels: Array<ChannelModel>): Array<DropdownItem> => channels.map(
  (channel): DropdownItem => ({
    icon: channel.channel_type ? channelIcons[channel.channel_type] : undefined,
    label: channel.title,
    value: channel.token,
  })
);

export interface InsightsState { }
class InsightsApp extends React.Component<InsightsProps, InsightsState> {
  constructor(public props: InsightsProps, public state: InsightsState) {
    super(props);
  }

  componentDidMount() {
    const { fetchChannels, organization } = this.props;
    organization &&
      fetchChannels(organization.id);
  }

  componentDidUpdate() {
    const { channelFilter, setChannelFilter, channelList } = this.props;
    if (channelFilter.length === 0 && channelList.length !== 0) {
      setChannelFilter(channelToDropdownItem(channelList));
    }
  }

  public render() {
    const { setDateFilter, dateFilter, channelList, channelFilter, setChannelFilter } = this.props;
    return (
      <NamaTabsTemplate
        tabs={[
          {
            component: OverviewComponent,
            title: 'Visão geral'
          },
          {
            component: ConversationsComponent,
            title: 'Conversas'
          }
        ]}
        filters={[
          <ChannelSelect
            items={channelToDropdownItem(channelList)}
            selected={channelFilter}
            onSelect={setChannelFilter}
            key="channelSelect"
          />,
          <DateRangePicker
            onSelect={setDateFilter}
            dateRange={dateFilter}
            key="daterange"
          />
        ]}
      />
    );
  }
}

const mapStateToProps = (state: DashboardModel) => {
  const {
    channelFilter,
    channelList,
    dateFilter,
  } = state.insights;

  const {
    selected: selectedOrganization,
  } = state.organizations;

  return {
    dateFilter,
    channelFilter,
    channelList,
    organization: selectedOrganization,
  }
};
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ ...InsightsActions }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsightsApp);
