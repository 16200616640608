import { BotModel, BotsService, ChannelsService } from "@purusha/purusha";
import { ChannelModel } from "@purusha/models/channel.model";

export let createChannel = (
  organization_id: string,
  channel_type: string,
  channel: ChannelModel
) => {
  const request = ChannelsService.create(
    organization_id,
    channel_type,
    channel
  );
  return {
    type: "CREATE_CHANNEL",
    payload: request
  };
};

export let updateChannel = (
  organization_id: string,
  channel_type: string,
  channel: ChannelModel
) => {
  const request = ChannelsService.update(
    organization_id,
    channel_type,
    channel
  );
  return {
    type: "UPDATE_CHANNEL",
    payload: request
  };
};

export let fetchChannels = (organization_id: string, channel_type: string) => {
  switch(channel_type) {
    case 'facebook':
    case 'web':
    case 'infobip':
    case 'slack':
    case 'workplace':
        const request = ChannelsService.fetch(organization_id, channel_type);
        return {
          type: "FETCH_CHANNELS",
          payload: request
        };
    default:
      return {
        type: "ERROR_FETCH_CHANNELS"
      };      
  }
};

export let selectAction = (action: any) => {
  return {
    type: "SELECT_ACTION",
    payload: action
  };
};

export let selectChannelType = (channelType: any) => {
  return {
    type: "SELECT_CHANNEL_TYPE",
    payload: channelType
  };
};

export let selectChannel = (channel: any) => {
  return {
    type: "SELECT_CHANNEL",
    payload: channel
  };
};

export let resetChannel = (aorganization_id: string, channel_token: string) => {
  const request = ChannelsService.reset(aorganization_id, channel_token);
  return {
    type: "RESET_CHANNEL",
    payload: request
  };
};

export let discardChannel = (organization_id: string, channel_id: string) => {
  const request = ChannelsService.discard(organization_id, channel_id);
  return {
    type: "DISCARD_CHANNEL",
    payload: request
  };
};
export let unselect = () => {
  return {
    type: "UNSELECT_PUBLISHES"
  };
};
