import React, { Component } from "react";
import { NamaAvatar, NamaButton } from "@prakriti/prakriti";
import "./dashboard-header-menu.molecule.scss";
import { UserModel, OrganizationModel } from "@purusha/purusha";
import { NavLink } from "react-router-dom";

interface NamaDashboardHeaderMenuProps {
  user: UserModel;
  logout: Function;
  organization: OrganizationModel;
  unselectOrganizationFn: Function;
}

class NamaDashboardHeaderMenu extends Component {
  constructor(public props: NamaDashboardHeaderMenuProps) {
    super(props);
  }

  render() {
    return (
      <div className="nama-dashboard-header-menu">
        <div className="nama-dashboard-header-user-menu">
          <NamaAvatar
            url={this.props.user.avatar ? this.props.user.avatar.url : ""}
            name={this.props.user.name}
            size="small"
          />

          <div className="avatar-options">
            <NavLink to="/account" activeClassName="active">
              <span className="nama-button-old">Account Settings</span>
            </NavLink>
            <NamaButton onClick={this.props.logout}>Logout</NamaButton>
          </div>
        </div>

        {this.props.organization && (
          <div className="nama-dashboard-header-organization">
            <NamaButton
              className="clear"
              onClick={this.props.unselectOrganizationFn}
            >
              {this.props.organization.name}
            </NamaButton>
          </div>
        )}
      </div>
    );
  }
}

export default NamaDashboardHeaderMenu;
