import { OrganizationModel, OrganizationsService } from "@purusha/purusha";

export let createOrganization = (organization: OrganizationModel) => {
  const request = OrganizationsService.create(organization.name);
  return {
    type: "CREATE_ORGANIZATION",
    payload: request
  };
};

export let fetchInformation = (organization: OrganizationModel) => {
  const request = OrganizationsService.information(organization.id.toString());
  return {
    type: "FETCH_INFORMATION",
    payload: request
  };
};

export let updateOrganization = (organization: OrganizationModel) => {
  const request = OrganizationsService.update(
    organization.id.toString(),
    organization.name
  );
  return {
    type: "UPDATE_ORGANIZATION",
    payload: request
  };
};

export let deleteOrganization = (organization: OrganizationModel) => {
  const request = OrganizationsService.discard(organization.id.toString());
  return {
    type: "DELETE_ORGANIZATION",
    payload: request
  };
};

export let fetchOrganizations = () => {
  const request = OrganizationsService.available();
  return {
    type: "FETCH_ORGANIZATIONS",
    payload: request
  };
};

export let selectOrganization = (organization: OrganizationModel) => {
  return {
    type: "SELECT_ORGANIZATION",
    payload: organization
  };
};

export let unselectOrganization = () => {
  return {
    type: "UNSELECT_ORGANIZATION"
  };
};

export let deleteDomain = (
  organization: OrganizationModel,
  domain_id: string
) => {
  const request = OrganizationsService.deleteDomain(
    organization.id.toString(),
    domain_id
  );
  return {
    type: "DELETE_ORGANIZATION_DOMAIN",
    payload: request
  };
};

export let fetchDomain = (organization: OrganizationModel) => {
  const request = OrganizationsService.getDomains(organization.id.toString());
  return {
    type: "FETCH_ORGANIZATION_DOMAIN",
    payload: request
  };
};

export let addDomain = (organization: OrganizationModel, domain: string) => {
  const request = OrganizationsService.addDomain(
    organization.id.toString(),
    domain
  );
  return {
    type: "ADD_ORGANIZATION_DOMAIN",
    payload: request
  };
};

export let removeDomain = (organization: OrganizationModel, id: string) => {
  const request = OrganizationsService.deleteDomain(
    organization.id.toString(),
    id
  );
  return {
    type: "REMOVE_ORGANIZATION_DOMAIN",
    payload: request
  };
};
