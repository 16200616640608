import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { difference } from "lodash";
import { filter } from "lodash";

import { Translate } from "ns-react-i18next";
import {
  OrganizationsActions,
  DashboardModel,
  AsyncErrorEffect,
  OrganizationModel,
  OrganizationsModel
} from "@purusha/purusha";
import * as Yup from "yup";
import {
  NamaDisplay,
  NamaIcon,
  NamaAccordeon,
  NamaAvatar,
  NamaButton,
  NamaUploadAvatar,
  NamaInput,
  NamaTagInput
} from "@prakriti/prakriti";
import { Formik, Form } from "formik";
import { addDomain } from "../../actions/organizations.actions";

export interface SettingsFormProps {
  ["onSubmit"]: any;
  ["errors"]: any;
  ["initial"]: OrganizationModel;
  ["organizations"]?: OrganizationsModel;
  ["fetchDomain"]: Function;
  ["addDomain"]: Function;
  ["removeDomain"]: Function;
}

export interface SettingsFormState { }

class NamaSettingsForm extends React.Component<SettingsFormProps> {
  public formik: any;
  public userSchema: any;

  constructor(
    public props: SettingsFormProps,
    public state: SettingsFormState
  ) {
    super(props);
    this.state = {};
    this.formik = React.createRef();
    this.userSchema = Yup.object().shape({
      name: Yup.string()
        .min(2, "validation_short")
        .max(70, "validation_long")
        .required("validation_required")
    });
  }

  public componentWillMount = () => {
    if (this.props.organizations && this.props.organizations.selected) {
      this.props.fetchDomain(this.props.organizations.selected);
    }
  };

  public addDomain = (domains: Array<string>) => {
    if (this.props.organizations && this.props.organizations.selected) {
      this.props.addDomain(
        this.props.organizations.selected,
        domains[domains.length - 1]
      );
    }
  };

  public removeDomain = (domains: Array<string>) => {
    if (this.props.organizations && this.props.organizations.selected) {
      let registered_domains = this.props.organizations.domains.map(
        item => item.domain
      );
      let difference_domains = difference(registered_domains, domains);

      difference_domains.forEach(domain => {
        if (this.props.organizations) {
          let domain_item: any = filter(
            this.props.organizations.domains,
            a => a.domain == domain
          );
          this.props.removeDomain(
            this.props.organizations.selected,
            domain_item[0].id
          );
        }
      });
    }
  };

  public submit = (values: any) => {
    console.log(values);
  };

  private filterFunction = (tags: Array<any>) => {
    let domainRegex = new RegExp(
      /(http|https):\/\/([-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6})\b(?:[-a-zA-Z0-9@:%_\+.~#?&//=]*)/i
    );

    let filtered_tags = tags.filter(tag => {
      return domainRegex.test(tag);
    });
    let unique_tags = filtered_tags.filter((item, pos) => {
      return filtered_tags.indexOf(item) == pos;
    });
    return unique_tags;
  };

  public render() {
    return (
      this.props.organizations && (
        <div>
          {/* <Formik
            ref={this.formik}
            initialValues={this.props.initial}
            validationSchema={this.userSchema}
            enableReinitialize={true}
            onSubmit={this.submit}
            render={({ values }) => (
              <Form className="nama-form">
                <AsyncErrorEffect
                  formik={this.formik}
                  errors={this.props.errors}
                />
                <NamaUploadAvatar
                  name="avatarUrl"
                  title={this.props.initial.name}
                  label="avatar"
                  formik={this.formik.current}
                  // url={values.avatar && values.avatar.url}
                />
                <NamaInput type="text" name="name" label="name" />

                <div className="actions">
                  <button type="submit" className="nama-button-old primary">
                    <Translate> update </Translate>
                  </button>
                </div>
              </Form>
            )}
          /> */}
          <section
            className="nama-destacked-content"
            style={{ marginTop: "32px" }}
          >
            <div className="header">
              <NamaIcon icon="web" className="fill-blue-300" />
              <p>Domains whitelisted</p>
            </div>

            <NamaTagInput
              tags={this.props.organizations.domains.map(item => item.domain)}
              name="whitelist"
              onChange={(e: any) => {
                if (e.target.value) {
                  if (this.props.organizations) {
                    if (
                      this.props.organizations.domains.length >
                      e.target.value.length
                    ) {
                      this.removeDomain(e.target.value);
                    } else {
                      this.addDomain(e.target.value);
                    }
                  }
                }
              }}
              placeholder="Type and press 'enter' to add a Domain"
              hint="Widget will only be displayed in this domains"
              onlyUnique
              filterFunction={this.filterFunction}
            />
          </section>
        </div>
      )
    );
  }
}

const mapStateToProps = (state: DashboardModel) => ({
  organizations: state.organizations
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(OrganizationsActions, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NamaSettingsForm);
