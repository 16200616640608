import axios from "axios";
import { AxiosPromise } from "axios";
import { host_url } from "@purusha/config";
export default class FacebookService {
  public static base_url: string = `${host_url}/api/v1`

  public static fetch(): AxiosPromise<any> {
    const url: string = `${this.base_url}/current_user`;
    return axios.get(url);
  }

  public static fetch_pages(
    organization_id: string,
    access_token: string
  ): AxiosPromise {
    const url: string = `${this.base_url}/organizations/${organization_id}/facebook_pages?access_token=${access_token}`;
    return axios.get(url);
  }
}
