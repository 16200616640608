import React from "react";
import { connect, FormikContext } from "formik";

type EffectProps = {
  ["formik"]: FormikContext<{}>;
  ["isOpen"]: boolean;
};

class ModalFormCleanEffect extends React.Component<EffectProps> {
  public componentWillReceiveProps = (nextProps: any) => {
    if (nextProps.isOpen !== this.props.isOpen) {
      if (!nextProps.isOpen) {
        this.props.formik.resetForm();
      }
    }
  };

  render() {
    return null;
  }
}

export default connect(ModalFormCleanEffect);
