import * as React from "react";
import "./menu.organism.scss";
import { Link } from "react-router-dom";
import { NamaIcon } from "@prakriti/prakriti";
import { ReactComponent as SVGlogowhite } from "../../assets/logos/white.icon.svg";
interface NamaMenuOrganismProps {
  ["show"]: boolean;
}
class NamaMenuOrganism extends React.Component<NamaMenuOrganismProps> {
  public render() {
    return (
      <div id="nama-menu" className={this.props.show ? "show" : "hide"}>
        <div className="header">
          <div className="item">
            <Link to="/">
              <NamaIcon icon="nama" svg={<SVGlogowhite />} />
            </Link>
          </div>
        </div>
        <div className="content">{this.props.children}</div>
        <div className="footer" />
      </div>
    );
  }
}

export default NamaMenuOrganism;
