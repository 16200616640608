import React, { Component } from "react";
import "./tag.atom.scss";

export interface NamaTagProps {
  ["children"]: React.ReactChild;
}

class NamaTag extends Component<NamaTagProps> {
  render() {
    return <div className="nama-tag">{this.props.children}</div>;
  }
}

export default NamaTag;
