import {
  AssistantsInitialState,
  AssistantsModel,
  AssistantModel
} from "@purusha/purusha";

export function AssistantsReducer(
  state: AssistantsModel = AssistantsInitialState,
  action: any
) {
  switch (action.type) {
    case "SELECT_ASSISTANT":
      let selectedAssistant: any = undefined;
      if (action.payload.assistant !== "0") {
        selectedAssistant = state.list.filter((assistant: AssistantModel) => {
          return assistant.id == action.payload.assistant;
        })[0];
      }
      return {
        ...state,
        selected: selectedAssistant,
        action: action.payload.action
      };

    case "SELECT_ASSISTANT_BY_TOKEN":
      let selectedAssistantToken: any = undefined;
      if (action.payload.token !== "0") {
        selectedAssistantToken = state.list.filter(
          (assistant: AssistantModel) => {
            return assistant.token == action.payload.token;
          }
        )[0];
      }
      return {
        ...state,
        selected: selectedAssistantToken,
        action: action.payload.action
      };

    case "FETCH_ASSISTANTS":
      if (action.payload.data) {
        let list = action.payload.data.map((item: any) => {
          return {
            ...item,
            name: item.title || "",
            avatar: item.avatar ? item.avatar.url : ""
          };
        });
        return { ...state, list: list, selected: undefined };
      } else {
        return state;
      }

    case "CREATE_ASSISTANT":
      if (action.payload.status == 201) {
        let serverAssistant = {
          ...action.payload.data.assistant_project,
          name: action.payload.data.assistant_project.title || "",
          avatar: action.payload.data.assistant_project.avatar
            ? action.payload.data.assistant_project.avatar.url
            : ""
        };
        return {
          ...state,
          errors: undefined,
          action: undefined,
          selected: serverAssistant,
          list: [...state.list, serverAssistant]
        };
      } else if (action.payload.response.data.errors) {
        return {
          ...state,
          errors: action.payload.response.data.errors
        };
      } else {
        return state;
      }

    case "UPDATE_ASSISTANT":
      if (action.payload.status == 200) {
        let serverAssistant = {
          ...action.payload.data.assistant_project,
          name: action.payload.data.assistant_project.title || "",
          avatar: action.payload.data.assistant_project.avatar
            ? action.payload.data.assistant_project.avatar.url
            : ""
        };
        let updatedList: Array<AssistantModel> = state.list.map(
          (assistant: AssistantModel) => {
            if (assistant.id == serverAssistant.id) {
              return serverAssistant;
            } else {
              return assistant;
            }
          }
        );
        return {
          ...state,
          selected: serverAssistant,
          action: undefined,
          list: updatedList
        };
      } else {
        return state;
      }

    case "DISCARD_ASSISTANT":
      if (action.payload.status == 200) {
        let updatedList: Array<AssistantModel> = state.list.filter(
          (assistant: AssistantModel) => {
            return assistant.id != action.payload.data.assistant_project.id;
          }
        );
        return {
          ...state,
          selected: undefined,
          action: "empty",
          list: updatedList
        };
      } else {
        return state;
      }

    case "FETCH_FLOW_VERSIONS":
      if (action.payload.data) {
        let list = action.payload.data.map((item: any) => {
          return item;
        });
        return {
          ...state,
          selected: { ...state.selected, flow_versions: list }
        };
      } else {
        return state;
      }

    case "ADD_FLOW_VERSION":
      if (action.payload.status == 201) {
        let flow_versions = state.selected
          ? [
            action.payload.data,
            ...(state.selected.flow_versions
              ? state.selected.flow_versions
              : [])
          ]
          : [];

        return {
          ...state,
          selected: {
            ...state.selected,
            flow_versions: flow_versions
          }
        };
      } else if (action.payload.response.data.error) {
        // change to a proper alert
        alert(action.payload.response.data.error);
      } else {
        return state;
      }

    // case "DISCARD_ASSISTANT_MEMBERS":
    //   if (action.payload.status == 200) {
    //     let updatedList: Array<MemberModel> = state.selected
    //       ? state.selected.assistants
    //         ? state.selected.assistants.filter((assistant: MemberModel) => {
    //             return assistant.id != action.payload.data.user.id;
    //           })
    //         : []
    //       : [];
    //     return {
    //       ...state,
    //       selected: {
    //         ...state.selected,
    //         assistants: updatedList
    //       }
    //     };
    //   } else {
    //     return state;
    //   }

    default:
      return state;
  }
}
