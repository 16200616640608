import React, { Component } from "react";
import "./table.molecule.scss";
import { NamaButton, NamaIcon, NamaTag } from "@prakriti/prakriti";
import { Translate } from "ns-react-i18next";

// Data to table should be like this:
// [['val1', 'val2', 'val3'], ['val1', 'val2', 'val3']]

interface NamaTableProps {
  ["data"]: Array<any>;
  ["header"]: Array<NamaTableHeaderModel>;
  ["actions"]?: Array<NamaTableActionsProps>;
  ["color"]?: string;
  // ["onClick"]: Function;
}

export interface NamaTableItemModel {
  ["id"]: number;
  ["title"]: string;
  ["url"]?: string;
  ["label"]?: string;
}

interface NamaTableHeaderModel {
  ["key"]: string;
  ["value"]: string;
  ["type"]?: string;
  ["direction"]?: string;
}

interface NamaTableHeaderProps {
  ["header"]: Array<NamaTableHeaderModel>;
  ["color"]?: string;
  ["actions"]?: Array<NamaTableActionsProps>;
}

interface NamaTableItemProps {
  ["item"]: any;
  ["header"]: Array<NamaTableHeaderModel>;
  ["actions"]?: Array<NamaTableActionsProps>;
}

interface NamaTableActionsProps {
  ["icon"]: string;
  ["name"]: string;
  ["onClick"]: any;
}

class NamaTable extends Component<NamaTableProps> {
  render() {
    return (
      <div className="nama-table">
        <NamaTableHeader
          color={this.props.color}
          actions={this.props.actions}
          header={this.props.header}
        />
        <div className="content">
          {this.props.data ? (
            this.props.data.map((item: any, index: number) => {
              return (
                <NamaTableItem
                  key={index}
                  header={this.props.header}
                  item={item}
                  actions={this.props.actions}
                />
              );
            })
          ) : (
            <div className="nama-table-loading">
              <NamaIcon icon="teams" /> Loading
            </div>
          )}
        </div>
      </div>
    );
  }
}

class NamaTableAction extends Component {
  constructor(public props: NamaTableActionsProps) {
    super(props);
  }

  render() {
    return (
      <NamaButton className="compact" onClick={this.props.onClick}>
        <NamaIcon icon={this.props.icon} />
        {this.props.name}
      </NamaButton>
    );
  }
}

class NamaTableHeader extends Component<NamaTableHeaderProps> {
  render() {
    return (
      <div className={`table-header ${this.props.color}`}>
        <div className="item">
          {this.props.header.map((item: any, index: number) => {
            return (
              <div key={index} className={`col ${item.direction}`}>
                <Translate>{item.value}</Translate>
              </div>
            );
          })}
          {this.props.actions ? (
            <div className="actions">
              {this.props.actions.map(
                (action: NamaTableActionsProps, i: number) => {
                  return (
                    <NamaTableAction
                      key={i}
                      icon={action.icon}
                      onClick={(e: any) => {}}
                      name={action.name}
                    />
                  );
                }
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

class NamaTableItem extends Component<NamaTableItemProps> {
  public dispatchAction = (action: any, e: any) => {
    e.detail = this.props.item;
    return action.onClick(e);
  };

  render() {
    return (
      <div className="item">
        {this.props.header.map((item: any, index: number) => {
          return (
            <div key={index} className={`col ${item.direction}`}>
              {item.type == "tag" &&
              typeof this.props.item[item.key] !== "undefined" ? (
                <NamaTag>{this.props.item[item.key]}</NamaTag>
              ) : typeof this.props.item[item.key] !== "undefined" ? (
                this.props.item[item.key]
              ) : (
                "-"
              )}
            </div>
          );
        })}
        {this.props.actions ? (
          <div className="actions">
            {this.props.actions.map(
              (action: NamaTableActionsProps, i: number) => {
                return (
                  <NamaTableAction
                    key={i}
                    icon={action.icon}
                    onClick={(e: any) => {
                      this.dispatchAction(action, e);
                    }}
                    name={action.name}
                  />
                );
              }
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default NamaTable;
