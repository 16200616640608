import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  NamaDashboardTemplate,
  NamaDashboardHeaderMenu
} from "@prakriti/prakriti";
import {
  NamaOrganizationsApp,
  NamaMembersApp,
  NamaChannelsApp,
  NamaAssistantsApp,
  NamaAccountApp,
  UserActions,
  OrganizationsActions,
  DashboardModel,
  NamaInsightsApp,
  NamaOrganizationManagerApp
} from "@purusha/purusha";

export interface NamaDashboardProps {
  ["dashboard"]: any;
  ["fetchCurrentUser"]: any;
  ["singout"]: any;
  ["unselectOrganization"]: any;
}

export interface NamaDashboardState {
  dashboard: DashboardModel;
}

class NamaDashboardApp extends React.Component<
  NamaDashboardProps,
  NamaDashboardState
  > {
  constructor(
    public props: NamaDashboardProps,
    public state: NamaDashboardState
  ) {
    super(props);
  }

  private unselectOrganization = () => {
    this.props.unselectOrganization();
  };

  public header = () => {
    return (
      <NamaDashboardHeaderMenu
        user={this.props.dashboard.user}
        logout={this.props.singout}
        organization={this.props.dashboard.organizations.selected}
        unselectOrganizationFn={this.unselectOrganization}
      />
    );
  };

  public organizationSelectedApps = () => {
    return [
      {
        component: NamaInsightsApp,
        route: "/insights",
        title: "insights",
        icon: "analytics"
      },
      {
        component: NamaAssistantsApp,
        route: "/assistants",
        title: "assistants",
        icon: "bot"
      },
      {
        component: NamaMembersApp,
        route: "/members",
        title: "members",
        icon: "teams"
      },
      {
        component: NamaChannelsApp,
        route: "/channels",
        title: "channels",
        icon: "channels"
      },
      {
        component: NamaAccountApp,
        route: "/account",
        title: "account",
        icon: "home",
        hidden: true
      },
      {
        component: NamaOrganizationManagerApp,
        route: "/organization",
        title: "organization settings",
        icon: "home"
      }
    ];
  };

  public render() {
    return (
      <NamaDashboardTemplate
        header={this.header()}
        default="organization"
        white={!this.props.dashboard.organizations.selected}
        showMenu={!!this.props.dashboard.organizations.selected}
        apps={
          !this.props.dashboard.organizations.selected
            ? [
              {
                component: NamaOrganizationsApp,
                title: "organizations",
                icon: "home",
                route: "/"
              }
            ]
            : this.organizationSelectedApps()
        }
      />
    );
  }

  public componentWillMount = () => {
    this.props.fetchCurrentUser();
  };
}

const mapStateToProps = (state: DashboardModel) => ({ dashboard: state });
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ ...UserActions, ...OrganizationsActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NamaDashboardApp);
