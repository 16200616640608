import * as React from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import "./navigation.template.scss";
import {
  NamaButton,
  NamaIcon,
  NamaAvatar,
  NamaConfirmation,
  NamaNavigationLayout
} from "@prakriti/prakriti";

interface NamaNavigationTemplateProps {
  ["select"]: Function;
  ["title"]?: string;
  ["action"]?: string | "empty";
  ["templates"]: {
    ["empty"]: JSX.Element;
    ["view"]: JSX.Element;
    ["edit"]: JSX.Element;
    ["new"]: JSX.Element;
  };
  ["items"]: Array<{
    ["name"]: string;
    ["avatar"]?: string;
    ["id"]: number;
  }>;
  ["selected"]?: {
    ["name"]: string;
    ["avatar"]?: string;
    ["id"]: number;
  };
}

class NamaNavigationTemplate extends React.Component<
  NamaNavigationTemplateProps
  > {
  public select = (id: string, action?: string) => {
    this.props.select(id, action);
  };

  public navigation = () => {
    let classNameNew = "white capitalize huge left icon fill-info";
    let classNameList = "huge white avatar icon";
    return (
      <div className="nama-button-old-list">
        <NamaButton
          className={
            this.props.action === "new"
              ? `${classNameNew} active`
              : classNameNew
          }
          onClick={() => {
            this.select("0", "new");
          }}
        >
          <NamaIcon icon="add" />
          <span>New {this.props.title}</span>
        </NamaButton>

        {this.props.items.map((item: any, index: number) => (
          <NamaButton
            key={index}
            className={
              this.props.selected && this.props.selected.id === item.id
                ? `${classNameList} active`
                : classNameList
            }
            onClick={() => {
              this.select(item.id.toString());
            }}
          >
            <NamaAvatar
              size="small"
              url={item.avatar ? item.avatar : undefined}
              name={item.name}
            />
            <span>{item.name}</span>
            <NamaIcon icon="next" />
          </NamaButton>
        ))}
      </div>
    );
  };

  public content = () => {
    return this.props.selected
      ? this.props.action === "edit"
        ? this.props.templates.edit
        : this.props.templates.view
      : this.props.action === "new"
        ? this.props.templates.new
        : this.props.templates.empty;
  };

  public render() {
    return (
      <NamaNavigationLayout nav={this.navigation()} content={this.content()} />
    );
  }
}

export default NamaNavigationTemplate;
