import * as React from "react";
import './event-message.scss';
import { MessageModel } from "@purusha/purusha";

export interface EventMessageComponentProps {
  ["message"]: MessageModel;
}

class EventMessageComponent extends React.Component<
  EventMessageComponentProps
  > {
  constructor(
    public props: EventMessageComponentProps
  ) {
    super(props);
  }

  public render() {
    const { message } = this.props;
    return (
      <div className="event-message">
        <div className="marker"></div>
        <p>
          {message.text}
        </p>
      </div>
    );
  }
}

export default EventMessageComponent;