import { UserService } from "@purusha/purusha";

export let fetchCurrentUser = () => {
  const request = UserService.current_user();
  return {
    type: "FETCH_CURRENT_USER",
    payload: request
  };
};

export let updateUserInfo = (name: string, avatar: string) => {
  const request = UserService.update_user_info(name, avatar);
  return {
    type: "UPDATE_CURRENT_USER",
    payload: request
  };
};

export let singout = () => {
  const request = UserService.sing_out();
  return {
    type: "SINGOUT",
    payload: request
  };
};
