import * as React from "react";
import './message-group.scss';
import { MessageModel } from "@purusha/purusha";
import moment from "moment";
import { NamaMessageBubble } from "@prakriti/prakriti";

export interface ConversationsLayoutProps {
  ["messages"]: Array<MessageModel>;
}

class ConversationsLayoutLayout extends React.Component<
  ConversationsLayoutProps
  > {
  constructor(public props: ConversationsLayoutProps) {
    super(props);
  }

  createMessageGroups = () => {
    const { messages } = this.props;
    if (!messages) {
      return;
    }
    const messageGroups = messages.reduce<Array<Array<MessageModel>>>(
      (groups, message) => {
        if (
          groups.length === 0 ||
          message.from !== groups[groups.length - 1][0].from
        ) {
          groups.push([message]);
        } else {
          groups[groups.length - 1].push(message);
        }
        return groups;
      }, []
    );

    return messageGroups;

  }

  public render() {
    const { messages } = this.props;
    const messageGroups = this.createMessageGroups();
    if (!Array.isArray(messages)) {
      return <h1>Falha ao exibir mensagens... :(</h1>
    }
    return (
      <div className="message-group">
        <div className="timestamp">
          {moment(messages[0].created_at).format("HH[h]mm")}
        </div>

        <div className="messages">
          {
            messageGroups &&
            messageGroups.map(
              (messages) =>
                <div className="messages">
                  {
                    messages.map(message => <NamaMessageBubble key={message.id} message={message} />)
                  }
                </div>

            )
          }
        </div>
      </div>
    );
  }
}

export default ConversationsLayoutLayout;
