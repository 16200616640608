import * as React from "react";
import "./header.organism.scss";
import { NamaIcon } from "@prakriti/prakriti";

export interface NamaHeaderOrganismProps {
  ["title"]: string;
  ["icon"]: string;
  ["children"]: any;
}
export interface NamaHeaderOrganismState {}

class NamaHeaderOrganism extends React.Component {
  constructor(
    public props: NamaHeaderOrganismProps,
    public state: NamaHeaderOrganismState
  ) {
    super(props);
  }

  public render() {
    return (
      <div id="nama-header">
        <div className="page-title slideInDown">
          <NamaIcon icon={this.props.icon} />
          <h3>{this.props.title}</h3>
        </div>

        {this.props.children}
      </div>
    );
  }
}

export default NamaHeaderOrganism;
