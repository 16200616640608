import * as React from "react";
import * as Yup from "yup";

import { NamaInput, NamaTagInput, NamaColorInput } from "@prakriti/prakriti";
import WidgetOptions from "@purusha/models/options.widget.model";
import { Formik, Form } from "formik";
import WidgetOptionEfffect from "../../../effects/widgetOption.effect";
export interface WidgetOptionsComponentProps {
  ["options"]: WidgetOptions;
  ["onChange"]?: Function;
}
export interface WidgetOptionsComponentState {
  ["options"]: WidgetOptionsForm;
}
export interface WidgetOptionsForm {
  ["size"]?: string;
  ["behavior"]?: string;
  ["placeholder"]?: string;
  ["color"]?: string;
  ["whiteLabel"]?: boolean;
  ["singleUse"]?: boolean;
  ["disclaimer"]?: string;
  ["first_message"]?: string;
  ["quick_replies"]?: Array<any>;
}
class WidgetOptionsComponent extends React.Component<
  WidgetOptionsComponentProps,
  WidgetOptionsComponentState
> {
  public formik: any;
  constructor(public props: WidgetOptionsComponentProps) {
    super(props);
    this.formik = React.createRef();
    this.state = {
      options: this.optionsToForm(this.props.options)
    };
    console.log(this.optionsToForm(this.props.options));
  }

  // PARSES

  public optionsToForm = (options: WidgetOptions): WidgetOptionsForm => {
    return {
      size: options.size,
      whiteLabel: options.whiteLabel,
      behavior: options.behavior,
      singleUse: options.singleUse,
      placeholder: options.placeholder,
      color: options.color,
      disclaimer: options.disclaimer,
      first_message: options.onboarding ? options.onboarding.text : "",
      quick_replies: options.onboarding
        ? this.choicesToQuickReplies(options.onboarding.choices)
        : []
    };
  };

  public formToOptions = (form: WidgetOptionsForm): WidgetOptions => {
    console.log(form)
    return {
      size: form.size,
      behavior: form.behavior,
      whiteLabel: form.whiteLabel,
      placeholder: form.placeholder,
      singleUse: form.singleUse,
      color: form.color,
      disclaimer: form.disclaimer,
      onboarding: {
        text: form.first_message,
        choices: form.quick_replies
          ? this.quickRepliesToChoices(form.quick_replies)
          : undefined
      }
    };
  };

  public choicesToQuickReplies = (choices?: Array<any>) => {
    return (
      choices &&
      choices.map((choice: any) => {
        return choice.value;
      })
    );
  };

  public quickRepliesToChoices = (quick_replies: Array<string>) => {
    return (
      quick_replies &&
      quick_replies.map((quick_replie: string) => {
        return {
          value: quick_replie
        };
      })
    );
  };

  public submit = (e: any) => {
    if (this.props.onChange) {
      let newOptions = this.formToOptions(e.values);
      this.props.onChange(newOptions);
      this.setState({ options: this.optionsToForm(newOptions) });
    }
  };

  public render() {
    let WidgetOptionsSchema = Yup.object().shape({
      first_message: Yup.string(),
      placeholder: Yup.string(),
      behavior: Yup.string(),
      size: Yup.string(),
      color: Yup.string(),
      disclaimer: Yup.string()
    });

    return (
      <div>
        <Formik
          validationSchema={WidgetOptionsSchema}
          initialValues={this.state.options}
          ref={this.formik}
          enableReinitialize={true}
          onSubmit={this.submit}
          render={(props: any) => (
            <Form className="nama-form">
              <h3> Widget Options </h3>

              <WidgetOptionEfffect
                onChange={this.submit}
                formik={this.formik}
              />
              <hr />
              <br />

              <NamaInput
                type="textarea"
                name="first_message"
                label="First message"
                hint="This is the first message which will engage your customers."
              />

              <NamaTagInput
                label="Quick Replies"
                name="quick_replies"
                tags={this.state.options.quick_replies}
                onChange={(e: any) => {
                  if (this.formik.current) {
                    this.formik.current.setValues({
                      ...this.state.options,
                      quick_replies: e.target.value
                    });
                  }
                }}
                placeholder="Type and press 'enter' to add a Quick Reply"
                hint="This is the message which appears on the text input."
              />

              <div>
                <div style={{ width: "50%", float: "left" }}>
                  <NamaColorInput
                    name="color"
                    onChange={(e: any) => {
                      if (this.formik.current) {
                        this.formik.current.setValues({
                          ...this.state.options,
                          color: e.target.value
                        });
                      }
                    }}
                    {...this.state.options && {
                      color: this.state.options.color || "#2962ff"
                    }}
                    label="Set up the widget’s main color"
                    hint="Modifying it will interfere on the message box and some extra details."
                  />
                </div>
                <div
                  style={{
                    width: "calc(50% - 16px)",
                    paddingLeft: 16,
                    float: "left"
                  }}
                >
                  <NamaInput
                    type="text"
                    name="behavior"
                    label="Widget Start Behavior"
                  >
                    <option value="default">Default</option>
                    <option value="always-open">Always Open</option>
                    <option value="after-interact-open">
                      After Interact Open
                    </option>
                  </NamaInput>
                  
                  <NamaInput
                    type="text"
                    name="singleUse"
                    label="Widget User Persistence"
                  >
                    <option value="false">Sim</option>
                    <option value="true">Não</option>
                  </NamaInput>

                  <NamaInput
                    type="text"
                    name="whiteLabel"
                    label="Widget is white labeled"
                  >
                    <option value="default">Default</option>
                    <option value="false">Não</option>
                    <option value="true">Sim</option>
                  </NamaInput>

                  <NamaInput type="text" name="size" label="Widget size">
                    <option value="default">Default</option>
                    <option value="full">Full Screen</option>
                  </NamaInput>
                </div>
              </div>

              <NamaInput
                type="text"
                name="placeholder"
                label="Text Placehold"
                hint="This is the message which appears on the text input."
              />

              <NamaInput
                type="textarea"
                name="disclaimer"
                label="Disclaimer text"
                hint="if you don`t want to add a Disclaimer text, leave this field blank."
              />
            </Form>
          )}
        />
      </div>
    );
  }
}

export default WidgetOptionsComponent;
