import React from "react";
import { Formik, Form } from "formik";
import {
  UserModel,
  AsyncErrorEffect,
  ModalFormCleanEffect
} from "@purusha/purusha";
import { NamaInput, NamaButton } from "@prakriti/prakriti";
import { Translate } from "ns-react-i18next";
import * as Yup from "yup";
import NamaUploadAvatar from "@prakriti/atoms/upload-avatar/upload-avatar.atom";
export interface NamaAccountFormProps {
  ["onSubmit"]: any;
  ["errors"]: any;
  ["initial"]: UserModel;
}

class NamaAccountFormApp extends React.Component {
  public formik: any;
  public userSchema: any;
  constructor(public props: NamaAccountFormProps) {
    super(props);
    this.formik = React.createRef();
    this.userSchema = Yup.object().shape({
      name: Yup.string()
        .min(2, "validation_short")
        .max(70, "validation_long")
        .required("validation_required")
    });
  }

  public submit = (values: any) => {
    this.props.onSubmit(values);
  };

  public render() {
    return (
      <Formik
        ref={this.formik}
        initialValues={this.props.initial}
        validationSchema={this.userSchema}
        enableReinitialize={true}
        onSubmit={this.submit}
        render={({ values }) => (
          <Form className="nama-form">
            <AsyncErrorEffect formik={this.formik} errors={this.props.errors} />
            <NamaUploadAvatar
              name="avatarUrl"
              title={this.props.initial.name}
              label="avatar"
              formik={this.formik.current}
              url={values.avatar && values.avatar.url}
            />
            <NamaInput type="text" name="name" label="name" />
            <div className="actions">
              <button type="submit" className="nama-button-old primary">
                <Translate> update </Translate>
              </button>
            </div>
          </Form>
        )}
      />
    );
  }
}

export default NamaAccountFormApp;
