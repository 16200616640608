import * as React from "react";
import './insightsOverview.layout.scss';
import { ChatStatsModel, OrganizationModel } from "@purusha/purusha";
import { ReceivedMessagesCard } from "@prakriti/prakriti";
import { Card as NamaCard, IDateRange, DropdownItem } from "nama-design";
import numbro from "numbro";

const numberFormat = {
  average: true,
  optionalMantissa: true,
  mantissa: 1
}

export interface InsightsOverviewLayoutProps {
  organization?: OrganizationModel;
  chatStats?: ChatStatsModel,
  dateFilter?: IDateRange,
  channelFilter?: Array<DropdownItem>,
  fetchChatStats(organization_id: Number, options: { dateFilter?: IDateRange, channelFilter?: Array<DropdownItem> }): any
}

class InsightsOverviewLayout extends React.Component<
  InsightsOverviewLayoutProps
  > {
  constructor(public props: InsightsOverviewLayoutProps) {
    super(props);
  }

  componentDidUpdate(prevProps: InsightsOverviewLayoutProps) {
    const { organization, fetchChatStats, dateFilter, channelFilter } = this.props;
    if ((prevProps.dateFilter !== this.props.dateFilter) ||
      (prevProps.channelFilter !== this.props.channelFilter)) {
      organization &&
        fetchChatStats(organization.id, { dateFilter, channelFilter });
    }
  }

  componentDidMount() {
    const { fetchChatStats, organization, dateFilter, channelFilter } = this.props;
    organization &&
      fetchChatStats(organization.id, { dateFilter, channelFilter });
  }

  public render() {
    const { chatStats } = this.props;
    return (
      <section className="insights-overview-layout">
        {
          chatStats &&
          <div>
            <NamaCard
              header={{
                label: "Conversas",
                iconName: "Chat",
                iconColor: "blue-300"
              }}
              items={
                [
                  { label: "Total de conversas", value: chatStats.chats.total ? numbro(chatStats.chats.total).format(numberFormat) : 0, color: "grey-800" },
                  { label: "Média de conversas por dia", value: chatStats.chats.daily_average ? numbro(chatStats.chats.daily_average).format(numberFormat) : 0, color: "grey-800" }
                ]
              }
            >
            </NamaCard>
          </div>
        }

        {
          chatStats &&
          <ReceivedMessagesCard
            ai={chatStats.user_interactions.ai}
            aiml_direct_response={chatStats.user_interactions.aiml_direct_response}
            total={chatStats.user_interactions.total}
          />
        }
      </section>
    );
  }
}

export default InsightsOverviewLayout;
