import React, { Component } from "react";
import "./dropfile.atom.scss";
import Dropzone from "react-dropzone";
import { throws } from "assert";
import { NamaButton, NamaIcon } from "@prakriti/prakriti";
import moment from "moment";

interface NamaDropFileProps {
  ["setFile"]: Function;
  ["accept"]?: string;
}

interface NamaDropFileState {
  ["file"]?: File;
}

class NamaDropFile extends Component {
  constructor(
    public props: NamaDropFileProps,
    public state: NamaDropFileState
  ) {
    super(props);
  }

  public addFile = (files: Array<File>) => {
    this.setState({ file: files[0] });
  };

  public removeFile = () => {
    this.setState({ file: undefined });
  };

  render() {
    const baseStyle = {
      width: "calc( 100% - 13px )",
      marginLeft: 5,
      height: 200,
      borderWidth: 1,
      borderColor: "#333",
      borderStyle: "dotted",
      borderRadius: 5,
      marginBottom: 16
    };
    const successStyle = {
      width: "calc( 100% - 13px )",
      marginLeft: 5,
      height: 200,
      borderWidth: 1,
      borderColor: "#6c6",
      borderStyle: "solid",
      borderRadius: 5,
      marginBottom: 16
    };
    const activeStyle = {
      borderStyle: "solid",
      borderColor: "#6c6",
      backgroundColor: "#E3E8EB"
    };
    const rejectStyle = {
      borderStyle: "solid",
      borderColor: "#F50057",
      backgroundColor: "#E3E8EB"
    };
    return (
      <Dropzone
        accept={this.props.accept}
        onDrop={(e: File[]) => {
          this.addFile(e);
          this.props.setFile(e[0]);
        }}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject,
          acceptedFiles,
          rejectedFiles
        }) => {
          let styles = { ...baseStyle };
          styles = isDragActive ? { ...styles, ...activeStyle } : styles;
          styles = isDragReject ? { ...styles, ...rejectStyle } : styles;

          return this.state.file ? (
            <div style={successStyle} className="nama-dropfile-success">
              <NamaIcon icon="ok" />
              <h3>{this.state.file.name}</h3>
              {(this.state.file as any).lastModifiedDate && (
                <p>
                  from{" "}
                  {moment((this.state.file as any).lastModifiedDate).format()}
                </p>
              )}

              <NamaButton onClick={this.removeFile} className="primary">
                {" "}
                Remove File{" "}
              </NamaButton>
            </div>
          ) : (
            <div className="nama-dropfile" {...getRootProps()} style={styles}>
              <input
                style={{ outline: "none" }}
                className="nama-input"
                {...getInputProps()}
              />
              {!isDragReject && (
                <h4 style={{ textAlign: "center", marginTop: 50 }}>
                  <NamaIcon className="fill-grey-300" icon="folder" />
                  {isDragAccept ? "Drop" : "Drag"} files here...
                </h4>
              )}
              {isDragReject && (
                <h3
                  className="color-red-300"
                  style={{ textAlign: "center", marginTop: 50 }}
                >
                  <NamaIcon className="fill-red-300" icon="information" />
                  Unsupported file type!
                </h3>
              )}
            </div>
          );
        }}
      </Dropzone>
    );
  }
}

export default NamaDropFile;
