import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Translate } from "ns-react-i18next";
import {
  OrganizationsActions,
  DashboardModel,
  AsyncErrorEffect,
  OrganizationModel
} from "@purusha/purusha";
import * as Yup from "yup";
import {} from "@prakriti/prakriti";
import NamaSettingsForm from "./settings.form";

export interface SettingsComponentProps {
  ["organization"]: OrganizationModel;
}

export interface SettingsComponentState {}

class SettingsComponent extends React.Component<
  SettingsComponentProps,
  SettingsComponentState
> {
  constructor(
    public props: SettingsComponentProps,
    public state: SettingsComponentState
  ) {
    super(props);
    this.state = {};
  }

  public submit = (values: any) => {
    console.log(values);
  };

  public render() {
    return (
      <section className="nama-content micro">
        {/* <p>
          An organization represents your business and allows you to manage
          users and other settings on accounts and features.
        </p> */}
        <NamaSettingsForm
          // errors={this.props.organization.errors}
          errors=""
          // initial={this.props.organization}
          initial={{
            id: 1,
            name: "Organization",
            role: "admin"
          }}
          onSubmit={this.submit}
        />
      </section>
    );
  }
}

const mapStateToProps = (state: DashboardModel) => ({});
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(OrganizationsActions, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsComponent);
