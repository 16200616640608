import { ChannelsModel } from "@purusha/purusha";
import { ChannelsInitialState } from "@purusha/purusha";

export function ChannelsReducer(
  state: ChannelsModel = ChannelsInitialState,
  action: any
) {
  switch (action.type) {
    case "CREATE_CHANNEL":
      if (action.payload.status == 201) {
        if (action.payload.data.conversable.answerer_type == "FlowVersion") {
          return {
            ...state,
            list: {
              ...state.list,
              flows: [...state.list.flows, action.payload.data]
            },
            selected_channel: action.payload.data
          };
        } else {
          return {
            ...state,
            list: {
              ...state.list,
              teams: [...state.list.teams, action.payload.data]
            },
            selected_channel: action.payload.data
          };
        }
      }
    case "UPDATE_CHANNEL":
      if (action.payload.status == 200) {
        let updatedFlowList: Array<any> = state.list.flows.map(
          (channel: any) => {
            if (channel.id == action.payload.data.id) {
              return action.payload.data;
            } else {
              return channel;
            }
          }
        );
        let updatedTeamList: Array<any> = state.list.teams.map(
          (channel: any) => {
            if (channel.id == action.payload.data.id) {
              return action.payload.data;
            } else {
              return channel;
            }
          }
        );

        return {
          ...state,
          list: {
            ...state.list,
            flows: updatedFlowList,
            teams: updatedTeamList
          },
          selected_channel: action.payload.data
        };
      }
    case "FETCH_CHANNELS":
      let channels = action.payload.data;
      if (channels) {
        let flows = channels.filter(
          (item: any) => item.conversable.answerer_type == "FlowVersion"
        );
        let teams = channels.filter(
          (item: any) => item.conversable.answerer_type == "Team"
        );
        return {
          ...state,
          list: {
            ...state.list,
            flows: flows,
            teams: teams
          }
        };
      }

    case "SELECT_CHANNEL_TYPE":
      if (action.payload.key) {
        return {
          ...state,
          selected_type: action.payload,
          list: {
            flows: [],
            teams: []
          }
        };
      } else {
        return state;
      }

    case "SELECT_CHANNEL":
      return {
        ...state,
        selected_channel: action.payload
      };

    case "SELECT_ACTION":
      return {
        ...state,
        action: action.payload
      };

    case "RESET_CHANNEL":
      if (action.payload.status == 200) {
        alert("Channel will restart next time someone talks to it.");
      }
      return state;

    case "DISCARD_CHANNEL":
      if (action.payload.status == 200) {
        let updatedFlowList: Array<any> = state.list.flows.filter(
          (channel: any) => {
            return channel.id != action.payload.data.id;
          }
        );
        let updatedTeamList: Array<any> = state.list.teams.filter(
          (channel: any) => {
            return channel.id != action.payload.data.id;
          }
        );
        return {
          ...state,
          list: {
            ...state.list,
            flows: updatedFlowList,
            teams: updatedTeamList
          }
        };
      } else {
        return state;
      }

    case "UNSELECT_PUBLISHES":
      return {
        ...state,
        selected_channel: undefined
      };
    default:
      return state;
  }
}
