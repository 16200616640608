import * as React from "react";
import { NamaChatItem } from "@prakriti/prakriti";
import { ChatModel } from '@purusha/models/chat.model';
import './chatList.scss';
import NamaButton from "@prakriti/atoms/button/button.atom";

export interface ChatListComponentProps {
  ["chatList"]: Array<ChatModel>;
  ["organizationId"]?: number;
  ["selected"]?: ChatModel;
  ["selectChat"]: any;
  ["loadMore"]?: any;
}

class ChatListComponent extends React.Component<
  ChatListComponentProps
  > {
  constructor(
    public props: ChatListComponentProps
  ) {
    super(props);
  }


  public render() {
    const { chatList, selectChat, organizationId, selected, loadMore } = this.props;
    return (
      <div className="chatlist">
        {
          chatList.map(
            chat => <NamaChatItem selected={Boolean(selected && selected.id === chat.id)} key={chat.id} chat={chat} onClick={() => selectChat(chat, organizationId)} />
          )
        }
        {
          loadMore &&
          <NamaButton onClick={loadMore} style={{
            background: '#F6F7F7',
            border: '1px solid #E3E7EA',
            borderRadius: '4px',
            marginTop: '24px',
          }}>
            Exibir conversas mais antigas
          </NamaButton>
        }
      </div>
    );
  }
}

export default ChatListComponent;