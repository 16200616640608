import {
  TeamInitialState,
  TeamsInitialState,
  TeamsModel,
  TeamModel,
  MemberModel
} from "@purusha/purusha";

export function TeamsReducer(
  state: TeamsModel = TeamsInitialState,
  action: any
) {
  switch (action.type) {
    case "SELECT_TEAM":
      let selectedTeam: any = undefined;
      if (action.payload.team !== "0") {
        selectedTeam = state.list.filter((member: TeamModel) => {
          return member.id == action.payload.team;
        })[0];
      }
      return {
        ...state,
        selected: selectedTeam,
        action: action.payload.action
      };

    case "FETCH_TEAMS":
      if (action.payload.data) {
        let list = action.payload.data.map((item: any) => {
          return {
            ...item,
            avatar: item.conversable_avatar.url || ""
          };
        });
        return { ...state, list: list, selected: undefined };
      } else {
        return state;
      }

    case "FETCH_TEAM_MEMBERS":
      if (action.payload.data) {
        let list = action.payload.data.map((item: any) => {
          return {
            ...item,
            id: item.user.id,
            name: item.user.name,
            email: item.user.email,
            role: item.roles,
            avatar: item.conversable_avatar ? item.conversable_avatar.url : ""
          };
        });
        return { ...state, selected: { ...state.selected, members: list } };
      } else {
        return state;
      }

    case "ADD_TEAM_MEMBER":
      if (action.payload.status == 200) {
        console.log(action.payload.data);
        return {
          ...state,
          selected: {
            ...state.selected,
            members: [
              ...(state.selected ? (state.selected.members as Array<any>) : []),
              {
                ...action.payload.data,
                ...action.payload.data.user,
                role: action.payload.data.roles
              }
            ]
          }
        };
      } else if (action.payload.response.data.error) {
        // change to a proper alert
        alert(action.payload.response.data.error);
      } else {
        return state;
      }

    case "CREATE_TEAM":
      if (action.payload.status == 201) {
        let serverTeam = {
          ...action.payload.data.team,
          avatar: action.payload.data.team.conversable_avatar
            ? action.payload.data.team.conversable_avatar.url
            : ""
        };
        return {
          ...state,
          errors: undefined,
          action: undefined,
          selected: serverTeam,
          list: [...state.list, serverTeam]
        };
      } else if (action.payload.response.data.errors) {
        return {
          ...state,
          errors: action.payload.response.data.errors
        };
      } else {
        return state;
      }

    case "UPDATE_TEAM_MEMBER":
      if (action.payload.status == 200) {
        let serverMember = {
          ...action.payload.data.user,
          ...action.payload.data,
          role: action.payload.data.roles
        };
        if (state.selected) {
          state.selected.members = state.selected.members || [];
          let updatedList: Array<MemberModel> = state.selected.members.map(
            (member: MemberModel) => {
              if (member.id == serverMember.id) {
                return serverMember;
              } else {
                return member;
              }
            }
          );
          return {
            ...state,
            selected: {
              ...state.selected,
              members: updatedList
            },
            action: undefined
          };
        }
      } else {
        return state;
      }

    case "UPDATE_TEAM":
      if (action.payload.status == 200) {
        let serverTeam = {
          ...action.payload.data.team,
          avatar: action.payload.data.team.conversable_avatar
            ? action.payload.data.team.conversable_avatar.url
            : ""
        };
        let updatedList: Array<TeamModel> = state.list.map(
          (team: TeamModel) => {
            if (team.id == serverTeam.id) {
              return serverTeam;
            } else {
              return team;
            }
          }
        );
        return {
          ...state,
          selected: serverTeam,
          action: undefined,
          list: updatedList
        };
      } else {
        return state;
      }

    case "DISCARD_TEAM_MEMBERS":
      if (action.payload.status == 200) {
        let updatedList: Array<MemberModel> = state.selected
          ? state.selected.members
            ? state.selected.members.filter((member: MemberModel) => {
              return member.id != action.payload.data.user.id;
            })
            : []
          : [];
        return {
          ...state,
          selected: {
            ...state.selected,
            members: updatedList
          }
        };
      } else {
        return state;
      }

    case "DISCARD_TEAM":
      if (action.payload.status == 200) {
        let updatedList: Array<TeamModel> = state.list.filter(
          (team: TeamModel) => {
            return team.id != action.payload.data.team.id;
          }
        );
        return {
          ...state,
          selected: undefined,
          action: "empty",
          list: updatedList
        };
      } else {
        return state;
      }

    default:
      return state;
  }
}
