import React, { Component } from "react";
import "./prakriti/styles/app.scss";
import NamaDashboardApp from "./purusha/apps/dashboard/dashboard.app";

class App extends Component {
  render() {
    return <NamaDashboardApp />;
  }
}
export default App;
